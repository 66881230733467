import { State } from '@/types/entities'
import { AsyncStatusTypeDb } from '@/types/generated/graphql'

/**
 * エクスポートするファイル名
 */
export const EXPORT_FILENAME = {
  user: 'ユーザー管理.xlsx',
  item_feature: '商品特性.xlsx',
  tran_ref_store: '実績参照店舗.xlsx',
  tran_ref_item: '実績参照商品.xlsx',
  order_pattern_setting_sii: '発注スケジュール.xlsx',
  areastore: '店舗エリア.xlsx',
  area: '店舗エリア名.xlsx',
  autoorder_stop: '自動発注停止.xlsx',
  base_conf: 'ベース設定.xlsx',
  causal_weight_conf: 'コーザル.xlsx',
  causal_name: 'コーザル名.xlsx',
  weekly_daily_budget_conf: '発注予算設定.xlsx',
  adjusted_dow_index: '曜日指数設定.xlsx',
  weight_adj_conf: '調整係数設定.xlsx',
  item_conf: '単品設定.xlsx',
  item_order_num_adj: '単品発注数調整表.xlsx',
  dsp_info: '棚コード・棚情報設定.xlsx',
  low_rank_logic_conf: '低ランクロジック設定表.xlsx',
  new_store_bulk_order: '新店一括発注設定.xlsx',
  new_store_bulk_order_arrival: '新店一括発注_店着日設定.xlsx',
  new_store_bulk_order_sheet: '新店一括発注_発注表.xlsx',
}

/**
 * 自動発注方式
 */
export const AUTOORDER_TYPE = {
  juyou_yosoku: '1',
  yosan_haibun: '2',
}

// 出力条件（抽出対象）の設定
export const OUTPUT_EXTRACT_TARGET = {
  all: '0',
  specify: '1',
}
// 出力条件（抽出対象）のデフォルト値
export const OUTPUT_EXTRACT_TARGET_DEFAULT = [OUTPUT_EXTRACT_TARGET.specify]

// 出力条件（店舗）の設定
export const OUTPUT_COND_STORE = {
  all: '0',
  tana: '1',
  area: '1',
  tanten: '2',
}
// 出力条件（商品）の設定
export const OUTPUT_COND_ITEM = {
  all: '0',
  shobunrui: '1',
  tanpin: '2',
  budgetGroup: '3',
  genre: '4',
}
// 出力条件（削除済みデータ出力）の設定
export const OUTPUT_COND_DELETE = {
  includeDelete: '1',
}

export const pathGroup: PathGroup = {
  s: { home: '/s', splitNum: 3, theme: '#f38e00' },
  h: { home: '/h', splitNum: 2, theme: '#444' },
  x: { home: '/s', splitNum: 3, theme: '#f38e00' },
}
export type PathGroup = {
  [key: string]: { home: string; splitNum: number; theme: string }
}

/**
 * 権限チェック用のパターン
 */
export const AVAILABLE_PATH = {
  shop: '/s/',
  head: '/h/',
}

/**
 * 商品特性の発注方式の選択項目
 */
export const ItemFeatureExtractAutoorderTypeOptions = [
  { value: null, text: '全て' },
  { value: '1', text: '需要予測型' },
  { value: '2', text: '予算配分型' },
]
/**
 * ユーザー管理の権限の選択項目
 */
export const userExtractIsAdministratorOptions = [
  { value: null, text: '全て' },
  { value: '1', text: 'システム管理者' },
  { value: '0', text: '本部利用' },
]

interface ITEM_STATUS {
  [key: string]: any
}
// 0:未来,1:棚入り,2:継続,3:段変更,4:フェイス変更,5:棚落ち,9:過去
export const ITEM_STATUS: ITEM_STATUS = {
  mirai: '0',
  yokoiri: '1',
  keizoku: '2',
  danHenkou: '3',
  faceHenkou: '4',
  tanaOti: '5',
  kako: '9',
}
interface EXTRACT_INFO_CONST {
  [key: string]: any
}
interface OUTPUT_INFO_CONST {
  [key: string]: any
}

export const EXTRACT_INFO_CONST: EXTRACT_INFO_CONST = {
  // 画面名
  UI_P_USER: {
    // [ラベル名]: {column: [Whereで参照しているカラム],default:[デフォルト値　Whereで渡していないときの値　],query: [名称を取得する場合のQueryのdocument]}
    loginId: { column: ['loginId'], default: '' },
    isAdministrator: {
      column: ['isAdministrator'],
      default: '全て',
      query: 'isAdministrator',
    },
  },
  UI_P_ITEM_FEATURE: {
    category1Cd: {
      column: ['vUiMItemApplyLevel/uiMCategory1s/category1Cd'],
      default: '',
      query: 'UiMCategory1sDocument',
    },
    autoorderType: {
      column: ['autoorderType'],
      default: '全て',
      query: 'AutoorderType',
    },
  },
  UI_P_TRAN_REF_STORE: {
    expireDate: {
      column: ['expireDate'],
      default: '2999-12-31',
    },
    refferenceStoreCd: {
      column: ['referrerStoreCd'],
      default: '',
      query: 'StoresDocument',
    },
    refferenceItemCategoryCd: {
      column: ['uiMCategory1s/category1Cd'],
      default: '',
      query: 'UiMCategory1sDocument',
    },
  },
  UI_P_TRAN_REF_ITEM: {
    expireDate: {
      column: ['expireDate'],
      default: '2999-12-31',
    },
    referrerItemCd: {
      column: ['referrerItemCd'],
      default: '',
      query: 'SaleItemsDocument',
    },
    refferenceItemCd: {
      column: ['refferenceItemCd'],
      default: '',
      query: 'SaleItemsDocument',
    },
  },
  UI_P_ORDER_PATTERN_SETTING_SII: {
    startDate: {
      column: ['startDate', 'endDateNvl'],
      default: '',
    },
    category1Cd: {
      column: ['category1'],
      default: '',
      query: 'UiMCategory1sDocument',
    },
    vendorCd: {
      column: ['vendorCd'],
      default: '',
      query: 'VendorsDocument',
    },
    areaCd: {
      column: ['vUiMStoreApplyLevel/vUiPAreas/areaCd'],
      default: '',
      query: 'ExtractVUiPAreasDocument',
    },
    storeCd: {
      column: ['vUiMStoreApplyLevel/validStores/storeCd'],
      default: '',
      query: 'StoresDocument',
    },
  },
  UI_P_AREASTORE: {
    startDate: {
      column: ['startDate', 'endDateNvl'],
      default: '',
    },
    areaStoreAreaCd: {
      column: ['areaCd'],
      default: '',
      query: 'ExtractVUiPAreasDocument',
    },
    areaStoreStoreCd: {
      column: ['storeCd'],
      default: '',
      query: 'StoresDocument',
    },
  },
  UI_P_AUTOORDER_STOP: {
    startDate: {
      column: ['startDate', 'endDateNvl'],
      default: '',
    },
    areaCd: {
      column: ['vUiMStoreApplyLevel/vUiPAreas/areaCd'],
      default: '',
      query: 'ExtractVUiPAreasDocument',
    },
    storeCd: {
      column: ['vUiMStoreApplyLevel/validStores/storeCd'],
      default: '',
      query: 'StoresDocument',
    },
    category1Cd: {
      column: ['vUiMItemApplyLevel/uiMCategory1s/category1Cd'],
      default: '',
      query: 'UiMCategory1sDocument',
    },
    saleItemCd: {
      column: ['vUiMItemApplyLevel/m2oItem/saleItemCd'],
      default: '',
      query: 'SaleItemsDocument',
    },
  },
  UI_P_BASE_CONF: {
    startDate: {
      column: ['startDate', 'endDateNvl'],
      default: '',
    },
    category1Cd: {
      column: ['vUiMItemApplyLevel/uiMCategory1s/category1Cd'],
      default: '',
      query: 'UiMCategory1sDocument',
    },
    category2Cd: {
      column: ['vUiMItemApplyLevel/uiMCategory2s/category2Cd'],
      default: '',
      query: 'UiMCategory2sDocument',
    },
    category3Cd: {
      column: ['vUiMItemApplyLevel/uiMCategory3s/category3Cd'],
      default: '',
      query: 'UiMCategory3sDocument',
    },
    saleItemCd: {
      column: ['vUiMItemApplyLevel/m2oItem/saleItemCd'],
      default: '',
      query: 'SaleItemsDocument',
    },
    itemStatus: {
      column: ['itemStatus'],
      default: '',
      query: 'ItemStatus',
    },
    areaCd: {
      column: ['vUiMStoreApplyLevel/vUiPAreas/areaCd'],
      default: '',
      query: 'ExtractVUiPAreasDocument',
    },
    storeCd: {
      column: ['vUiMStoreApplyLevel/validStores/storeCd'],
      default: '',
      query: 'StoresDocument',
    },
  },
  UI_P_CAUSAL_WEIGHT_CONF: {
    startDate: {
      column: ['startDate', 'endDateNvl'],
      default: '',
    },
    areaCd: {
      column: ['vUiMStoreApplyLevel/vUiPAreas/areaCd'],
      default: '',
      query: 'ExtractVUiPAreasDocument',
    },
    storeCd: {
      column: ['vUiMStoreApplyLevel/validStores/storeCd'],
      default: '',
      query: 'StoresDocument',
    },
    category1Cd: {
      column: ['vUiMItemApplyLevel/uiMCategory1s/category1Cd'],
      default: '',
      query: 'UiMCategory1sDocument',
    },
    saleItemCd: {
      column: ['vUiMItemApplyLevel/m2oItem/saleItemCd'],
      default: '',
      query: 'SaleItemsDocument',
    },
    causalCd: {
      column: ['causalCd'],
      default: '',
      query: 'UiPCausalNamesDocument',
    },
  },
  UI_P_WEEKLY_DAILY_BUDGET_CONF: {
    itemCategory0Cd: {
      column: ['vUiPBudgetGroup/category0'],
      default: '',
      query: 'UiMCategory0sDocument',
    },
  },
  UI_P_ADJUSTED_DOW_INDEX: {
    startDate: {
      column: ['startDateNvl', 'endDateNvl'],
      default: '',
    },
    itemCategory0Cd: {
      column: ['vUiPBudgetGroup/category0'],
      default: '',
      query: 'UiMCategory0sDocument',
    },
  },
  UI_P_WEIGHT_ADJ_CONF: {
    itemCategory0Cd: {
      column: ['vUiPBudgetGroup/category0'],
      default: '',
      query: 'UiMCategory0sDocument',
    },
  },
  UI_P_ITEM_CONF: {
    itemStandardDate: {
      column: [
        'saleItems/uiMShelfPatterns/startDate',
        'saleItems/uiMShelfPatterns/endDate',
      ],
      default: '',
    },
    itemCategory0Cd: {
      column: ['item/category0'],
      default: '',
      query: 'UiMCategory0sDocument',
    },
    itemCategory1Cd: {
      column: ['item/category1'],
      default: '',
      query: 'UiMCategory1sDocument',
    },
    itemCategory2Cd: {
      column: ['item/category2'],
      default: '',
      query: 'UiMCategory2sDocument',
    },
    itemCategory3Cd: {
      column: ['item/category3'],
      default: '',
      query: 'UiMCategory3sDocument',
    },
    saleItemCd: {
      column: ['itemCd'],
      default: '',
      query: 'SaleItemsDocument',
    },
  },
  UI_P_ITEM_ORDER_NUM_ADJ: {
    itemStandardDate: {
      column: ['startDate', 'endDateNvl'],
      default: '',
    },
    itemCategory0Cd: {
      column: ['item/category0'],
      default: '',
      query: 'UiMCategory0sDocument',
    },
    itemCategory1Cd: {
      column: ['item/category1'],
      default: '',
      query: 'UiMCategory1sDocument',
    },
    itemCategory2Cd: {
      column: ['item/category2'],
      default: '',
      query: 'UiMCategory2sDocument',
    },
    itemCategory3Cd: {
      column: ['item/category3'],
      default: '',
      query: 'UiMCategory3sDocument',
    },
    saleItemCd: {
      column: ['itemCd'],
      default: '',
      query: 'SaleItemsDocument',
    },
  },
  UI_P_NEW_STORE_BULK_ORDER: {
    itemCategory0Cd: {
      column: ['category0Cd'],
      default: '',
      query: 'UiMCategory0sDocument',
    },
    itemCategory1Cd: {
      column: ['category1Cd'],
      default: '',
      query: 'UiMCategory1sDocument',
    },
    itemCategory2Cd: {
      column: ['category2Cd'],
      default: '',
      query: 'UiMCategory2sDocument',
    },
    itemCategory3Cd: {
      column: ['category3Cd'],
      default: '',
      query: 'UiMCategory3sDocument',
    },
  },
  UI_P_NEW_STORE_BULK_ORDER_SHEET: {
    storeCd: {
      column: ['storeCd'],
      default: '',
      query: 'StoresDocument',
    },
  },  

}

export const OUTPUT_INFO_CONST: OUTPUT_INFO_CONST = {
  // 画面名
  UI_P_USER: {
    // OutputConditionの内容
    Delete: {
      outputCondType: 'Delete',
      displayItems: OUTPUT_COND_DELETE.includeDelete,
      default: '',
      // Whereで参照しているカラム名
      field: 'inactiveFlg',
    },
  },
  UI_P_ITEM_FEATURE: {
    Item: {
      outputCondType: ['Item'],
      displayItems: [
        OUTPUT_COND_ITEM.all,
        OUTPUT_COND_ITEM.shobunrui,
        OUTPUT_COND_ITEM.tanpin,
      ],
      default: [],
      field: 'itemApplyLevel',
    },
    Delete: {
      outputCondType: 'Delete',
      displayItems: OUTPUT_COND_DELETE.includeDelete,
      default: '',
      field: 'inactiveFlg',
    },
    ExtractTarget: {
      outputCondType: ['ExtractTarget'],
      displayItems: [OUTPUT_EXTRACT_TARGET.all, OUTPUT_EXTRACT_TARGET.specify],
      default: OUTPUT_EXTRACT_TARGET.all,
      field: 'configuredFlg',
    },
  },
  UI_P_TRAN_REF_STORE: {
    Item: {
      outputCondType: ['Item'],
      displayItems: [OUTPUT_COND_ITEM.all, OUTPUT_COND_ITEM.shobunrui],
      default: [],
      field: 'refLevel',
    },
    Delete: {
      outputCondType: 'Delete',
      displayItems: OUTPUT_COND_DELETE.includeDelete,
      default: '',
      field: 'inactiveFlg',
    },
    ExtractTarget: {
      outputCondType: ['ExtractTarget'],
      displayItems: [OUTPUT_EXTRACT_TARGET.all, OUTPUT_EXTRACT_TARGET.specify],
      default: OUTPUT_EXTRACT_TARGET.all,
      field: 'configuredFlg',
    },
  },
  UI_P_TRAN_REF_ITEM: {
    Item: {
      outputCondType: ['Item'],
      displayItems: [OUTPUT_COND_ITEM.tanpin],
      default: [OUTPUT_COND_ITEM.tanpin],
      field: '',
    },
    Delete: {
      outputCondType: 'Delete',
      displayItems: OUTPUT_COND_DELETE.includeDelete,
      default: '',
      field: 'inactiveFlg',
    },
  },
  UI_P_ORDER_PATTERN_SETTING_SII: {
    Item: {
      outputCondType: ['Item'],
      displayItems: [OUTPUT_COND_ITEM.shobunrui],
      default: [OUTPUT_COND_ITEM.shobunrui],
      field: '',
    },
    Store: {
      outputCondType: ['Store'],
      displayItems: [
        OUTPUT_COND_STORE.all,
        OUTPUT_COND_STORE.tana,
        OUTPUT_COND_STORE.tanten,
      ],
      default: [''],
      field: 'storeApplyLevel',
    },
    Delete: {
      outputCondType: 'Delete',
      displayItems: [OUTPUT_COND_DELETE.includeDelete],
      default: '',
      field: 'inactiveFlg',
    },
    ExtractTarget: {
      outputCondType: ['ExtractTarget'],
      displayItems: [OUTPUT_EXTRACT_TARGET.all, OUTPUT_EXTRACT_TARGET.specify],
      default: OUTPUT_EXTRACT_TARGET.all,
      field: 'configuredFlg',
    },
  },
  UI_P_AREASTORE: {
    Store: {
      outputCondType: ['Store'],
      displayItems: [OUTPUT_COND_STORE.tanten],
      default: [OUTPUT_COND_STORE.tanten],
      field: 'storeApplyLevel',
    },
    Delete: {
      outputCondType: 'Delete',
      displayItems: [OUTPUT_COND_DELETE.includeDelete],
      default: '',
      field: 'inactiveFlg',
    },
  },
  UI_P_AREA: {
    Delete: {
      outputCondType: 'Delete',
      displayItems: [OUTPUT_COND_DELETE.includeDelete],
      default: '',
      field: 'inactiveFlg',
    },
  },
  UI_P_AUTOORDER_STOP: {
    Item: {
      outputCondType: ['Item'],
      displayItems: [
        OUTPUT_COND_ITEM.all,
        OUTPUT_COND_ITEM.shobunrui,
        OUTPUT_COND_ITEM.tanpin,
      ],
      default: [],
      field: 'itemApplyLevel',
    },
    Store: {
      outputCondType: ['Store'],
      displayItems: [
        OUTPUT_COND_STORE.all,
        OUTPUT_COND_STORE.tana,
        OUTPUT_COND_STORE.tanten,
      ],
      default: [],
      field: 'storeApplyLevel',
    },
    Delete: {
      outputCondType: 'Delete',
      displayItems: [OUTPUT_COND_DELETE.includeDelete],
      default: '',
      field: 'inactiveFlg',
    },
    ExtractTarget: {
      outputCondType: ['ExtractTarget'],
      displayItems: [OUTPUT_EXTRACT_TARGET.all, OUTPUT_EXTRACT_TARGET.specify],
      default: OUTPUT_EXTRACT_TARGET.all,
      field: 'configuredFlg',
    },
  },
  UI_P_BASE_CONF: {
    Store: {
      outputCondType: ['Store'],
      displayItems: [
        OUTPUT_COND_STORE.all,
        OUTPUT_COND_STORE.tana,
        OUTPUT_COND_STORE.tanten,
      ],
      default: [''],
      field: 'storeApplyLevel',
      isTanaPattern: true,
    },
    Delete: {
      outputCondType: 'Delete',
      displayItems: [OUTPUT_COND_DELETE.includeDelete],
      default: '',
      field: 'inactiveFlg',
    },
    ExtractTarget: {
      outputCondType: ['ExtractTarget'],
      displayItems: [OUTPUT_EXTRACT_TARGET.all, OUTPUT_EXTRACT_TARGET.specify],
      default: OUTPUT_EXTRACT_TARGET.all,
      field: 'configuredFlg',
    },
  },
  UI_P_CAUSAL_WEIGHT_CONF: {
    Item: {
      outputCondType: ['Item'],
      displayItems: [
        OUTPUT_COND_ITEM.all,
        OUTPUT_COND_ITEM.shobunrui,
        OUTPUT_COND_ITEM.tanpin,
      ],
      default: [],
      field: 'itemApplyLevel',
    },
    Store: {
      outputCondType: ['Store'],
      displayItems: [
        OUTPUT_COND_STORE.all,
        OUTPUT_COND_STORE.tana,
        OUTPUT_COND_STORE.tanten,
      ],
      default: [],
      field: 'storeApplyLevel',
    },
    Delete: {
      outputCondType: 'Delete',
      displayItems: [OUTPUT_COND_DELETE.includeDelete],
      default: '',
      field: 'inactiveFlg',
    },
    ExtractTarget: {
      outputCondType: ['ExtractTarget'],
      displayItems: [OUTPUT_EXTRACT_TARGET.all, OUTPUT_EXTRACT_TARGET.specify],
      default: OUTPUT_EXTRACT_TARGET.all,
      field: 'configuredFlg',
    },
  },
  UI_P_CAUSAL_NAME: {
    Delete: {
      outputCondType: 'Delete',
      displayItems: [OUTPUT_COND_DELETE.includeDelete],
      default: '',
      field: 'inactiveFlg',
    },
  },
  UI_P_WEEKLY_DAILY_BUDGET_CONF: {
    Store: {
      outputCondType: ['Store'],
      displayItems: [OUTPUT_COND_STORE.tanten],
      default: [OUTPUT_COND_STORE.tanten],
      field: 'storeApplyLevel',
    },
    Item: {
      outputCondType: ['Item'],
      displayItems: [OUTPUT_COND_ITEM.budgetGroup],
      default: [OUTPUT_COND_ITEM.budgetGroup],
      field: 'itemApplyLevel',
    },
    ExtractTarget: {
      outputCondType: ['ExtractTarget'],
      displayItems: [OUTPUT_EXTRACT_TARGET.all, OUTPUT_EXTRACT_TARGET.specify],
      default: OUTPUT_EXTRACT_TARGET.all,
      field: 'configuredFlg',
    },
  },
  UI_P_ADJUSTED_DOW_INDEX: {
    Store: {
      outputCondType: ['Store'],
      displayItems: [OUTPUT_COND_STORE.tanten],
      default: [OUTPUT_COND_STORE.tanten],
      field: 'storeApplyLevel',
    },
    Item: {
      outputCondType: ['Item'],
      displayItems: [OUTPUT_COND_ITEM.budgetGroup],
      default: [OUTPUT_COND_ITEM.budgetGroup],
      field: 'itemApplyLevel',
    },
    Delete: {
      outputCondType: 'Delete',
      displayItems: [OUTPUT_COND_DELETE.includeDelete],
      default: '',
      field: 'inactiveFlgNvl',
    },
    ExtractTarget: {
      outputCondType: ['ExtractTarget'],
      displayItems: [OUTPUT_EXTRACT_TARGET.all, OUTPUT_EXTRACT_TARGET.specify],
      default: OUTPUT_EXTRACT_TARGET.all,
      field: 'configuredFlg',
    },
  },
  UI_P_WEIGHT_ADJ_CONF: {
    Store: {
      outputCondType: ['Store'],
      displayItems: [OUTPUT_COND_STORE.all],
      default: [OUTPUT_COND_STORE.all],
      field: 'storeApplyLevel',
    },
    Item: {
      outputCondType: ['Item'],
      displayItems: [OUTPUT_COND_ITEM.budgetGroup],
      default: [OUTPUT_COND_ITEM.budgetGroup],
      field: 'itemApplyLevel',
    },
    ExtractTarget: {
      outputCondType: ['ExtractTarget'],
      displayItems: [OUTPUT_EXTRACT_TARGET.all, OUTPUT_EXTRACT_TARGET.specify],
      default: OUTPUT_EXTRACT_TARGET.all,
      field: 'configuredFlg',
    },
  },
  UI_P_ITEM_CONF: {
    Store: {
      outputCondType: ['Store'],
      displayItems: [OUTPUT_COND_STORE.all],
      default: [OUTPUT_COND_STORE.all],
      field: 'storeApplyLevel',
    },
    Item: {
      outputCondType: ['Item'],
      displayItems: [OUTPUT_COND_ITEM.tanpin],
      default: [OUTPUT_COND_ITEM.tanpin],
      field: 'itemApplyLevel',
    },
    ExtractTarget: {
      outputCondType: ['ExtractTarget'],
      displayItems: [OUTPUT_EXTRACT_TARGET.all, OUTPUT_EXTRACT_TARGET.specify],
      default: OUTPUT_EXTRACT_TARGET.all,
      field: 'configuredFlg',
    },
  },
  UI_P_ITEM_ORDER_NUM_ADJ: {
    Store: {
      outputCondType: ['Store'],
      displayItems: [OUTPUT_COND_STORE.all],
      default: [OUTPUT_COND_STORE.all],
      field: 'storeApplyLevel',
    },
    Item: {
      outputCondType: ['Item'],
      displayItems: [OUTPUT_COND_ITEM.tanpin],
      default: [OUTPUT_COND_ITEM.tanpin],
      field: 'itemApplyLevel',
    },
    Delete: {
      outputCondType: 'Delete',
      displayItems: [OUTPUT_COND_DELETE.includeDelete],
      default: '',
      field: 'inactiveFlgNvl',
    },
    ExtractTarget: {
      outputCondType: ['ExtractTarget'],
      displayItems: [OUTPUT_EXTRACT_TARGET.all, OUTPUT_EXTRACT_TARGET.specify],
      default: OUTPUT_EXTRACT_TARGET.all,
      field: 'configuredFlg',
    },
  },
}
// 絞り込み条件の選択上限数
export const SELECT_MAX_COUNT = 100

// 絞り込み条件の表示上限数
export const MAX_COUNT = 500

// ステータスのマッピング
export const asyncStatusTypeDbMapping: { [K in AsyncStatusTypeDb]: State } = {
  [AsyncStatusTypeDb.CompleteExportTransform]: {
    display: 'status.processing',
    isFinished: false,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.ImportTransform]: {
    display: 'status.import-processing1',
    isFinished: false,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.CompleteImportTransform]: {
    display: 'status.import-processing2',
    isFinished: false,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.ImportValidate]: {
    display: 'status.import-processing3',
    isFinished: false,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.CompleteImportValidate]: {
    display: 'status.import-processing4',
    isFinished: false,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.CompleteImportValidateTransform]: {
    display: 'status.import-processing5',
    isFinished: false,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.ImportLoad]: {
    display: 'status.import-processing6',
    isFinished: false,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.SkipImportLoad]: {
    display: 'status.import-processing7',
    isFinished: false,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.CompleteImportLoad]: {
    display: 'status.import-processing7',
    isFinished: false,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.AddExtrainfoColumn]: {
    display: 'status.import-processing8',
    isFinished: false,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.CompleteAddExtrainfoColumn]: {
    display: 'status.import-processing9',
    isFinished: false,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.ImportComplete]: {
    display: 'status.completed',
    isFinished: true,
    isSucceeded: true,
  },
  [AsyncStatusTypeDb.ErrorImportValidate]: {
    display: 'status.validationErrored',
    isFinished: true,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.ErrorTimeout]: {
    display: 'status.timeout',
    isFinished: true,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.ExportComplete]: {
    display: 'status.completed',
    isFinished: true,
    isSucceeded: true,
  },
  [AsyncStatusTypeDb.ExportExtractJson]: {
    display: 'status.export-processing1',
    isFinished: false,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.ExportExtractJsonMod]: {
    display: 'status.export-processing2',
    isFinished: false,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.ExportTransform]: {
    display: 'status.export-processing3',
    isFinished: false,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.ExportTransformMod]: {
    display: 'status.export-processing4',
    isFinished: false,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.FailAddExtrainfoColumn]: {
    display: 'status.errored',
    isFinished: true,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.FailExportExtractJson]: {
    display: 'status.errored',
    isFinished: true,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.FailExportExtractJsonMod]: {
    display: 'status.errored',
    isFinished: true,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.FailExportTransform]: {
    display: 'status.errored',
    isFinished: true,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.FailExportTransformMod]: {
    display: 'status.errored',
    isFinished: true,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.FailImportLoad]: {
    display: 'status.errored',
    isFinished: true,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.FailImportTransform]: {
    display: 'status.errored',
    isFinished: true,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.FailImportValidate]: {
    display: 'status.errored',
    isFinished: true,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.Waiting]: {
    display: 'status.waiting',
    isFinished: false,
    isSucceeded: false,
  },
  [AsyncStatusTypeDb.ErrorExceededLines]: {
    display: 'status.ErrorExceededLines',
    isFinished: true,
    isSucceeded: false,
  },
}
