import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * Leverages the internal Python implementation of UUID (uuid.UUID) to provide native UUID objects
   * in fields, resolvers and input.
   */
  UUID: any;
};

export type AllCausalLogicConfMst = {
  __typename?: 'AllCausalLogicConfMst';
  /** コーザルロジックコード / ロジックパターンを指定する一意となる値 */
  causalLogicCd: Scalars['String'];
  /** コーザルロジック名 / コーザルロジックの種類を表現する名前を設定 / 任意のラベル設定用 */
  causalLogicName: Scalars['String'];
  /** タイムスタンプ / 連携データの更新時刻 / 障害追跡用 */
  dataTime: Scalars['String'];
  /** 処理対象日 / 更新時の処理対象日 / バッチ実行時に指定した処理対象日 */
  targetDate: Scalars['Date'];
  /** 処理対象日_UI / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻 / 取り込み時刻 / 障害追跡用 */
  updTime: Scalars['DateTime'];
  /** 更新時刻_UI / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type AllCausalLogicConfMstConnection = {
  __typename?: 'AllCausalLogicConfMstConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AllCausalLogicConfMstEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AllCausalLogicConfMst` and its cursor. */
export type AllCausalLogicConfMstEdge = {
  __typename?: 'AllCausalLogicConfMstEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AllCausalLogicConfMst>;
};

export type AllConstMst = {
  __typename?: 'AllConstMst';
  /** コメント / 定義の説明 / システム担当者へのコメント */
  comment: Scalars['String'];
  /** 小数 / 小数定義の場合の値 */
  dec?: Maybe<Scalars['Float']>;
  /** 定義キー / 定義キー / 検索キー */
  key: Scalars['String'];
  /** 定義名 / 定義日本語名 */
  name: Scalars['String'];
  /** 整数 / 整数定義の場合の値 */
  num?: Maybe<Scalars['Int']>;
  /** 処理対象日_UI / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** テキスト / 文字列定義の場合の値 */
  txt?: Maybe<Scalars['String']>;
  /** 更新時刻 / 取り込み時刻 / 障害追跡用 */
  updTime: Scalars['DateTime'];
  /** 更新時刻_UI / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type AllConstMstConnection = {
  __typename?: 'AllConstMstConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AllConstMstEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AllConstMst` and its cursor. */
export type AllConstMstEdge = {
  __typename?: 'AllConstMstEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AllConstMst>;
};

export type AllSaleitemMst = {
  __typename?: 'AllSaleitemMst';
  /** 親カテゴリコード / 直属の親となるカテゴリコード / 第2分類コード */
  category0?: Maybe<Scalars['String']>;
  /** カテゴリ１ / 商品分類用 企業１商品マスタ.第3分類コード */
  category1?: Maybe<Scalars['String']>;
  /** カテゴリ２ / 商品分類用 企業１商品マスタ.第4分類コード */
  category2?: Maybe<Scalars['String']>;
  /** カテゴリ３ / 商品分類用 企業１商品マスタ.第5分類コード */
  category3?: Maybe<Scalars['String']>;
  /** カテゴリ４ / 商品分類（予備） */
  category4?: Maybe<Scalars['String']>;
  /** カテゴリ５ / 商品分類（予備） */
  category5?: Maybe<Scalars['String']>;
  /** クラス１ / 分類１（予備） */
  class1?: Maybe<Scalars['String']>;
  /** クラス２ / 分類２（予備） */
  class2?: Maybe<Scalars['String']>;
  /** クラス３ / 分類３（予備） */
  class3?: Maybe<Scalars['String']>;
  /** クラス４ / 分類４（予備） */
  class4?: Maybe<Scalars['String']>;
  /** クラス５ / 分類５（予備） */
  class5?: Maybe<Scalars['String']>;
  /** タイムスタンプ / 連携データの更新時刻 / 障害追跡用 */
  dataTime: Scalars['String'];
  /** 在庫管理商品コード / 在庫管理商品コード（sku） / ケース売り等が無い場合は販売商品コードと同一 */
  itemCd: Scalars['String'];
  /**
   * a: VUiMItemApplyLevel, b: UiMRefItemApplyLevelSaleitem, c: AllSaleitemMst<br><br>
   * a.item_apply_level == '2'
   */
  m2oVUiMItemApplyLevels?: Maybe<VUiMItemApplyLevelConnection>;
  /** メーカコード / 商品のメーカ識別 */
  makerCd?: Maybe<Scalars['String']>;
  /** 在庫管理不要フラグ / 在庫管理要否（0:管理対象、1：管理対象外） / 生鮮等で日がまたげない商品など */
  noManageFlg: Scalars['String'];
  /** 自動発注不可フラグ / 自動発注可否（0:自動発注可能、1：自動発注不可） / 未使用項目（0固定） */
  noOrderFlg: Scalars['String'];
  /** 標準価格 / 商品分類用（拡張用） */
  price?: Maybe<Scalars['Int']>;
  /** 入り数 / 販売商品１に対するskuの数。通常1。 */
  quantity: Scalars['Int'];
  /** 販売商品コード / 販売商品コード（jan） */
  saleItemCd: Scalars['String'];
  /** 販売商品名 / 商品名 / 表示用 */
  saleItemName: Scalars['String'];
  /** 保管可能日数 / 賞味期限など / 無期限商品は0 */
  storageDays: Scalars['Int'];
  /** 発注先コード / 商品の発注先識別 */
  supplierCd?: Maybe<Scalars['String']>;
  /** 処理対象日 / 更新時の処理対象日 / バッチ実行時に指定した処理対象日 */
  targetDate: Scalars['Date'];
  /** 処理対象日_UI / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  uiMCategory0?: Maybe<UiMCategory0>;
  uiMCategory1?: Maybe<UiMCategory1>;
  uiMCategory2?: Maybe<UiMCategory2>;
  uiMCategory3?: Maybe<UiMCategory3>;
  uiMRefItemApplyLevel_SaleItems?: Maybe<UiMRefItemApplyLevelSaleitemConnection>;
  uiMRefStoreitemSaleItem?: Maybe<UiMRefStoreitemSaleitem>;
  uiMShelfPatterns?: Maybe<UiMShelfPatternConnection>;
  uiMStoreShelfPatterns?: Maybe<UiMStoreShelfPatternConnection>;
  uiMStoreShelves?: Maybe<UiMStoreShelfConnection>;
  /** 更新時刻 / 取り込み時刻 / 障害追跡用 */
  updTime: Scalars['DateTime'];
  /** 更新時刻_UI / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  /**
   * a: VUiMItemApplyLevel, b: UiMRefItemApplyLevelSaleitem, c: AllSaleitemMst<br><br>
   * b.sale_item_cd == c.item_cd
   */
  vUiMItemApplyLevel_Item?: Maybe<VUiMItemApplyLevelConnection>;
  vUiPItemConfs?: Maybe<VUiPItemConfConnection>;
  vUiPItemOrderNumAdjs?: Maybe<VUiPItemOrderNumAdjConnection>;
  /**
   * a: ui_m_shelf_pattern, b: all_saleitem_mst<br><br>
   * UI_BUSINESS_DATE between a.start_date and a.end_date
   */
  validUiMShelfPatterns?: Maybe<UiMShelfPatternConnection>;
  /**
   * a: ui_m_store_shelf_pattern, b: all_saleitem_mst<br><br>
   * UI_BUSINESS_DATE between a.start_date and a.end_date
   */
  validUiMStoreShelfPatterns?: Maybe<UiMStoreShelfPatternConnection>;
  /**
   * a: ui_m_store_shelf, b: all_saleitem_mst<br><br>
   * UI_BUSINESS_DATE between b.start_date and b.end_date
   */
  validUiMStoreShelves?: Maybe<UiMStoreShelfConnection>;
};


export type AllSaleitemMstM2oVUiMItemApplyLevelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AllSaleitemMstUiMRefItemApplyLevel_SaleItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AllSaleitemMstUiMShelfPatternsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AllSaleitemMstUiMStoreShelfPatternsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AllSaleitemMstUiMStoreShelvesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AllSaleitemMstVUiMItemApplyLevel_ItemArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AllSaleitemMstVUiPItemConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AllSaleitemMstVUiPItemOrderNumAdjsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AllSaleitemMstValidUiMShelfPatternsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AllSaleitemMstValidUiMStoreShelfPatternsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AllSaleitemMstValidUiMStoreShelvesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type AllSaleitemMstConnection = {
  __typename?: 'AllSaleitemMstConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AllSaleitemMstEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AllSaleitemMst` and its cursor. */
export type AllSaleitemMstEdge = {
  __typename?: 'AllSaleitemMstEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AllSaleitemMst>;
};

export type AllStoreMst = {
  __typename?: 'AllStoreMst';
  /** 分類１ / 店舗の分類 / 企業２コード */
  class1?: Maybe<Scalars['String']>;
  /** 分類２ / 店舗の分類 / 任意。表示または条件によるカスタマイズで参照 */
  class2?: Maybe<Scalars['String']>;
  /** 分類３ / 店舗の分類 / 任意。表示または条件によるカスタマイズで参照 */
  class3?: Maybe<Scalars['String']>;
  /** 閉店日 / 閉店日 / 閉店店舗以外はnull */
  closeDate?: Maybe<Scalars['Date']>;
  /** タイムスタンプ / 連携データの更新時刻 / 障害追跡用 */
  dataTime: Scalars['String'];
  /** 地域１ / 店舗の地域 / 任意。表示または条件によるカスタマイズで参照 */
  location1?: Maybe<Scalars['String']>;
  /** 地域２ / 店舗の地域 / 任意。表示または条件によるカスタマイズで参照 */
  location2?: Maybe<Scalars['String']>;
  /** 地域３ / 店舗の地域 / 任意。表示または条件によるカスタマイズで参照 */
  location3?: Maybe<Scalars['String']>;
  /** 在庫管理不要フラグ / 在庫管理要否（0:管理対象、1：管理対象外） / 閉店で在庫管理が不要な店舗など */
  noManageFlg: Scalars['String'];
  /** 開店日 / 開店日 / 開店日までは販売実績の計算対象外 */
  openDate: Scalars['Date'];
  /** 店舗コード / 店舗コード */
  storeCd: Scalars['String'];
  /** 店舗名 / 店舗名 / 表示用 */
  storeName: Scalars['String'];
  /** 処理対象日 / 更新時の処理対象日 / バッチ実行時に指定した処理対象日 */
  targetDate: Scalars['Date'];
  /** 処理対象日_UI / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  uiMStoreShelfPatterns?: Maybe<UiMStoreShelfPatternConnection>;
  /** 更新時刻 / 取り込み時刻 / 障害追跡用 */
  updTime: Scalars['DateTime'];
  /** 更新時刻_UI / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiMStoreApplyLevel_Stores?: Maybe<VUiMStoreApplyLevelStoreConnection>;
  vUiPAreas?: Maybe<VUiPAreaConnection>;
  vUiPAreastores?: Maybe<VUiPAreastoreConnection>;
  vUiPWeeklyDailyBudgetConf_Weather?: Maybe<VUiPWeeklyDailyBudgetConfWeather>;
  vUiPWeeklyDailyBudgetConfs?: Maybe<VUiPWeeklyDailyBudgetConfConnection>;
  /**
   * a: VUiMStoreApplyLevel, b: VUiMStoreApplyLevelArea, c: AllStoreMst<br><br>
   * UI_BUSINESS_DATE between b.start_date and b.end_date
   */
  validVUiMStoreApplyLevels?: Maybe<VUiMStoreApplyLevelConnection>;
  /**
   * a: v_ui_p_area, b: v_ui_p_areastore, c: all_store_mst<br><br>
   * a.inactive_flg = '0' and<br>b.inactive_flg = '0' and<br>UI_BUSINESS_DATE between b.start_date and b.end_date
   */
  validVUiPAreas?: Maybe<VUiPAreaConnection>;
};


export type AllStoreMstUiMStoreShelfPatternsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AllStoreMstVUiMStoreApplyLevel_StoresArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AllStoreMstVUiPAreasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AllStoreMstVUiPAreastoresArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AllStoreMstVUiPWeeklyDailyBudgetConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AllStoreMstValidVUiMStoreApplyLevelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type AllStoreMstValidVUiPAreasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type AllStoreMstConnection = {
  __typename?: 'AllStoreMstConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AllStoreMstEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AllStoreMst` and its cursor. */
export type AllStoreMstEdge = {
  __typename?: 'AllStoreMstEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AllStoreMst>;
};

export type AllStoreitemMst = {
  __typename?: 'AllStoreitemMst';
  /** 原単価 / 原単価 */
  cost: Scalars['Float'];
  /** タイムスタンプ / 連携データの更新時刻 / 障害追跡用 */
  dataTime: Scalars['String'];
  /** 棚置き指示フラグ / 棚置き支持（0:置かない、1：棚におく） */
  displayFlg: Scalars['String'];
  /** 取り扱いフラグ / 取り扱い要否（0:取り扱わない、1：取り扱う） / 陳列商品に登録されているものと発注対象商品に登録されているもの */
  handleFlg: Scalars['String'];
  /** 最少発注数 / 最少発注数 */
  moq: Scalars['Int'];
  /** 発注カテゴリ / 商品の発注カテゴリ / 発注スケジュールを特定する */
  orderCategory: Scalars['String'];
  /** 発注不可フラグ / 発注不可（0:発注不可でない、1:発注不可） / 発注ロック */
  orderLockFlg: Scalars['String'];
  /** 売単価 / 売単価 */
  price: Scalars['Int'];
  /** 発注単位入数 / 入り数 */
  quantity: Scalars['Int'];
  /** 販売商品コード / 販売商品コード（jan） */
  saleItemCd: Scalars['String'];
  /** 店舗コード / 店舗コード */
  storeCd: Scalars['String'];
  /** 仕入先コード / 仕入先コード */
  suplierCd?: Maybe<Scalars['String']>;
  /** 処理対象日 / 更新時の処理対象日 / バッチ実行時に指定した処理対象日 */
  targetDate: Scalars['Date'];
  /** 処理対象日_UI / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻 / 取り込み時刻 / 障害追跡用 */
  updTime: Scalars['DateTime'];
  /** 更新時刻_UI / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiMStoreItemApplyLevels?: Maybe<VUiMStoreItemApplyLevelConnection>;
  /** 発注先コード / 発注先ード */
  vendorCd: Scalars['String'];
  /** 表示対象フラグ / 表示要否（0:非表示、1：表示） / 画面の表示対象 */
  visibleFlg: Scalars['String'];
};


export type AllStoreitemMstVUiMStoreItemApplyLevelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type AllStoreitemMstConnection = {
  __typename?: 'AllStoreitemMstConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AllStoreitemMstEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AllStoreitemMst` and its cursor. */
export type AllStoreitemMstEdge = {
  __typename?: 'AllStoreitemMstEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AllStoreitemMst>;
};

export type AllTranRefRangeStoreitemMst = {
  __typename?: 'AllTranRefRangeStoreitemMst';
  /** 終了日 / 参照終了日 */
  endDate: Scalars['Date'];
  /** 商品コード / 参照元商品コード */
  itemCd: Scalars['String'];
  /** 参照先商品コード / 参照先商品コード */
  refferenceItemCd: Scalars['String'];
  /** 参照先店舗コード / 参照先店舗 */
  refferenceStoreCd: Scalars['String'];
  /** 開始日 / 参照開始日 */
  startDate: Scalars['Date'];
  /** 店舗コード / 参照元店舗 */
  storeCd: Scalars['String'];
  /** 処理対象日 / 更新時の処理対象日 / バッチ実行時に指定した処理対象日 */
  targetDate: Scalars['Date'];
  /** 処理対象日_UI / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻 / 取り込み時刻 / 障害追跡用 */
  updTime: Scalars['DateTime'];
  /** 更新時刻_UI / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type AllTranRefRangeStoreitemMstConnection = {
  __typename?: 'AllTranRefRangeStoreitemMstConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AllTranRefRangeStoreitemMstEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AllTranRefRangeStoreitemMst` and its cursor. */
export type AllTranRefRangeStoreitemMstEdge = {
  __typename?: 'AllTranRefRangeStoreitemMstEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AllTranRefRangeStoreitemMst>;
};

export type AllVendorMst = {
  __typename?: 'AllVendorMst';
  /** タイムスタンプ / 連携データの更新時刻 / 障害追跡用 */
  dataTime: Scalars['String'];
  /** 発注不可フラグ / 発注可否（0:発注可、1：発注不可） / 発注（推奨）の可否 */
  noOrderFlg: Scalars['String'];
  /** 仕入先コード / 仕入先コード */
  suplierCd?: Maybe<Scalars['String']>;
  /** 処理対象日 / 更新時の処理対象日 / バッチ実行時に指定した処理対象日 */
  targetDate: Scalars['Date'];
  /** 処理対象日_UI / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻 / 取り込み時刻 / 障害追跡用 */
  updTime: Scalars['DateTime'];
  /** 更新時刻_UI / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  /** 発注先コード / 発注先ード */
  vendorCd: Scalars['String'];
  /** 発注先名 / 発注先名 / 表示用 */
  vendorName: Scalars['String'];
};

export type AllVendorMstConnection = {
  __typename?: 'AllVendorMstConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<AllVendorMstEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `AllVendorMst` and its cursor. */
export type AllVendorMstEdge = {
  __typename?: 'AllVendorMstEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<AllVendorMst>;
};

/** An enumeration. */
export enum ArchiveTypeDb {
  Archive = 'ARCHIVE',
  Unarchive = 'UNARCHIVE'
}

/** An enumeration. */
export enum AsyncStatusEncodingType {
  Cp932 = 'CP932',
  Inherit = 'INHERIT',
  Utf_8 = 'UTF_8'
}

/** An enumeration. */
export enum AsyncStatusEncodingTypeDb {
  Cp932 = 'CP932',
  Inherit = 'INHERIT',
  Utf_8 = 'UTF_8'
}

/** An enumeration. */
export enum AsyncStatusFormatType {
  Csv = 'CSV',
  ExcelCsv = 'EXCEL_CSV',
  Json = 'JSON',
  Xlsx = 'XLSX'
}

/** An enumeration. */
export enum AsyncStatusFormatTypeDb {
  Csv = 'CSV',
  ExcelCsv = 'EXCEL_CSV',
  Json = 'JSON',
  Xlsx = 'XLSX'
}

/** An enumeration. */
export enum AsyncStatusTypeDb {
  AddExtrainfoColumn = 'ADD_EXTRAINFO_COLUMN',
  CompleteAddExtrainfoColumn = 'COMPLETE_ADD_EXTRAINFO_COLUMN',
  CompleteExportTransform = 'COMPLETE_EXPORT_TRANSFORM',
  CompleteImportLoad = 'COMPLETE_IMPORT_LOAD',
  CompleteImportTransform = 'COMPLETE_IMPORT_TRANSFORM',
  CompleteImportValidate = 'COMPLETE_IMPORT_VALIDATE',
  CompleteImportValidateTransform = 'COMPLETE_IMPORT_VALIDATE_TRANSFORM',
  ErrorExceededLines = 'ERROR_EXCEEDED_LINES',
  ErrorImportValidate = 'ERROR_IMPORT_VALIDATE',
  ErrorTimeout = 'ERROR_TIMEOUT',
  ExportComplete = 'EXPORT_COMPLETE',
  ExportExtractJson = 'EXPORT_EXTRACT_JSON',
  ExportExtractJsonMod = 'EXPORT_EXTRACT_JSON_MOD',
  ExportTransform = 'EXPORT_TRANSFORM',
  ExportTransformMod = 'EXPORT_TRANSFORM_MOD',
  FailAddExtrainfoColumn = 'FAIL_ADD_EXTRAINFO_COLUMN',
  FailExportExtractJson = 'FAIL_EXPORT_EXTRACT_JSON',
  FailExportExtractJsonMod = 'FAIL_EXPORT_EXTRACT_JSON_MOD',
  FailExportTransform = 'FAIL_EXPORT_TRANSFORM',
  FailExportTransformMod = 'FAIL_EXPORT_TRANSFORM_MOD',
  FailImportLoad = 'FAIL_IMPORT_LOAD',
  FailImportTransform = 'FAIL_IMPORT_TRANSFORM',
  FailImportValidate = 'FAIL_IMPORT_VALIDATE',
  ImportComplete = 'IMPORT_COMPLETE',
  ImportLoad = 'IMPORT_LOAD',
  ImportTransform = 'IMPORT_TRANSFORM',
  ImportValidate = 'IMPORT_VALIDATE',
  SkipImportLoad = 'SKIP_IMPORT_LOAD',
  Waiting = 'WAITING'
}

export type BulkOperationOptionXlsx = {
  header?: InputMaybe<Scalars['Boolean']>;
  sheetName?: InputMaybe<Scalars['String']>;
};

/** Query for bulk export. */
export type BulkOperationRunQuery = {
  __typename?: 'BulkOperationRunQuery';
  operationId: Scalars['UUID'];
};

export type BulkOperationRunQueryColumnLabelMappingObject = {
  from?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
};

export type BulkOperationRunQueryInput = {
  columnLabelMapping?: InputMaybe<Array<InputMaybe<BulkOperationRunQueryColumnLabelMappingObject>>>;
  encodingType: AsyncStatusEncodingType;
  filenameExport?: InputMaybe<Scalars['String']>;
  formatType: AsyncStatusFormatType;
  jmespathQuery?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
  optionXlsx?: InputMaybe<BulkOperationOptionXlsx>;
  query: Scalars['String'];
  templateFile?: InputMaybe<TemplateFile>;
};

/**
 * ファイルアップロード用URLの生成
 *
 * インポート処理フロー
 *   1. ファイルアップロード用URLの生成 ([mutation] createUploadUrl)
 *   2. インポート処理開始 ([mutation] runSimpleImport)
 *   3. インポート状況確認 (importStatus)
 */
export type CreateUploadUrl = {
  __typename?: 'CreateUploadUrl';
  putFilepath: Scalars['String'];
  urlPutFile: Scalars['String'];
};

export type CreateUploadUrlInput = {
  filename: Scalars['String'];
};

/**
 * ヘルスチェックAPI
 * DBに対して汎用的なQueryを実行し、成功したら `ok` をかえす。
 */
export type HealthCheck = {
  __typename?: 'HealthCheck';
  /** 実行結果 */
  result?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Query for bulk export. */
  bulkOperationRunQuery?: Maybe<BulkOperationRunQuery>;
  /**
   * ファイルアップロード用URLの生成
   *
   * インポート処理フロー
   *   1. ファイルアップロード用URLの生成 ([mutation] createUploadUrl)
   *   2. インポート処理開始 ([mutation] runSimpleImport)
   *   3. インポート状況確認 (importStatus)
   */
  createUploadUrl?: Maybe<CreateUploadUrl>;
  /**
   * インポート処理開始
   *
   * インポート処理フロー
   *   1. ファイルアップロード用URLの生成 ([mutation] createUploadUrl)
   *   2. インポート処理開始 ([mutation] runSimpleImport)
   *   3. インポート状況確認 (importStatus)
   *
   * TODO: backup import file (maybe overwrite import file)
   */
  runSimpleImport?: Maybe<RunSimpleImport>;
};


export type MutationBulkOperationRunQueryArgs = {
  input: BulkOperationRunQueryInput;
};


export type MutationCreateUploadUrlArgs = {
  input: CreateUploadUrlInput;
};


export type MutationRunSimpleImportArgs = {
  input: RunSimpleImportInput;
};

export type OrderBy = {
  direction?: InputMaybe<OrderByDirection>;
  field: Scalars['String'];
  nullsOption?: InputMaybe<OrderByNullsOption>;
};

export enum OrderByDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum OrderByNullsOption {
  NullsFirst = 'NULLS_FIRST',
  NullsLast = 'NULLS_LAST'
}

export type OutApplyBudgetItemBaseMst = {
  __typename?: 'OutApplyBudgetItemBaseMst';
  /** 単品係数 / 値１で100%を表す */
  adjustRate?: Maybe<Scalars['Float']>;
  /** 在庫管理商品コード */
  itemCd: Scalars['String'];
  /** ランク１納品曜日指定 */
  r1StoreinDowPatternId?: Maybe<Scalars['String']>;
  /** ランク２納品曜日指定 */
  r2StoreinDowPatternId?: Maybe<Scalars['String']>;
  /** ランク３納品曜日指定 */
  r3StoreinDowPatternId?: Maybe<Scalars['String']>;
  /** ランク４納品曜日指定 */
  r4StoreinDowPatternId?: Maybe<Scalars['String']>;
  /** ランク５納品曜日指定 */
  r5StoreinDowPatternId?: Maybe<Scalars['String']>;
  /** ランク６納品曜日指定 */
  r6StoreinDowPatternId?: Maybe<Scalars['String']>;
  /** ランク７納品曜日指定 / 未使用 */
  r7StoreinDowPatternId?: Maybe<Scalars['String']>;
  /** ランク８納品曜日指定 / 未使用 */
  r8StoreinDowPatternId?: Maybe<Scalars['String']>;
  /** ランク９納品曜日指定 / 未使用 */
  r9StoreinDowPatternId?: Maybe<Scalars['String']>;
  /** ランク１０納品曜日指定 / 未使用 */
  r10StoreinDowPatternId?: Maybe<Scalars['String']>;
  /** 処理対象日 / 更新時の処理対象日 */
  targetDate: Scalars['Date'];
  /** 処理対象日_UI / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 全店発注数下限 */
  totalOrderNumLower?: Maybe<Scalars['Int']>;
  /** 全店発注数上限 */
  totalOrderNumUpper?: Maybe<Scalars['Int']>;
  /** 更新時刻 / 処理時刻 / 障害追跡用 */
  updTime: Scalars['DateTime'];
  /** 更新時刻_UI / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  /** 週間最低発注数 */
  weeklyMinOrderNum?: Maybe<Scalars['Int']>;
};

export type OutApplyBudgetItemBaseMstConnection = {
  __typename?: 'OutApplyBudgetItemBaseMstConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OutApplyBudgetItemBaseMstEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `OutApplyBudgetItemBaseMst` and its cursor. */
export type OutApplyBudgetItemBaseMstEdge = {
  __typename?: 'OutApplyBudgetItemBaseMstEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<OutApplyBudgetItemBaseMst>;
};

export type OutApplyVarietyGroupItem = {
  __typename?: 'OutApplyVarietyGroupItem';
  /** 過去２週廃棄率 */
  actualDisposalRate: Scalars['Float'];
  /** 在庫管理商品コード */
  itemCd: Scalars['String'];
  /** 最終廃棄率 */
  latestDisposalRate: Scalars['Float'];
  /** 優先順位 / １を最優先とする連番 / 予備軍入れ替え前の値 */
  priority: Scalars['Int'];
  /** 入替え後優先順位 / １を最優先とする連番 / 予備軍入れ替え後の値 */
  priorityAfter: Scalars['Int'];
  /** 入替え先商品コード / 入替え先の商品コード。入替えなしの場合は在庫管理商品コードと同じ */
  swapItemCd: Scalars['String'];
  /** 処理対象日 / 更新時の処理対象日 */
  targetDate: Scalars['Date'];
  /** 処理対象日_UI / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻 / 処理時刻 / 障害追跡用 */
  updTime: Scalars['DateTime'];
  /** 更新時刻_UI / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  /** バラエティグループid */
  varietyGroupId: Scalars['String'];
};

export type OutApplyVarietyGroupItemConnection = {
  __typename?: 'OutApplyVarietyGroupItemConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OutApplyVarietyGroupItemEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `OutApplyVarietyGroupItem` and its cursor. */
export type OutApplyVarietyGroupItemEdge = {
  __typename?: 'OutApplyVarietyGroupItemEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<OutApplyVarietyGroupItem>;
};

export type OutWeekIndex = {
  __typename?: 'OutWeekIndex';
  /** 予算商品群id */
  budgetGroupId: Scalars['String'];
  /** 曜日別pi値 / 予算商品群内の曜日別商品別piの合計 */
  dowPiValue: Scalars['Float'];
  /** 販売曜日 / 販売日の曜日。0:日曜〜6:土曜日 */
  saleDow: Scalars['Int'];
  /** 店舗コード */
  storeCd: Scalars['String'];
  /** 週合計pi値 / 全曜日のpi値合計 */
  sumPiValue: Scalars['Float'];
  /** 処理対象日 / 更新時の処理対象日 */
  targetDate: Scalars['Date'];
  /** 処理対象日_UI / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻 / 処理時刻 / 障害追跡用 */
  updTime: Scalars['DateTime'];
  /** 更新時刻_UI / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  /** 曜日指数 / 曜日比率の合計を７（平均１）とした数値 / 全体で７になる */
  weekIndex: Scalars['Float'];
  /** 曜日比率 / 曜日別pi値/週合計pi値 / 全体で１になる */
  weekRatio: Scalars['Float'];
};

export type OutWeekIndexConnection = {
  __typename?: 'OutWeekIndexConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OutWeekIndexEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `OutWeekIndex` and its cursor. */
export type OutWeekIndexEdge = {
  __typename?: 'OutWeekIndexEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<OutWeekIndex>;
};

export type OutWeekSalesStoreRank = {
  __typename?: 'OutWeekSalesStoreRank';
  /** 平均週販 / 在庫管理商品コード */
  salesAmountAvg: Scalars['Float'];
  /** 店舗コード / 発注店舗コード */
  storeCd: Scalars['String'];
  /** 店ランク / 発注店舗コード */
  storeRank: Scalars['Int'];
  /** 処理対象日 / 更新時の処理対象日 / バッチ実行時に指定した処理対象日 */
  targetDate: Scalars['Date'];
  /** 処理対象日_UI / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻 / 更新時刻 / 障害追跡用 */
  updTime: Scalars['DateTime'];
  /** 更新時刻_UI / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type OutWeekSalesStoreRankConnection = {
  __typename?: 'OutWeekSalesStoreRankConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<OutWeekSalesStoreRankEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `OutWeekSalesStoreRank` and its cursor. */
export type OutWeekSalesStoreRankEdge = {
  __typename?: 'OutWeekSalesStoreRankEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<OutWeekSalesStoreRank>;
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  causalLogicConf?: Maybe<AllCausalLogicConfMst>;
  causalLogicConfs?: Maybe<AllCausalLogicConfMstConnection>;
  const?: Maybe<AllConstMst>;
  consts?: Maybe<AllConstMstConnection>;
  /**
   * ヘルスチェックAPI
   * DBに対して汎用的なQueryを実行し、成功したら `ok` をかえす。
   */
  healthCheck?: Maybe<HealthCheck>;
  outApplyBudgetItemBase?: Maybe<OutApplyBudgetItemBaseMst>;
  outApplyBudgetItemBases?: Maybe<OutApplyBudgetItemBaseMstConnection>;
  outApplyVarietyGroupItem?: Maybe<OutApplyVarietyGroupItem>;
  outApplyVarietyGroupItems?: Maybe<OutApplyVarietyGroupItemConnection>;
  outWeekIndex?: Maybe<OutWeekIndex>;
  outWeekIndices?: Maybe<OutWeekIndexConnection>;
  outWeekSalesStoreRank?: Maybe<OutWeekSalesStoreRank>;
  outWeekSalesStoreRanks?: Maybe<OutWeekSalesStoreRankConnection>;
  /** ファイルアップロード用URL取得 */
  s3PresignedUrlForUpload?: Maybe<S3PresignedUrlForUpload>;
  saleItem?: Maybe<AllSaleitemMst>;
  saleItems?: Maybe<AllSaleitemMstConnection>;
  skuDiscard?: Maybe<SkuDiscard>;
  skuDiscards?: Maybe<SkuDiscardConnection>;
  skuSale?: Maybe<SkuSale>;
  skuSales?: Maybe<SkuSaleConnection>;
  store?: Maybe<AllStoreMst>;
  storeItem?: Maybe<AllStoreitemMst>;
  storeItems?: Maybe<AllStoreitemMstConnection>;
  stores?: Maybe<AllStoreMstConnection>;
  /** システム設定情報 */
  systemSetting?: Maybe<SystemSetting>;
  /** システム状態 */
  systemStatus?: Maybe<SystemStatus>;
  tranRefRangeStoreItem?: Maybe<AllTranRefRangeStoreitemMst>;
  tranRefRangeStoreItems?: Maybe<AllTranRefRangeStoreitemMstConnection>;
  uiMCategory0?: Maybe<UiMCategory0>;
  uiMCategory0s?: Maybe<UiMCategory0Connection>;
  uiMCategory1?: Maybe<UiMCategory1>;
  uiMCategory1s?: Maybe<UiMCategory1Connection>;
  uiMCategory2?: Maybe<UiMCategory2>;
  uiMCategory2s?: Maybe<UiMCategory2Connection>;
  uiMCategory3?: Maybe<UiMCategory3>;
  uiMCategory3s?: Maybe<UiMCategory3Connection>;
  uiMItemWeeklySale?: Maybe<UiMItemWeeklySale>;
  uiMItemWeeklySaleDenom?: Maybe<UiMItemWeeklySaleDenom>;
  uiMItemWeeklySaleDenoms?: Maybe<UiMItemWeeklySaleDenomConnection>;
  uiMItemWeeklySales?: Maybe<UiMItemWeeklySaleConnection>;
  uiMMaker?: Maybe<UiMMaker>;
  uiMMakers?: Maybe<UiMMakerConnection>;
  uiMRefItemApplyLevel_Category1?: Maybe<UiMRefItemApplyLevelCategory1>;
  uiMRefItemApplyLevel_Category1s?: Maybe<UiMRefItemApplyLevelCategory1Connection>;
  uiMRefItemApplyLevel_Category2?: Maybe<UiMRefItemApplyLevelCategory2>;
  uiMRefItemApplyLevel_Category2s?: Maybe<UiMRefItemApplyLevelCategory2Connection>;
  uiMRefItemApplyLevel_Category3?: Maybe<UiMRefItemApplyLevelCategory3>;
  uiMRefItemApplyLevel_Category3s?: Maybe<UiMRefItemApplyLevelCategory3Connection>;
  uiMRefItemApplyLevel_SaleItem?: Maybe<UiMRefItemApplyLevelSaleitem>;
  uiMRefItemApplyLevel_SaleItems?: Maybe<UiMRefItemApplyLevelSaleitemConnection>;
  uiMRefStoreitemLevelShelfPattern?: Maybe<UiMRefStoreitemLevelShelfPattern>;
  uiMRefStoreitemLevelShelfPatterns?: Maybe<UiMRefStoreitemLevelShelfPatternConnection>;
  uiMRefStoreitemSaleItem?: Maybe<UiMRefStoreitemSaleitem>;
  uiMRefStoreitemSaleItems?: Maybe<UiMRefStoreitemSaleitemConnection>;
  uiMRefStoreitemScopeSale?: Maybe<UiMRefStoreitemScopeSale>;
  uiMRefStoreitemScopeSales?: Maybe<UiMRefStoreitemScopeSaleConnection>;
  uiMRefStoreitemScopeStoreItem?: Maybe<UiMRefStoreitemScopeStoreitem>;
  uiMRefStoreitemScopeStoreItems?: Maybe<UiMRefStoreitemScopeStoreitemConnection>;
  uiMShelfPattern?: Maybe<UiMShelfPattern>;
  uiMShelfPatterns?: Maybe<UiMShelfPatternConnection>;
  uiMStoreLocation?: Maybe<UiMStoreLocation>;
  uiMStoreLocations?: Maybe<UiMStoreLocationConnection>;
  uiMStoreShelf?: Maybe<UiMStoreShelf>;
  uiMStoreShelfItemStatus?: Maybe<UiMStoreShelfItemStatus>;
  uiMStoreShelfItemStatuses?: Maybe<UiMStoreShelfItemStatusConnection>;
  uiMStoreShelfPattern?: Maybe<UiMStoreShelfPattern>;
  uiMStoreShelfPatterns?: Maybe<UiMStoreShelfPatternConnection>;
  uiMStoreShelves?: Maybe<UiMStoreShelfConnection>;
  uiMStoreWeather?: Maybe<UiMStoreWeather>;
  uiMStoreWeathers?: Maybe<UiMStoreWeatherConnection>;
  uiMStoreitemWeeklySale?: Maybe<UiMStoreitemWeeklySale>;
  uiMStoreitemWeeklySales?: Maybe<UiMStoreitemWeeklySaleConnection>;
  uiMWeather?: Maybe<UiMWeather>;
  uiMWeathers?: Maybe<UiMWeatherConnection>;
  uiPAdjustedDowIndex?: Maybe<UiPAdjustedDowIndex>;
  uiPAdjustedDowIndices?: Maybe<UiPAdjustedDowIndexConnection>;
  uiPArea?: Maybe<UiPArea>;
  uiPAreas?: Maybe<UiPAreaConnection>;
  uiPAreastore?: Maybe<UiPAreastore>;
  uiPAreastores?: Maybe<UiPAreastoreConnection>;
  uiPAutoorderStop?: Maybe<UiPAutoorderStop>;
  uiPAutoorderStops?: Maybe<UiPAutoorderStopConnection>;
  uiPBaseConf?: Maybe<UiPBaseConf>;
  uiPBaseConfs?: Maybe<UiPBaseConfConnection>;
  uiPBudgetGroup?: Maybe<UiPBudgetGroup>;
  uiPBudgetGroups?: Maybe<UiPBudgetGroupConnection>;
  uiPCausalName?: Maybe<UiPCausalName>;
  uiPCausalNames?: Maybe<UiPCausalNameConnection>;
  uiPCausalWeightConf?: Maybe<UiPCausalWeightConf>;
  uiPCausalWeightConfs?: Maybe<UiPCausalWeightConfConnection>;
  uiPDspInfo?: Maybe<UiPDspInfo>;
  uiPDspInfos?: Maybe<UiPDspInfoConnection>;
  uiPItemConf?: Maybe<UiPItemConf>;
  uiPItemConfs?: Maybe<UiPItemConfConnection>;
  uiPItemFeature?: Maybe<UiPItemFeature>;
  uiPItemFeatures?: Maybe<UiPItemFeatureConnection>;
  uiPItemOrderNumAdj?: Maybe<UiPItemOrderNumAdj>;
  uiPItemOrderNumAdjs?: Maybe<UiPItemOrderNumAdjConnection>;
  uiPLowRankLogicConf?: Maybe<UiPLowRankLogicConf>;
  uiPLowRankLogicConfs?: Maybe<UiPLowRankLogicConfConnection>;
  uiPOrderPatternSettingSii?: Maybe<UiPOrderPatternSettingSii>;
  uiPOrderPatternSettingSiis?: Maybe<UiPOrderPatternSettingSiiConnection>;
  uiPStoreRank?: Maybe<UiPStoreRank>;
  uiPStoreRanks?: Maybe<UiPStoreRankConnection>;
  uiPStoreinPattern?: Maybe<UiPStoreinPattern>;
  uiPStoreinPatterns?: Maybe<UiPStoreinPatternConnection>;
  uiPTranRefItem?: Maybe<UiPTranRefItem>;
  uiPTranRefItems?: Maybe<UiPTranRefItemConnection>;
  uiPTranRefStore?: Maybe<UiPTranRefStore>;
  uiPTranRefStores?: Maybe<UiPTranRefStoreConnection>;
  uiPUser?: Maybe<UiPUser>;
  uiPUsers?: Maybe<UiPUserConnection>;
  uiPVarietyGroup?: Maybe<UiPVarietyGroup>;
  uiPVarietyGroups?: Maybe<UiPVarietyGroupConnection>;
  uiPWeeklyDailyBudgetConf?: Maybe<UiPWeeklyDailyBudgetConf>;
  uiPWeeklyDailyBudgetConfs?: Maybe<UiPWeeklyDailyBudgetConfConnection>;
  uiPWeightAdjConf?: Maybe<UiPWeightAdjConf>;
  uiPWeightAdjConfs?: Maybe<UiPWeightAdjConfConnection>;
  uiSAsyncExportStatus?: Maybe<UiSAsyncExportStatus>;
  uiSAsyncExportStatuses?: Maybe<UiSAsyncExportStatusConnection>;
  uiSAsyncImportStatus?: Maybe<UiSAsyncImportStatus>;
  uiSAsyncImportStatuses?: Maybe<UiSAsyncImportStatusConnection>;
  uiSConst?: Maybe<UiSConst>;
  uiSConsts?: Maybe<UiSConstConnection>;
  uiSImportLock?: Maybe<UiSImportLock>;
  uiSImportLocks?: Maybe<UiSImportLockConnection>;
  uiSUser?: Maybe<UiSUser>;
  uiSUsers?: Maybe<UiSUserConnection>;
  /** ユーザー情報 */
  userInformation?: Maybe<UserInformation>;
  vUiMItemApplyLevel?: Maybe<VUiMItemApplyLevel>;
  vUiMItemApplyLevels?: Maybe<VUiMItemApplyLevelConnection>;
  vUiMStoreApplyLevel?: Maybe<VUiMStoreApplyLevel>;
  vUiMStoreApplyLevel_Area?: Maybe<VUiMStoreApplyLevelArea>;
  vUiMStoreApplyLevel_Areas?: Maybe<VUiMStoreApplyLevelAreaConnection>;
  vUiMStoreApplyLevel_Store?: Maybe<VUiMStoreApplyLevelStore>;
  vUiMStoreApplyLevel_Stores?: Maybe<VUiMStoreApplyLevelStoreConnection>;
  vUiMStoreApplyLevels?: Maybe<VUiMStoreApplyLevelConnection>;
  vUiMStoreItemApplyLevel?: Maybe<VUiMStoreItemApplyLevel>;
  vUiMStoreItemApplyLevels?: Maybe<VUiMStoreItemApplyLevelConnection>;
  vUiPAdjustedDowIndex?: Maybe<VUiPAdjustedDowIndex>;
  vUiPAdjustedDowIndices?: Maybe<VUiPAdjustedDowIndexConnection>;
  vUiPArea?: Maybe<VUiPArea>;
  vUiPAreas?: Maybe<VUiPAreaConnection>;
  vUiPAreastore?: Maybe<VUiPAreastore>;
  vUiPAreastores?: Maybe<VUiPAreastoreConnection>;
  vUiPAutoorderStop?: Maybe<VUiPAutoorderStop>;
  vUiPAutoorderStops?: Maybe<VUiPAutoorderStopConnection>;
  vUiPBaseConf?: Maybe<VUiPBaseConf>;
  vUiPBaseConf_AllStoreConfig?: Maybe<VUiPBaseConfAllStoreConfig>;
  vUiPBaseConf_AllStoreConfigs?: Maybe<VUiPBaseConfAllStoreConfigConnection>;
  vUiPBaseConf_EachStoreConfig?: Maybe<VUiPBaseConfEachStoreConfig>;
  vUiPBaseConf_EachStoreConfigs?: Maybe<VUiPBaseConfEachStoreConfigConnection>;
  vUiPBaseConf_TranRef?: Maybe<VUiPBaseConfTranRef>;
  vUiPBaseConf_TranRefs?: Maybe<VUiPBaseConfTranRefConnection>;
  vUiPBaseConfs?: Maybe<VUiPBaseConfConnection>;
  vUiPBudgetGroup?: Maybe<VUiPBudgetGroup>;
  vUiPBudgetGroups?: Maybe<VUiPBudgetGroupConnection>;
  vUiPCausalName?: Maybe<VUiPCausalName>;
  vUiPCausalNames?: Maybe<VUiPCausalNameConnection>;
  vUiPCausalWeightConf?: Maybe<VUiPCausalWeightConf>;
  vUiPCausalWeightConfs?: Maybe<VUiPCausalWeightConfConnection>;
  vUiPDspInfo?: Maybe<VUiPDspInfo>;
  vUiPDspInfos?: Maybe<VUiPDspInfoConnection>;
  vUiPItemConf?: Maybe<VUiPItemConf>;
  vUiPItemConfs?: Maybe<VUiPItemConfConnection>;
  vUiPItemFeature?: Maybe<VUiPItemFeature>;
  vUiPItemFeatures?: Maybe<VUiPItemFeatureConnection>;
  vUiPItemOrderNumAdj?: Maybe<VUiPItemOrderNumAdj>;
  vUiPItemOrderNumAdjs?: Maybe<VUiPItemOrderNumAdjConnection>;
  vUiPLowRankLogicConf?: Maybe<VUiPLowRankLogicConf>;
  vUiPLowRankLogicConfs?: Maybe<VUiPLowRankLogicConfConnection>;
  vUiPNewStoreBulkOrder?: Maybe<VUiPNewStoreBulkOrder>;
  vUiPNewStoreBulkOrders?: Maybe<VUiPNewStoreBulkOrderConnection>;
  vUiPOrderPatternSettingSii?: Maybe<VUiPOrderPatternSettingSii>;
  vUiPOrderPatternSettingSiis?: Maybe<VUiPOrderPatternSettingSiiConnection>;
  vUiPStoreRank?: Maybe<VUiPStoreRank>;
  vUiPStoreRanks?: Maybe<VUiPStoreRankConnection>;
  vUiPStoreinPattern?: Maybe<VUiPStoreinPattern>;
  vUiPStoreinPatterns?: Maybe<VUiPStoreinPatternConnection>;
  vUiPTranRefItem?: Maybe<VUiPTranRefItem>;
  vUiPTranRefItems?: Maybe<VUiPTranRefItemConnection>;
  vUiPTranRefStore?: Maybe<VUiPTranRefStore>;
  vUiPTranRefStores?: Maybe<VUiPTranRefStoreConnection>;
  vUiPUser?: Maybe<VUiPUser>;
  vUiPUsers?: Maybe<VUiPUserConnection>;
  vUiPVarietyGroup?: Maybe<VUiPVarietyGroup>;
  vUiPVarietyGroups?: Maybe<VUiPVarietyGroupConnection>;
  vUiPWeeklyDailyBudgetConf?: Maybe<VUiPWeeklyDailyBudgetConf>;
  vUiPWeeklyDailyBudgetConf_DowIndex?: Maybe<VUiPWeeklyDailyBudgetConfDowIndex>;
  vUiPWeeklyDailyBudgetConf_DowIndices?: Maybe<VUiPWeeklyDailyBudgetConfDowIndexConnection>;
  vUiPWeeklyDailyBudgetConf_Weather?: Maybe<VUiPWeeklyDailyBudgetConfWeather>;
  vUiPWeeklyDailyBudgetConf_Weathers?: Maybe<VUiPWeeklyDailyBudgetConfWeatherConnection>;
  vUiPWeeklyDailyBudgetConfs?: Maybe<VUiPWeeklyDailyBudgetConfConnection>;
  vUiPWeightAdjConf?: Maybe<VUiPWeightAdjConf>;
  vUiPWeightAdjConfs?: Maybe<VUiPWeightAdjConfConnection>;
  vendor?: Maybe<AllVendorMst>;
  vendors?: Maybe<AllVendorMstConnection>;
};


export type QueryCausalLogicConfArgs = {
  causalLogicCd: Scalars['String'];
};


export type QueryCausalLogicConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryConstArgs = {
  key: Scalars['String'];
  name: Scalars['String'];
};


export type QueryConstsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryOutApplyBudgetItemBaseArgs = {
  itemCd: Scalars['String'];
};


export type QueryOutApplyBudgetItemBasesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryOutApplyVarietyGroupItemArgs = {
  itemCd: Scalars['String'];
  varietyGroupId: Scalars['String'];
};


export type QueryOutApplyVarietyGroupItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryOutWeekIndexArgs = {
  budgetGroupId: Scalars['String'];
  saleDow: Scalars['Int'];
  storeCd: Scalars['String'];
};


export type QueryOutWeekIndicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryOutWeekSalesStoreRankArgs = {
  storeCd: Scalars['String'];
};


export type QueryOutWeekSalesStoreRanksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QuerySaleItemArgs = {
  saleItemCd: Scalars['String'];
};


export type QuerySaleItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QuerySkuDiscardArgs = {
  discardDate: Scalars['Date'];
  itemCd: Scalars['String'];
  storeCd: Scalars['String'];
};


export type QuerySkuDiscardsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QuerySkuSaleArgs = {
  itemCd: Scalars['String'];
  saleDate: Scalars['Date'];
  storeCd: Scalars['String'];
};


export type QuerySkuSalesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryStoreArgs = {
  storeCd: Scalars['String'];
};


export type QueryStoreItemArgs = {
  saleItemCd: Scalars['String'];
  storeCd: Scalars['String'];
};


export type QueryStoreItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryStoresArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryTranRefRangeStoreItemArgs = {
  itemCd: Scalars['String'];
  startDate: Scalars['Date'];
  storeCd: Scalars['String'];
};


export type QueryTranRefRangeStoreItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMCategory0Args = {
  category0Cd: Scalars['String'];
};


export type QueryUiMCategory0sArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMCategory1Args = {
  category1Cd: Scalars['String'];
};


export type QueryUiMCategory1sArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMCategory2Args = {
  category1Cd: Scalars['String'];
  category2Cd: Scalars['String'];
};


export type QueryUiMCategory2sArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMCategory3Args = {
  category1Cd: Scalars['String'];
  category2Cd: Scalars['String'];
  category3Cd: Scalars['String'];
};


export type QueryUiMCategory3sArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMItemWeeklySaleArgs = {
  itemCd: Scalars['String'];
  relWeekInx: Scalars['Int'];
};


export type QueryUiMItemWeeklySaleDenomArgs = {
  itemCd: Scalars['String'];
};


export type QueryUiMItemWeeklySaleDenomsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMItemWeeklySalesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMMakerArgs = {
  makerCd: Scalars['String'];
};


export type QueryUiMMakersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMRefItemApplyLevel_Category1Args = {
  category1Cd: Scalars['String'];
  itemApplyLevel: Scalars['String'];
  itemApplyScope: Scalars['String'];
};


export type QueryUiMRefItemApplyLevel_Category1sArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMRefItemApplyLevel_Category2Args = {
  category1Cd: Scalars['String'];
  category2Cd: Scalars['String'];
  itemApplyLevel: Scalars['String'];
  itemApplyScope: Scalars['String'];
};


export type QueryUiMRefItemApplyLevel_Category2sArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMRefItemApplyLevel_Category3Args = {
  category1Cd: Scalars['String'];
  category2Cd: Scalars['String'];
  category3Cd: Scalars['String'];
  itemApplyLevel: Scalars['String'];
  itemApplyScope: Scalars['String'];
};


export type QueryUiMRefItemApplyLevel_Category3sArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMRefItemApplyLevel_SaleItemArgs = {
  itemApplyLevel: Scalars['String'];
  itemApplyScope: Scalars['String'];
  saleItemCd: Scalars['String'];
};


export type QueryUiMRefItemApplyLevel_SaleItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMRefStoreitemLevelShelfPatternArgs = {
  itemApplyLevel: Scalars['String'];
  itemApplyScope: Scalars['String'];
  startDate: Scalars['Date'];
  storeApplyLevel: Scalars['String'];
  storeApplyScope: Scalars['String'];
};


export type QueryUiMRefStoreitemLevelShelfPatternsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMRefStoreitemSaleItemArgs = {
  saleItemCd: Scalars['String'];
};


export type QueryUiMRefStoreitemSaleItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMRefStoreitemScopeSaleArgs = {
  itemApplyLevel: Scalars['String'];
  itemApplyScope: Scalars['String'];
  storeApplyLevel: Scalars['String'];
  storeApplyScope: Scalars['String'];
};


export type QueryUiMRefStoreitemScopeSalesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMRefStoreitemScopeStoreItemArgs = {
  itemApplyLevel: Scalars['String'];
  itemApplyScope: Scalars['String'];
  saleItemCd: Scalars['String'];
  storeApplyLevel: Scalars['String'];
  storeApplyScope: Scalars['String'];
  storeCd: Scalars['String'];
};


export type QueryUiMRefStoreitemScopeStoreItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMShelfPatternArgs = {
  saleItemCd: Scalars['String'];
  shelfPatternCd: Scalars['String'];
  startDate: Scalars['Date'];
};


export type QueryUiMShelfPatternsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMStoreLocationArgs = {
  storeCd: Scalars['String'];
};


export type QueryUiMStoreLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMStoreShelfArgs = {
  categoryCd: Scalars['String'];
  columnNo: Scalars['String'];
  patternCd: Scalars['String'];
  patternSeqNo: Scalars['String'];
  patternStandNo: Scalars['String'];
  saleItemCd: Scalars['String'];
  shelfUpNo: Scalars['String'];
  standSeqNo: Scalars['String'];
  startDate: Scalars['Date'];
  storeCd: Scalars['String'];
};


export type QueryUiMStoreShelfItemStatusArgs = {
  baseDate: Scalars['Date'];
  categoryCd: Scalars['String'];
  columnNo: Scalars['String'];
  patternCd: Scalars['String'];
  patternSeqNo: Scalars['String'];
  patternStandNo: Scalars['String'];
  saleItemCd: Scalars['String'];
  shelfUpNo: Scalars['String'];
  standSeqNo: Scalars['String'];
  startDate: Scalars['Date'];
  storeCd: Scalars['String'];
};


export type QueryUiMStoreShelfItemStatusesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMStoreShelfPatternArgs = {
  saleItemCd: Scalars['String'];
  shelfPatternCd: Scalars['String'];
  startDate: Scalars['Date'];
  storeCd: Scalars['String'];
};


export type QueryUiMStoreShelfPatternsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMStoreShelvesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMStoreWeatherArgs = {
  saleDate: Scalars['Date'];
  storeCd: Scalars['String'];
};


export type QueryUiMStoreWeathersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMStoreitemWeeklySaleArgs = {
  itemCd: Scalars['String'];
  relWeekInx: Scalars['Int'];
  storeCd: Scalars['String'];
};


export type QueryUiMStoreitemWeeklySalesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiMWeatherArgs = {
  weatherCd: Scalars['String'];
};


export type QueryUiMWeathersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPAdjustedDowIndexArgs = {
  budgetGroupId: Scalars['String'];
  startDate: Scalars['Date'];
  storeCd: Scalars['String'];
};


export type QueryUiPAdjustedDowIndicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPAreaArgs = {
  areaCd: Scalars['String'];
};


export type QueryUiPAreasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPAreastoreArgs = {
  areaCd: Scalars['String'];
  startDate: Scalars['Date'];
  storeCd: Scalars['String'];
};


export type QueryUiPAreastoresArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPAutoorderStopArgs = {
  itemApplyLevel: Scalars['String'];
  itemApplyScope: Scalars['String'];
  startDate: Scalars['Date'];
  storeApplyLevel: Scalars['String'];
  storeApplyScope: Scalars['String'];
};


export type QueryUiPAutoorderStopsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPBaseConfArgs = {
  itemApplyLevel: Scalars['String'];
  itemApplyScope: Scalars['String'];
  startDate: Scalars['Date'];
  storeApplyLevel: Scalars['String'];
  storeApplyScope: Scalars['String'];
};


export type QueryUiPBaseConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPBudgetGroupArgs = {
  budgetGroupId: Scalars['String'];
};


export type QueryUiPBudgetGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPCausalNameArgs = {
  causalCd: Scalars['String'];
};


export type QueryUiPCausalNamesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPCausalWeightConfArgs = {
  adjustType: Scalars['Int'];
  causalCd: Scalars['String'];
  causalType: Scalars['String'];
  itemApplyLevel: Scalars['String'];
  itemApplyScope: Scalars['String'];
  startDate: Scalars['Date'];
  storeApplyLevel: Scalars['String'];
  storeApplyScope: Scalars['String'];
};


export type QueryUiPCausalWeightConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPDspInfoArgs = {
  ctgCode: Scalars['String'];
  ptnCode: Scalars['String'];
  shelfupNo: Scalars['String'];
  standNo: Scalars['String'];
  storeCd: Scalars['String'];
};


export type QueryUiPDspInfosArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPItemConfArgs = {
  itemCd: Scalars['String'];
};


export type QueryUiPItemConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPItemFeatureArgs = {
  itemApplyLevel: Scalars['String'];
  itemApplyScope: Scalars['String'];
};


export type QueryUiPItemFeaturesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPItemOrderNumAdjArgs = {
  itemCd: Scalars['String'];
  startDate: Scalars['Date'];
};


export type QueryUiPItemOrderNumAdjsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPLowRankLogicConfArgs = {
  minWeeklyOrderNum: Scalars['Int'];
  storeRank: Scalars['Int'];
  storeinDowPatternId: Scalars['String'];
};


export type QueryUiPLowRankLogicConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPOrderPatternSettingSiiArgs = {
  category1: Scalars['String'];
  startDate: Scalars['Date'];
  storeApplyLevel: Scalars['String'];
  storeApplyScope: Scalars['String'];
  vendorCd: Scalars['String'];
};


export type QueryUiPOrderPatternSettingSiisArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPStoreRankArgs = {
  storeCd: Scalars['String'];
};


export type QueryUiPStoreRanksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPStoreinPatternArgs = {
  storeinDowPatternId: Scalars['String'];
};


export type QueryUiPStoreinPatternsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPTranRefItemArgs = {
  referrerItemCd: Scalars['String'];
};


export type QueryUiPTranRefItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPTranRefStoreArgs = {
  category: Scalars['String'];
  refLevel: Scalars['String'];
  referrerStoreCd: Scalars['String'];
};


export type QueryUiPTranRefStoresArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPUserArgs = {
  loginId: Scalars['String'];
};


export type QueryUiPUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPVarietyGroupArgs = {
  varietyGroupId: Scalars['String'];
};


export type QueryUiPVarietyGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPWeeklyDailyBudgetConfArgs = {
  budgetGroupId: Scalars['String'];
  storeCd: Scalars['String'];
};


export type QueryUiPWeeklyDailyBudgetConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiPWeightAdjConfArgs = {
  budgetGroupId: Scalars['String'];
};


export type QueryUiPWeightAdjConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiSAsyncExportStatusArgs = {
  operationId: Scalars['String'];
};


export type QueryUiSAsyncExportStatusesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiSAsyncImportStatusArgs = {
  operationId: Scalars['String'];
};


export type QueryUiSAsyncImportStatusesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiSConstArgs = {
  key: Scalars['String'];
};


export type QueryUiSConstsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiSImportLockArgs = {
  tableId: Scalars['String'];
};


export type QueryUiSImportLocksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryUiSUserArgs = {
  loginId: Scalars['String'];
};


export type QueryUiSUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiMItemApplyLevelArgs = {
  itemApplyLevel: Scalars['String'];
  itemApplyScope: Scalars['String'];
};


export type QueryVUiMItemApplyLevelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiMStoreApplyLevelArgs = {
  storeApplyLevel: Scalars['String'];
  storeApplyScope: Scalars['String'];
};


export type QueryVUiMStoreApplyLevel_AreaArgs = {
  areaCd: Scalars['String'];
  startDate: Scalars['Date'];
  storeApplyLevel: Scalars['String'];
  storeApplyScope: Scalars['String'];
};


export type QueryVUiMStoreApplyLevel_AreasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiMStoreApplyLevel_StoreArgs = {
  startDate: Scalars['Date'];
  storeApplyLevel: Scalars['String'];
  storeApplyScope: Scalars['String'];
  storeCd: Scalars['String'];
};


export type QueryVUiMStoreApplyLevel_StoresArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiMStoreApplyLevelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiMStoreItemApplyLevelArgs = {
  itemApplyLevel: Scalars['String'];
  itemApplyScope: Scalars['String'];
  storeApplyLevel: Scalars['String'];
  storeApplyScope: Scalars['String'];
};


export type QueryVUiMStoreItemApplyLevelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPAdjustedDowIndexArgs = {
  budgetGroupId: Scalars['String'];
  startDateNvl: Scalars['Date'];
  storeCd: Scalars['String'];
};


export type QueryVUiPAdjustedDowIndicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPAreaArgs = {
  areaCd: Scalars['String'];
};


export type QueryVUiPAreasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPAreastoreArgs = {
  areaCd: Scalars['String'];
  startDate: Scalars['Date'];
  storeCd: Scalars['String'];
};


export type QueryVUiPAreastoresArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPAutoorderStopArgs = {
  itemApplyLevel: Scalars['String'];
  itemApplyScope: Scalars['String'];
  startDate: Scalars['Date'];
  storeApplyLevel: Scalars['String'];
  storeApplyScope: Scalars['String'];
};


export type QueryVUiPAutoorderStopsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPBaseConfArgs = {
  itemApplyLevel: Scalars['String'];
  itemApplyScope: Scalars['String'];
  startDate: Scalars['Date'];
  storeApplyLevel: Scalars['String'];
  storeApplyScope: Scalars['String'];
};


export type QueryVUiPBaseConf_AllStoreConfigArgs = {
  itemApplyLevel: Scalars['String'];
  itemApplyScope: Scalars['String'];
  startDate: Scalars['Date'];
  storeApplyLevel: Scalars['String'];
  storeApplyScope: Scalars['String'];
};


export type QueryVUiPBaseConf_AllStoreConfigsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPBaseConf_EachStoreConfigArgs = {
  itemApplyLevel: Scalars['String'];
  itemApplyScope: Scalars['String'];
  startDate: Scalars['Date'];
  storeApplyLevel: Scalars['String'];
  storeApplyScope: Scalars['String'];
};


export type QueryVUiPBaseConf_EachStoreConfigsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPBaseConf_TranRefArgs = {
  itemApplyLevel: Scalars['String'];
  itemApplyScope: Scalars['String'];
  startDate: Scalars['Date'];
  storeApplyLevel: Scalars['String'];
  storeApplyScope: Scalars['String'];
};


export type QueryVUiPBaseConf_TranRefsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPBaseConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPBudgetGroupArgs = {
  budgetGroupId: Scalars['String'];
};


export type QueryVUiPBudgetGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPCausalNameArgs = {
  causalCd: Scalars['String'];
};


export type QueryVUiPCausalNamesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPCausalWeightConfArgs = {
  adjustType: Scalars['Int'];
  causalCd: Scalars['String'];
  causalType: Scalars['String'];
  itemApplyLevel: Scalars['String'];
  itemApplyScope: Scalars['String'];
  startDate: Scalars['Date'];
  storeApplyLevel: Scalars['String'];
  storeApplyScope: Scalars['String'];
};


export type QueryVUiPCausalWeightConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPDspInfoArgs = {
  ctgCode: Scalars['String'];
  ptnCode: Scalars['String'];
  shelfupNo: Scalars['String'];
  standNo: Scalars['String'];
  storeCd: Scalars['String'];
};


export type QueryVUiPDspInfosArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPItemConfArgs = {
  itemCd: Scalars['String'];
};


export type QueryVUiPItemConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPItemFeatureArgs = {
  itemApplyLevel: Scalars['String'];
  itemApplyScope: Scalars['String'];
};


export type QueryVUiPItemFeaturesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPItemOrderNumAdjArgs = {
  itemCd: Scalars['String'];
  startDateNvl: Scalars['Date'];
};


export type QueryVUiPItemOrderNumAdjsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPLowRankLogicConfArgs = {
  minWeeklyOrderNum: Scalars['Int'];
  storeRank: Scalars['Int'];
  storeinDowPatternId: Scalars['String'];
};


export type QueryVUiPLowRankLogicConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPNewStoreBulkOrderArgs = {
  category2Cd: Scalars['String'];
  category3Cd: Scalars['String'];
  itemApplyLevel: Scalars['String'];
  itemApplyScope: Scalars['String'];
};


export type QueryVUiPNewStoreBulkOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPOrderPatternSettingSiiArgs = {
  category1: Scalars['String'];
  startDate: Scalars['Date'];
  storeApplyLevel: Scalars['String'];
  storeApplyScope: Scalars['String'];
  vendorCd: Scalars['String'];
};


export type QueryVUiPOrderPatternSettingSiisArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPStoreRankArgs = {
  storeCd: Scalars['String'];
};


export type QueryVUiPStoreRanksArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPStoreinPatternArgs = {
  storeinDowPatternId: Scalars['String'];
};


export type QueryVUiPStoreinPatternsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPTranRefItemArgs = {
  referrerItemCd: Scalars['String'];
};


export type QueryVUiPTranRefItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPTranRefStoreArgs = {
  category: Scalars['String'];
  refLevel: Scalars['String'];
  referrerStoreCd: Scalars['String'];
};


export type QueryVUiPTranRefStoresArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPUserArgs = {
  loginId: Scalars['String'];
};


export type QueryVUiPUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPVarietyGroupArgs = {
  varietyGroupId: Scalars['String'];
};


export type QueryVUiPVarietyGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPWeeklyDailyBudgetConfArgs = {
  budgetGroupId: Scalars['String'];
  storeCd: Scalars['String'];
};


export type QueryVUiPWeeklyDailyBudgetConf_DowIndexArgs = {
  budgetGroupId: Scalars['String'];
  storeCd: Scalars['String'];
};


export type QueryVUiPWeeklyDailyBudgetConf_DowIndicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPWeeklyDailyBudgetConf_WeatherArgs = {
  storeCd: Scalars['String'];
};


export type QueryVUiPWeeklyDailyBudgetConf_WeathersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPWeeklyDailyBudgetConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVUiPWeightAdjConfArgs = {
  budgetGroupId: Scalars['String'];
};


export type QueryVUiPWeightAdjConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};


export type QueryVendorArgs = {
  vendorCd: Scalars['String'];
};


export type QueryVendorsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>>>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
  whereEx?: InputMaybe<WhereEx>;
};

/** An enumeration. */
export enum ResourceType {
  UiPAdjustedDowIndex = 'UI_P_ADJUSTED_DOW_INDEX',
  UiPArea = 'UI_P_AREA',
  UiPAreastore = 'UI_P_AREASTORE',
  UiPAutoorderStop = 'UI_P_AUTOORDER_STOP',
  UiPBaseConf = 'UI_P_BASE_CONF',
  UiPCausalName = 'UI_P_CAUSAL_NAME',
  UiPCausalWeightConf = 'UI_P_CAUSAL_WEIGHT_CONF',
  UiPDspInfo = 'UI_P_DSP_INFO',
  UiPItemConf = 'UI_P_ITEM_CONF',
  UiPItemFeature = 'UI_P_ITEM_FEATURE',
  UiPItemOrderNumAdj = 'UI_P_ITEM_ORDER_NUM_ADJ',
  UiPLowRankLogicConf = 'UI_P_LOW_RANK_LOGIC_CONF',
  UiPNewStoreBulkOrder = 'UI_P_NEW_STORE_BULK_ORDER',
  UiPNewStoreBulkOrderArrival = 'UI_P_NEW_STORE_BULK_ORDER_ARRIVAL',
  UiPNewStoreBulkOrderSheet = 'UI_P_NEW_STORE_BULK_ORDER_SHEET',
  UiPOrderPatternSettingSii = 'UI_P_ORDER_PATTERN_SETTING_SII',
  UiPTranRefItem = 'UI_P_TRAN_REF_ITEM',
  UiPTranRefStore = 'UI_P_TRAN_REF_STORE',
  UiPUser = 'UI_P_USER',
  UiPWeeklyDailyBudgetConf = 'UI_P_WEEKLY_DAILY_BUDGET_CONF',
  UiPWeightAdjConf = 'UI_P_WEIGHT_ADJ_CONF'
}

/**
 * インポート処理開始
 *
 * インポート処理フロー
 *   1. ファイルアップロード用URLの生成 ([mutation] createUploadUrl)
 *   2. インポート処理開始 ([mutation] runSimpleImport)
 *   3. インポート状況確認 (importStatus)
 *
 * TODO: backup import file (maybe overwrite import file)
 */
export type RunSimpleImport = {
  __typename?: 'RunSimpleImport';
  operationId: Scalars['UUID'];
};

export type RunSimpleImportInput = {
  encodingType: AsyncStatusEncodingType;
  filepathImport: Scalars['String'];
  formatType: AsyncStatusFormatType;
  optionXlsx?: InputMaybe<RunSimpleImportOptionXlsx>;
  resourceType: ResourceType;
};

export type RunSimpleImportOptionXlsx = {
  sheetName?: InputMaybe<Scalars['String']>;
  skipRows?: InputMaybe<Scalars['Int']>;
};

/** ファイルアップロード用URL取得 */
export type S3PresignedUrlForUpload = {
  __typename?: 'S3PresignedUrlForUpload';
  /** ファイルアップロード用URL */
  url: Scalars['String'];
};

export type SkuDiscard = {
  __typename?: 'SkuDiscard';
  /** 返品廃棄日 / 返品廃棄日 */
  discardDate: Scalars['Date'];
  /** 返品廃棄数量 / 返品廃棄数量 */
  discardNum: Scalars['Int'];
  /** 返品廃棄商品コード / 在庫管理商品コード */
  itemCd: Scalars['String'];
  /** 返品廃棄店舗コード / 返品廃棄店舗コード */
  storeCd: Scalars['String'];
  /** 処理対象日 / 更新時の処理対象日 / バッチ実行時に指定した処理対象日 */
  targetDate: Scalars['Date'];
  /** 処理対象日_UI / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻 / 取り込み時刻 / 障害追跡用 */
  updTime: Scalars['DateTime'];
  /** 更新時刻_UI / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type SkuDiscardConnection = {
  __typename?: 'SkuDiscardConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SkuDiscardEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `SkuDiscard` and its cursor. */
export type SkuDiscardEdge = {
  __typename?: 'SkuDiscardEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SkuDiscard>;
};

export type SkuSale = {
  __typename?: 'SkuSale';
  /** 在庫管理商品コード / 在庫管理商品コード */
  itemCd: Scalars['String'];
  /** 最終販売時刻 / 最終販売時刻　hh:mm形式 */
  lastSaleTime?: Maybe<Scalars['String']>;
  /** 見切り販売数量 / 販売数のうち見切りで販売した数量 */
  reducedSaleNum: Scalars['Int'];
  /** 販売金額 / 販売金額。税抜き税込み不問。 / 販売要因追跡用 */
  saleAmount?: Maybe<Scalars['Int']>;
  /** 販売日 / 販売日 */
  saleDate: Scalars['Date'];
  /** 販売数量 / 販売数量 / 客注は含まない */
  saleNum: Scalars['Int'];
  /** 販売数量２ / 見切り、客注・大量販売除く販売数量 / 平均販売数計算用 */
  saleNum2: Scalars['Int'];
  /** 店舗コード / 発注店舗コード */
  storeCd: Scalars['String'];
  /** 処理対象日 / 更新時の処理対象日 / バッチ実行時に指定した処理対象日 */
  targetDate: Scalars['Date'];
  /** 処理対象日_UI / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻 / 更新時刻 / 障害追跡用 */
  updTime: Scalars['DateTime'];
  /** 更新時刻_UI / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type SkuSaleConnection = {
  __typename?: 'SkuSaleConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<SkuSaleEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `SkuSale` and its cursor. */
export type SkuSaleEdge = {
  __typename?: 'SkuSaleEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<SkuSale>;
};

/** システム設定情報 */
export type SystemSetting = {
  __typename?: 'SystemSetting';
  /** メンテナンス時表示文言１ */
  displayMaintenanceWord1?: Maybe<Scalars['String']>;
  /** メンテナンス時表示文言２ */
  displayMaintenanceWord2?: Maybe<Scalars['String']>;
  /** 入出力履歴表示日数 */
  operationDisplayHistDays?: Maybe<Scalars['Int']>;
  /** システム日付 */
  systemDate?: Maybe<Scalars['Date']>;
};

/** システム状態 */
export type SystemStatus = {
  __typename?: 'SystemStatus';
  /** UI以外のサービスで何らかの障害が発生している（画面は利用可能） */
  isExtServiceWarn?: Maybe<Scalars['Boolean']>;
  /** マスタのインポートで警告が発生しているかどうか */
  isImportWarn?: Maybe<Scalars['Boolean']>;
  /** システムがメンテナンス状態にあるかどうか */
  isMaintenance?: Maybe<Scalars['Boolean']>;
};

/** An enumeration. */
export enum TemplateFile {
  UiPAdjustedDowIndex_01 = 'UI_P_ADJUSTED_DOW_INDEX__01',
  UiPAreastore_01 = 'UI_P_AREASTORE__01',
  UiPArea_01 = 'UI_P_AREA__01',
  UiPAutoorderStop_01 = 'UI_P_AUTOORDER_STOP__01',
  UiPBaseConf_01 = 'UI_P_BASE_CONF__01',
  UiPCausalName_01 = 'UI_P_CAUSAL_NAME__01',
  UiPCausalWeightConf_01 = 'UI_P_CAUSAL_WEIGHT_CONF__01',
  UiPDspInfo_01 = 'UI_P_DSP_INFO__01',
  UiPItemConf_01 = 'UI_P_ITEM_CONF__01',
  UiPItemFeature_01 = 'UI_P_ITEM_FEATURE__01',
  UiPItemOrderNumAdj_01 = 'UI_P_ITEM_ORDER_NUM_ADJ__01',
  UiPLowRankLogicConf_01 = 'UI_P_LOW_RANK_LOGIC_CONF__01',
  UiPNewStoreBulkOrder_01 = 'UI_P_NEW_STORE_BULK_ORDER__01',
  UiPNewStoreBulkOrderArrival_01 = 'UI_P_NEW_STORE_BULK_ORDER_ARRIVAL__01',
  UiPNewStoreBulkOrderSheet_01 = 'UI_P_NEW_STORE_BULK_ORDER_SHEET__01',
  UiPOrderPatternSettingSii_01 = 'UI_P_ORDER_PATTERN_SETTING_SII__01',
  UiPTranRefItem_01 = 'UI_P_TRAN_REF_ITEM__01',
  UiPTranRefStore_01 = 'UI_P_TRAN_REF_STORE__01',
  UiPUser_01 = 'UI_P_USER__01',
  UiPWeeklyDailyBudgetConf_01 = 'UI_P_WEEKLY_DAILY_BUDGET_CONF__01',
  UiPWeightAdjConf_01 = 'UI_P_WEIGHT_ADJ_CONF__01'
}

export type UiMCategory0 = {
  __typename?: 'UiMCategory0';
  /** カテゴリ0コード / 第2階層のカテゴリを一意に特定するコード / 第2分類コード */
  category0Cd: Scalars['String'];
  /** カテゴリ名 / カテゴリ名称 / 全角 */
  categoryName: Scalars['String'];
  /** 親カテゴリコード / 直属の親となるカテゴリコード / 第1分類コード */
  parentCategoryCd: Scalars['String'];
  saleItems?: Maybe<AllSaleitemMstConnection>;
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  uiMCategory1s?: Maybe<UiMCategory1Connection>;
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiPBudgetGroups?: Maybe<VUiPBudgetGroupConnection>;
};


export type UiMCategory0SaleItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type UiMCategory0UiMCategory1sArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type UiMCategory0VUiPBudgetGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UiMCategory0Connection = {
  __typename?: 'UiMCategory0Connection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMCategory0Edge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMCategory0` and its cursor. */
export type UiMCategory0Edge = {
  __typename?: 'UiMCategory0Edge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMCategory0>;
};

export type UiMCategory1 = {
  __typename?: 'UiMCategory1';
  /** カテゴリ1コード / 第3階層のカテゴリを一意に特定するコード / 第3分類コード、販売商品マスタ.カテゴリ1コード */
  category1Cd: Scalars['String'];
  /** カテゴリ名 / カテゴリ名称 / 全角 */
  categoryName: Scalars['String'];
  /** 親カテゴリコード / 直属の親となるカテゴリコード / 第2分類コード */
  parentCategoryCd: Scalars['String'];
  saleItems?: Maybe<AllSaleitemMstConnection>;
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  uiMCategory0?: Maybe<UiMCategory0>;
  uiMCategory2s?: Maybe<UiMCategory2Connection>;
  uiMCategory3s?: Maybe<UiMCategory3Connection>;
  uiMRefItemApplyLevel_Category1s?: Maybe<UiMRefItemApplyLevelCategory1Connection>;
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiPBudgetGroups?: Maybe<VUiPBudgetGroupConnection>;
  vUiPTranRefStores?: Maybe<VUiPTranRefStoreConnection>;
};


export type UiMCategory1SaleItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type UiMCategory1UiMCategory2sArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type UiMCategory1UiMCategory3sArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type UiMCategory1UiMRefItemApplyLevel_Category1sArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type UiMCategory1VUiPBudgetGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type UiMCategory1VUiPTranRefStoresArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UiMCategory1Connection = {
  __typename?: 'UiMCategory1Connection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMCategory1Edge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMCategory1` and its cursor. */
export type UiMCategory1Edge = {
  __typename?: 'UiMCategory1Edge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMCategory1>;
};

export type UiMCategory2 = {
  __typename?: 'UiMCategory2';
  /** カテゴリ1コード / 直属の親となるカテゴリコード / 第3分類コード、販売商品マスタ.カテゴリ1コード */
  category1Cd: Scalars['String'];
  /** カテゴリ2コード / 第4階層のカテゴリを一意に特定するコード / 第4分類コード、販売商品マスタ.カテゴリ2コード */
  category2Cd: Scalars['String'];
  /** カテゴリ名 / カテゴリ名称 / 全角 */
  categoryName: Scalars['String'];
  saleItems?: Maybe<AllSaleitemMstConnection>;
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  uiMCategory0?: Maybe<UiMCategory0>;
  uiMCategory1?: Maybe<UiMCategory1>;
  uiMCategory3s?: Maybe<UiMCategory3Connection>;
  uiMRefItemApplyLevel_Category2s?: Maybe<UiMRefItemApplyLevelCategory2Connection>;
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiPBudgetGroups?: Maybe<VUiPBudgetGroupConnection>;
};


export type UiMCategory2SaleItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type UiMCategory2UiMCategory3sArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type UiMCategory2UiMRefItemApplyLevel_Category2sArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type UiMCategory2VUiPBudgetGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UiMCategory2Connection = {
  __typename?: 'UiMCategory2Connection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMCategory2Edge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMCategory2` and its cursor. */
export type UiMCategory2Edge = {
  __typename?: 'UiMCategory2Edge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMCategory2>;
};

export type UiMCategory3 = {
  __typename?: 'UiMCategory3';
  /** カテゴリ1コード / 直属の親の親となるカテゴリコード / 第3分類コード、販売商品マスタ.カテゴリ1コード */
  category1Cd: Scalars['String'];
  /** カテゴリ2コード / 直属の親となるカテゴリコード / 第4分類コード、販売商品マスタ.カテゴリ2コード */
  category2Cd: Scalars['String'];
  /** カテゴリ3コード / 第5階層のカテゴリを一意に特定するコード / 第5分類コード、販売商品マスタ.カテゴリ3コード */
  category3Cd: Scalars['String'];
  /** カテゴリ名 / カテゴリ名称 / 全角 */
  categoryName: Scalars['String'];
  saleItems?: Maybe<AllSaleitemMstConnection>;
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  uiMCategory0?: Maybe<UiMCategory0>;
  uiMCategory1?: Maybe<UiMCategory1>;
  uiMCategory2?: Maybe<UiMCategory2>;
  uiMRefItemApplyLevel_Category3s?: Maybe<UiMRefItemApplyLevelCategory3Connection>;
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiPBudgetGroups?: Maybe<VUiPBudgetGroupConnection>;
};


export type UiMCategory3SaleItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type UiMCategory3UiMRefItemApplyLevel_Category3sArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type UiMCategory3VUiPBudgetGroupsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UiMCategory3Connection = {
  __typename?: 'UiMCategory3Connection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMCategory3Edge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMCategory3` and its cursor. */
export type UiMCategory3Edge = {
  __typename?: 'UiMCategory3Edge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMCategory3>;
};

export type UiMItemWeeklySale = {
  __typename?: 'UiMItemWeeklySale';
  /** 基準日 / 実績集計週の基準となる日 / md週開始曜日の月曜日の日付 rel_week_inxに依存 */
  baseDate: Scalars['Date'];
  /** 原単価 / 店舗商品マスタより非正規化 / 全店の最大値 */
  cost: Scalars['Float'];
  /** 廃棄金額 / 基準日の週の廃棄金額合計 */
  discardAmount: Scalars['Int'];
  /** 廃棄数 / 基準日の週の廃棄数合計 */
  discardNum: Scalars['Int'];
  /** 在庫管理商品コード */
  itemCd: Scalars['String'];
  /** 売単価 / 店舗商品マスタより非正規化 / 全店の最大値 */
  price: Scalars['Int'];
  /** 粗利金額 / 基準日の週の粗利金額合計 */
  profitAmount: Scalars['Float'];
  /** 相対週 / 現在を0とし、過去方向を正とする週単位インデックス / 一ヶ月あれば良いので、1,2,3,4を想定 4は先月の同じ週。 */
  relWeekInx: Scalars['Int'];
  /** 販売金額 / 基準日の週の販売金額合計 */
  saleAmount: Scalars['Int'];
  /** 販売数 / 基準日の週の販売数合計 */
  saleNum: Scalars['Int'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiMItemWeeklySaleConnection = {
  __typename?: 'UiMItemWeeklySaleConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMItemWeeklySaleEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type UiMItemWeeklySaleDenom = {
  __typename?: 'UiMItemWeeklySaleDenom';
  /** 原単価 / 店舗商品マスタより非正規化 / 全店の最大値 */
  cost: Scalars['Float'];
  /** 廃棄金額 1週間 */
  discardAmount1w: Scalars['Int'];
  /** 廃棄数 1週間 */
  discardNum1w: Scalars['Int'];
  /** 廃棄率 1週間 / 廃棄額(合計) 1週間 / 販売金額(合計) 1週間 */
  discardRate1w: Scalars['Float'];
  /** 在庫管理商品コード */
  itemCd: Scalars['String'];
  /** 売単価 / 店舗商品マスタより非正規化 / 全店の最大値 */
  price: Scalars['Int'];
  /** 粗利金額 1週間 */
  profitAmount1w: Scalars['Float'];
  /** 粗利金額 3週間 */
  profitAmount3w: Scalars['Float'];
  /** 粗利率 / (price - cost) / price */
  profitRate: Scalars['Float'];
  /** 粗利率 3週間 / 粗利金額(合計) 3週間 / 売上金額(合計) 3週間 */
  profitRate3w: Scalars['Float'];
  /** 販売金額 1週間 */
  saleAmount1w: Scalars['Int'];
  /** 販売金額 3週間 */
  saleAmount3w: Scalars['Int'];
  /** 販売数 1週間 */
  saleNum1w: Scalars['Int'];
  /** 販売数 1週前 / 販売数(合計) 1週間と同じ */
  saleNum1wBefore: Scalars['Int'];
  /** 販売数 2週前 / 2週前、1週間の合計販売数 */
  saleNum2wBefore: Scalars['Int'];
  /** 販売数 3週間 / 3週間の合計販売数 */
  saleNum3w: Scalars['Int'];
  /** 販売数 3週前 / 3週前、1週間の合計販売数 */
  saleNum3wBefore: Scalars['Int'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiMItemWeeklySaleDenomConnection = {
  __typename?: 'UiMItemWeeklySaleDenomConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMItemWeeklySaleDenomEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMItemWeeklySaleDenom` and its cursor. */
export type UiMItemWeeklySaleDenomEdge = {
  __typename?: 'UiMItemWeeklySaleDenomEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMItemWeeklySaleDenom>;
};

/** A Relay edge containing a `UiMItemWeeklySale` and its cursor. */
export type UiMItemWeeklySaleEdge = {
  __typename?: 'UiMItemWeeklySaleEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMItemWeeklySale>;
};

export type UiMMaker = {
  __typename?: 'UiMMaker';
  /** メーカーコード */
  makerCd: Scalars['String'];
  /** メーカー名 */
  makerName?: Maybe<Scalars['String']>;
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiMMakerConnection = {
  __typename?: 'UiMMakerConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMMakerEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMMaker` and its cursor. */
export type UiMMakerEdge = {
  __typename?: 'UiMMakerEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMMaker>;
};

export type UiMRefItemApplyLevelCategory1 = {
  __typename?: 'UiMRefItemApplyLevelCategory1';
  /** カテゴリ1コード / 0','0'の場合でcategory1_cdが違う場合があるので、このカラムもpk */
  category1Cd: Scalars['String'];
  /** 商品適用レベル / 0:all,1:genre,2:item */
  itemApplyLevel: Scalars['String'];
  /** 商品適用範囲 / 0:all,適用ジャンルor適用商品コード / 2の場合、sale_item_cd */
  itemApplyScope: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  uiMCategory1?: Maybe<UiMCategory1>;
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiMItemApplyLevel?: Maybe<VUiMItemApplyLevel>;
};

export type UiMRefItemApplyLevelCategory1Connection = {
  __typename?: 'UiMRefItemApplyLevelCategory1Connection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMRefItemApplyLevelCategory1Edge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMRefItemApplyLevelCategory1` and its cursor. */
export type UiMRefItemApplyLevelCategory1Edge = {
  __typename?: 'UiMRefItemApplyLevelCategory1Edge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMRefItemApplyLevelCategory1>;
};

export type UiMRefItemApplyLevelCategory2 = {
  __typename?: 'UiMRefItemApplyLevelCategory2';
  /** カテゴリ1コード / 0','0'の場合でcategory1_cdが違う場合があるので、このカラムもpk */
  category1Cd: Scalars['String'];
  /** カテゴリ2コード / 0','0'の場合でcategory1_cdが違う場合があるので、このカラムもpk */
  category2Cd: Scalars['String'];
  /** 商品適用レベル / 0:all,1:genre,2:item */
  itemApplyLevel: Scalars['String'];
  /** 商品適用範囲 / 0:all,適用ジャンルor適用商品コード / 2の場合、sale_item_cd */
  itemApplyScope: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  uiMCategory2?: Maybe<UiMCategory2>;
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiMItemApplyLevel?: Maybe<VUiMItemApplyLevel>;
};

export type UiMRefItemApplyLevelCategory2Connection = {
  __typename?: 'UiMRefItemApplyLevelCategory2Connection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMRefItemApplyLevelCategory2Edge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMRefItemApplyLevelCategory2` and its cursor. */
export type UiMRefItemApplyLevelCategory2Edge = {
  __typename?: 'UiMRefItemApplyLevelCategory2Edge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMRefItemApplyLevelCategory2>;
};

export type UiMRefItemApplyLevelCategory3 = {
  __typename?: 'UiMRefItemApplyLevelCategory3';
  /** カテゴリ1コード / 0','0'の場合でcategory1_cdが違う場合があるので、このカラムもpk */
  category1Cd: Scalars['String'];
  /** カテゴリ2コード / 0','0'の場合でcategory1_cdが違う場合があるので、このカラムもpk */
  category2Cd: Scalars['String'];
  /** カテゴリ3コード / 0','0'の場合でcategory1_cdが違う場合があるので、このカラムもpk */
  category3Cd: Scalars['String'];
  /** 商品適用レベル / 0:all,1:genre,2:item */
  itemApplyLevel: Scalars['String'];
  /** 商品適用範囲 / 0:all,適用ジャンルor適用商品コード / 2の場合、sale_item_cd */
  itemApplyScope: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  uiMCategory3?: Maybe<UiMCategory3>;
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiMItemApplyLevel?: Maybe<VUiMItemApplyLevel>;
};

export type UiMRefItemApplyLevelCategory3Connection = {
  __typename?: 'UiMRefItemApplyLevelCategory3Connection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMRefItemApplyLevelCategory3Edge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMRefItemApplyLevelCategory3` and its cursor. */
export type UiMRefItemApplyLevelCategory3Edge = {
  __typename?: 'UiMRefItemApplyLevelCategory3Edge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMRefItemApplyLevelCategory3>;
};

export type UiMRefItemApplyLevelSaleitem = {
  __typename?: 'UiMRefItemApplyLevelSaleitem';
  /** 商品適用レベル / 0:all,1:genre,2:item */
  itemApplyLevel: Scalars['String'];
  /** 商品適用範囲 / 0:all,適用ジャンルor適用商品コード / 2の場合、sale_item_cd */
  itemApplyScope: Scalars['String'];
  saleItem?: Maybe<AllSaleitemMst>;
  /** 販売商品コード / 0','0'の場合でcategory1_cdが違う場合があるので、このカラムもpk */
  saleItemCd: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiMItemApplyLevel?: Maybe<VUiMItemApplyLevel>;
};

export type UiMRefItemApplyLevelSaleitemConnection = {
  __typename?: 'UiMRefItemApplyLevelSaleitemConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMRefItemApplyLevelSaleitemEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMRefItemApplyLevelSaleitem` and its cursor. */
export type UiMRefItemApplyLevelSaleitemEdge = {
  __typename?: 'UiMRefItemApplyLevelSaleitemEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMRefItemApplyLevelSaleitem>;
};

export type UiMRefStoreitemLevelShelfPattern = {
  __typename?: 'UiMRefStoreitemLevelShelfPattern';
  /** 列 / 優先度最大のレコードの値 */
  columnNo: Scalars['String'];
  /** 棚割適用終了日 / 元テーブル値なしは無期限（29991231）が入っている */
  endDate: Scalars['Date'];
  /** フェイス数 / 優先度最大のレコードの値 */
  faceNum: Scalars['Int'];
  /** フェイス数_合算 / 合算値 */
  faceNumSum: Scalars['Int'];
  /** ゴンドラ番号 / 優先度最大のレコードの値 */
  gondolaNo?: Maybe<Scalars['String']>;
  /** 商品適用レベル / 0:all,1:category,2:item */
  itemApplyLevel: Scalars['String'];
  /** 商品適用範囲 / 0:all,適用ジャンルor適用商品コード */
  itemApplyScope: Scalars['String'];
  /** 商品状態 / 0:未来,1:棚入り,2:継続,3:段変更,4:フェイス変更,5:棚落ち,9:過去 */
  itemStatus: Scalars['String'];
  itemStatusName?: Maybe<Scalars['String']>;
  /** パターン名称 / 優先度最大のレコードの値 */
  patternName?: Maybe<Scalars['String']>;
  /** パターン通番 / 優先度最大のレコードの値 */
  patternSeqNo: Scalars['String'];
  /** パターン台 / 優先度最大のレコードの値 */
  patternStandNo: Scalars['String'];
  /** 棚種別 / 優先度最大のレコードの値 */
  shelfMode?: Maybe<Scalars['String']>;
  /** 段 / 優先度最大のレコードの値 */
  shelfUpNo: Scalars['String'];
  /** 積上数 / 優先度最大のレコードの値 */
  stackUpNum: Scalars['Int'];
  /** 台通番 / 優先度最大のレコードの値 */
  standSeqNo: Scalars['String'];
  /** 棚割適用開始日 */
  startDate: Scalars['Date'];
  /** 店舗適用レベル / 0:all,1:area,2:store */
  storeApplyLevel: Scalars['String'];
  /** 店舗適用範囲 / 0:all,適用エリアコード or 適用店舗コード */
  storeApplyScope: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiPBaseConfs?: Maybe<VUiPBaseConfConnection>;
  /** 版番号 / パターン台棚位置毎の履歴番号（1オリジン） / 優先度最大のレコードの値 */
  versionNo: Scalars['Int'];
};


export type UiMRefStoreitemLevelShelfPatternVUiPBaseConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UiMRefStoreitemLevelShelfPatternConnection = {
  __typename?: 'UiMRefStoreitemLevelShelfPatternConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMRefStoreitemLevelShelfPatternEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMRefStoreitemLevelShelfPattern` and its cursor. */
export type UiMRefStoreitemLevelShelfPatternEdge = {
  __typename?: 'UiMRefStoreitemLevelShelfPatternEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMRefStoreitemLevelShelfPattern>;
};

export type UiMRefStoreitemSaleitem = {
  __typename?: 'UiMRefStoreitemSaleitem';
  /** 原単価 / 原単価 */
  cost: Scalars['Float'];
  /** タイムスタンプ / 連携データの更新時刻 / 障害追跡用 */
  dataTime: Scalars['String'];
  /** 棚置き指示フラグ / 棚置き支持（0:置かない、1：棚におく） */
  displayFlg: Scalars['String'];
  /** 取り扱いフラグ / 取り扱い要否（0:取り扱わない、1：取り扱う） / 陳列商品に登録されているものと発注対象商品に登録されているもの */
  handleFlg: Scalars['String'];
  /** 最少発注数 / 最少発注数 */
  moq: Scalars['Int'];
  /** 発注カテゴリ / 商品の発注カテゴリ / 発注スケジュールを特定する */
  orderCategory: Scalars['String'];
  /** 発注不可フラグ / 発注不可（0:発注不可でない、1:発注不可） / 発注ロック */
  orderLockFlg: Scalars['String'];
  /** 売単価 / 売単価 */
  price: Scalars['Int'];
  /** 発注単位入数 / 入り数 */
  quantity: Scalars['Int'];
  saleItem?: Maybe<AllSaleitemMst>;
  /** 販売商品コード / 販売商品コード（jan） */
  saleItemCd: Scalars['String'];
  /** 店舗コード / 店舗コード */
  storeCd: Scalars['String'];
  /** 仕入先コード / 仕入先コード */
  suplierCd?: Maybe<Scalars['String']>;
  /** 処理対象日 / 更新時の処理対象日 / バッチ実行時に指定した処理対象日 */
  targetDate: Scalars['Date'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻 / 取り込み時刻 / 障害追跡用 */
  updTime: Scalars['DateTime'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  /** 発注先コード / 発注先ード */
  vendorCd: Scalars['String'];
  /** 表示対象フラグ / 表示要否（0:非表示、1：表示） / 画面の表示対象 */
  visibleFlg: Scalars['String'];
};

export type UiMRefStoreitemSaleitemConnection = {
  __typename?: 'UiMRefStoreitemSaleitemConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMRefStoreitemSaleitemEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMRefStoreitemSaleitem` and its cursor. */
export type UiMRefStoreitemSaleitemEdge = {
  __typename?: 'UiMRefStoreitemSaleitemEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMRefStoreitemSaleitem>;
};

export type UiMRefStoreitemScopeSale = {
  __typename?: 'UiMRefStoreitemScopeSale';
  /** 平均販売実績 / 1店舗あたり販売実績 */
  averageSaleNum?: Maybe<Scalars['Float']>;
  /** 商品適用レベル / 0:all,1:category,2:item */
  itemApplyLevel: Scalars['String'];
  /** 商品適用範囲 / 0:all,適用ジャンルor適用商品コード */
  itemApplyScope: Scalars['String'];
  /** 販売数 1週前 / 店舗エリアの場合、合算 */
  saleNum1wBefore?: Maybe<Scalars['Int']>;
  /** 販売数 2週前 / 店舗エリアの場合、合算 */
  saleNum2wBefore?: Maybe<Scalars['Int']>;
  /** 販売数 3週前 / 店舗エリアの場合、合算 */
  saleNum3wBefore?: Maybe<Scalars['Int']>;
  /** 販売数 4週前 / 店舗エリアの場合、合算 */
  saleNum4wBefore?: Maybe<Scalars['Int']>;
  /** 店舗適用レベル / 0:all,1:area,2:store */
  storeApplyLevel: Scalars['String'];
  /** 店舗適用範囲 / 0:all,適用エリアコード or 適用店舗コード */
  storeApplyScope: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiPBaseConfs?: Maybe<VUiPBaseConfConnection>;
};


export type UiMRefStoreitemScopeSaleVUiPBaseConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type UiMRefStoreitemScopeSaleConnection = {
  __typename?: 'UiMRefStoreitemScopeSaleConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMRefStoreitemScopeSaleEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMRefStoreitemScopeSale` and its cursor. */
export type UiMRefStoreitemScopeSaleEdge = {
  __typename?: 'UiMRefStoreitemScopeSaleEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMRefStoreitemScopeSale>;
};

export type UiMRefStoreitemScopeStoreitem = {
  __typename?: 'UiMRefStoreitemScopeStoreitem';
  /** 商品適用レベル / 0:all,1:category,2:item */
  itemApplyLevel: Scalars['String'];
  /** 商品適用範囲 / 0:all,適用ジャンルor適用商品コード */
  itemApplyScope: Scalars['String'];
  /** 販売商品コード */
  saleItemCd: Scalars['String'];
  /** 店舗適用レベル / 0:all,1:area,2:store */
  storeApplyLevel: Scalars['String'];
  /** 店舗適用範囲 / 0:all,適用エリアコード or 適用店舗コード */
  storeApplyScope: Scalars['String'];
  /** 店舗コード */
  storeCd: Scalars['String'];
  /** 処理対象日_ui / バッチ実行時に指定した処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiMRefStoreitemScopeStoreitemConnection = {
  __typename?: 'UiMRefStoreitemScopeStoreitemConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMRefStoreitemScopeStoreitemEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMRefStoreitemScopeStoreitem` and its cursor. */
export type UiMRefStoreitemScopeStoreitemEdge = {
  __typename?: 'UiMRefStoreitemScopeStoreitemEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMRefStoreitemScopeStoreitem>;
};

export type UiMShelfPattern = {
  __typename?: 'UiMShelfPattern';
  /** 列 / 優先度最大のレコードの値 */
  columnNo: Scalars['String'];
  /** 棚割適用終了日 / 元テーブル値なしは無期限（29991231）が入っている */
  endDate: Scalars['Date'];
  /** フェイス数 / 優先度最大のレコードの値 */
  faceNum: Scalars['Int'];
  /** フェイス数_合算 / 合算値 */
  faceNumSum: Scalars['Int'];
  /** ゴンドラ番号 / 優先度最大のレコードの値 */
  gondolaNo?: Maybe<Scalars['String']>;
  /** 商品状態 / 0:未来,1:棚入り,2:継続,3:段変更,4:フェイス変更,5:棚落ち,9:過去 */
  itemStatus: Scalars['String'];
  /** パターン名称 / 優先度最大のレコードの値 */
  patternName?: Maybe<Scalars['String']>;
  /** パターン通番 / 優先度最大のレコードの値 */
  patternSeqNo: Scalars['String'];
  /** パターン台 / 優先度最大のレコードの値 */
  patternStandNo: Scalars['String'];
  saleItem?: Maybe<AllSaleitemMst>;
  /** 販売商品コード */
  saleItemCd: Scalars['String'];
  /** 棚種別 / 優先度最大のレコードの値 */
  shelfMode?: Maybe<Scalars['String']>;
  /** 棚パターンコード / ui_opt_陳列商品パターン２のカテゴリーコード _ パターンコード */
  shelfPatternCd: Scalars['String'];
  /** 段 / 優先度最大のレコードの値 */
  shelfUpNo: Scalars['String'];
  /** 積上数 / 優先度最大のレコードの値 */
  stackUpNum: Scalars['Int'];
  /** 台通番 / 優先度最大のレコードの値 */
  standSeqNo: Scalars['String'];
  /** 棚割適用開始日 */
  startDate: Scalars['Date'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiPArea?: Maybe<VUiPArea>;
  /**
   * a: ui_m_shelf_pattern, b: all_saleitem_mst<br><br>
   * UI_BUSINESS_DATE between a.start_date and a.end_date
   */
  validSaleItem?: Maybe<AllSaleitemMst>;
  /**
   * a: ui_m_shelf_pattern, b: v_ui_p_area<br><br>
   * UI_BUSINESS_DATE between a.start_date and a.end_date and<br>b.inactive_flg = '0'
   */
  validVUiPArea?: Maybe<VUiPArea>;
  /** 版番号 / パターン台棚位置毎の履歴番号（1オリジン） / 優先度最大のレコードの値 */
  versionNo: Scalars['Int'];
};

export type UiMShelfPatternConnection = {
  __typename?: 'UiMShelfPatternConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMShelfPatternEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMShelfPattern` and its cursor. */
export type UiMShelfPatternEdge = {
  __typename?: 'UiMShelfPatternEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMShelfPattern>;
};

export type UiMStoreLocation = {
  __typename?: 'UiMStoreLocation';
  /** 位置情報グループid / ui_m_店舗天気位置情報グループ.位置情報グループid */
  locationGroupId: Scalars['String'];
  /** 店舗コード */
  storeCd: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiMStoreLocationConnection = {
  __typename?: 'UiMStoreLocationConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMStoreLocationEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMStoreLocation` and its cursor. */
export type UiMStoreLocationEdge = {
  __typename?: 'UiMStoreLocationEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMStoreLocation>;
};

export type UiMStoreShelf = {
  __typename?: 'UiMStoreShelf';
  /** カテゴリーコード / {category_cd}_{pattern_cd}=エリアコード */
  categoryCd: Scalars['String'];
  /** 列 */
  columnNo: Scalars['String'];
  /** 棚割適用終了日 / 無期限は29991231として登録 */
  endDate: Scalars['Date'];
  /** フェイス数 */
  faceNum: Scalars['Int'];
  /** ゴンドラ番号 */
  gondolaNo?: Maybe<Scalars['String']>;
  /** パターンコード / {category_cd}_{pattern_cd}=エリアコード */
  patternCd: Scalars['String'];
  /** パターン名称 */
  patternName?: Maybe<Scalars['String']>;
  /** パターン通番 */
  patternSeqNo: Scalars['String'];
  /** パターン台 */
  patternStandNo: Scalars['String'];
  saleItem?: Maybe<AllSaleitemMst>;
  /** 販売商品コード */
  saleItemCd: Scalars['String'];
  /** 棚種別 */
  shelfMode?: Maybe<Scalars['String']>;
  /** 段 */
  shelfUpNo: Scalars['String'];
  /** 積上数 */
  stackUpNum: Scalars['Int'];
  /** 台通番 */
  standSeqNo: Scalars['String'];
  /** 棚割適用開始日 */
  startDate: Scalars['Date'];
  /** 店舗番号 */
  storeCd: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  /**
   * a: ui_m_store_shelf, b: all_saleitem_mst<br><br>
   * UI_BUSINESS_DATE between b.start_date and b.end_date
   */
  validSaleItem?: Maybe<AllSaleitemMst>;
  /** 版番号 / パターン台棚位置毎の履歴番号（1オリジン） / 保持データ内で毎回振り直し。ワーク用項目 */
  versionNo: Scalars['Int'];
};

export type UiMStoreShelfConnection = {
  __typename?: 'UiMStoreShelfConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMStoreShelfEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMStoreShelf` and its cursor. */
export type UiMStoreShelfEdge = {
  __typename?: 'UiMStoreShelfEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMStoreShelf>;
};

export type UiMStoreShelfItemStatus = {
  __typename?: 'UiMStoreShelfItemStatus';
  /** 基準日 / 商品状態判定の基準となるmd週の開始日 / 処理対象日が属するmd週開始曜日の月曜日の日付 */
  baseDate: Scalars['Date'];
  /** カテゴリーコード */
  categoryCd: Scalars['String'];
  /** 列 */
  columnNo: Scalars['String'];
  /** 商品状態 / 0:未来,1:棚入り,2:継続,3:段変更,4:フェイス変更,5:棚落ち,9:過去 */
  itemStatus: Scalars['String'];
  /** パターンコード */
  patternCd: Scalars['String'];
  /** パターン通番 */
  patternSeqNo: Scalars['String'];
  /** パターン台 */
  patternStandNo: Scalars['String'];
  /** 販売商品コード */
  saleItemCd: Scalars['String'];
  /** 段 */
  shelfUpNo: Scalars['String'];
  /** 台通番 */
  standSeqNo: Scalars['String'];
  /** 棚割適用開始日 */
  startDate: Scalars['Date'];
  /** 店舗番号 */
  storeCd: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiMStoreShelfItemStatusConnection = {
  __typename?: 'UiMStoreShelfItemStatusConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMStoreShelfItemStatusEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMStoreShelfItemStatus` and its cursor. */
export type UiMStoreShelfItemStatusEdge = {
  __typename?: 'UiMStoreShelfItemStatusEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMStoreShelfItemStatus>;
};

export type UiMStoreShelfPattern = {
  __typename?: 'UiMStoreShelfPattern';
  /** 列 / 優先度最大のレコードの値 */
  columnNo: Scalars['String'];
  /** 棚割適用終了日 / 元テーブル値なしは無期限（29991231）が入っている / 優先度最大のレコードの値 */
  endDate: Scalars['Date'];
  /** フェイス数 / 優先度最大のレコードの値 */
  faceNum: Scalars['Int'];
  /** ゴンドラ番号 / 優先度最大のレコードの値 */
  gondolaNo?: Maybe<Scalars['String']>;
  /** 商品状態 / 0:未来,1:棚入り,2:継続,3:段変更,4:フェイス変更,5:棚落ち,9:過去 */
  itemStatus: Scalars['String'];
  /** パターン名称 / 優先度最大のレコードの値 */
  patternName?: Maybe<Scalars['String']>;
  /** パターン通番 / 優先度最大のレコードの値 */
  patternSeqNo: Scalars['String'];
  /** パターン台 / 優先度最大のレコードの値 */
  patternStandNo: Scalars['String'];
  saleItem?: Maybe<AllSaleitemMst>;
  /** 販売商品コード */
  saleItemCd: Scalars['String'];
  /** 棚種別 / 優先度最大のレコードの値 */
  shelfMode?: Maybe<Scalars['String']>;
  /** 棚パターンコード / ui_opt_陳列商品パターン２のカテゴリーコード _ パターンコード */
  shelfPatternCd: Scalars['String'];
  /** 段 / 優先度最大のレコードの値 */
  shelfUpNo: Scalars['String'];
  /** 積上数 / 優先度最大のレコードの値 */
  stackUpNum: Scalars['Int'];
  /** 台通番 / 優先度最大のレコードの値 */
  standSeqNo: Scalars['String'];
  /** 棚割適用開始日 */
  startDate: Scalars['Date'];
  store?: Maybe<AllStoreMst>;
  /** 店舗コード */
  storeCd: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiPArea?: Maybe<VUiPArea>;
  /**
   * a: ui_m_store_shelf_pattern, b: all_saleitem_mst<br><br>
   * UI_BUSINESS_DATE between a.start_date and a.end_date
   */
  validSaleItem?: Maybe<AllSaleitemMst>;
  /**
   * a: ui_m_store_shelf_pattern, b: v_ui_p_area<br><br>
   * UI_BUSINESS_DATE between a.start_date and a.end_date and<br>b.inactive_flg = '0'
   */
  validVUiPArea?: Maybe<VUiPArea>;
  /** 版番号 / パターン台棚位置毎の履歴番号（1オリジン） / 優先度最大のレコードの値 */
  versionNo: Scalars['Int'];
};

export type UiMStoreShelfPatternConnection = {
  __typename?: 'UiMStoreShelfPatternConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMStoreShelfPatternEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMStoreShelfPattern` and its cursor. */
export type UiMStoreShelfPatternEdge = {
  __typename?: 'UiMStoreShelfPatternEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMStoreShelfPattern>;
};

export type UiMStoreWeather = {
  __typename?: 'UiMStoreWeather';
  /** 過去履歴区分 / 0:予報、1:実績 / openweathermap apiから過去分として取得したもの。 */
  historicalFlg: Scalars['String'];
  /** 降水確率 */
  probabilityOfPrecipitation?: Maybe<Scalars['Float']>;
  /** 雨量 / 営業時間内の予想降水量 / 1日の降水量を24分割して営業時間数を乗じる。 */
  rain?: Maybe<Scalars['Float']>;
  /** 全日雨量 / 1日の予想降水量 */
  rainByDay?: Maybe<Scalars['Float']>;
  /** 取得日時 / 天気サービスが天気を予測または実績を取得した日時。 / 天気サービスからopenweathermapapiを呼び出した日時。 */
  retrievedDatetime: Scalars['DateTime'];
  /** 販売日 */
  saleDate: Scalars['Date'];
  /** 店舗コード */
  storeCd: Scalars['String'];
  /** 処理対象日_ui / バッチ実行時に指定した処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 平均気温 */
  tempDay?: Maybe<Scalars['Float']>;
  /** 最高気温 */
  tempMax?: Maybe<Scalars['Float']>;
  /** 最低気温 */
  tempMin?: Maybe<Scalars['Float']>;
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  /** 天気コード */
  weatherCd: Scalars['String'];
  /** 風速 */
  windSpeed?: Maybe<Scalars['Float']>;
};

export type UiMStoreWeatherConnection = {
  __typename?: 'UiMStoreWeatherConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMStoreWeatherEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMStoreWeather` and its cursor. */
export type UiMStoreWeatherEdge = {
  __typename?: 'UiMStoreWeatherEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMStoreWeather>;
};

export type UiMStoreitemWeeklySale = {
  __typename?: 'UiMStoreitemWeeklySale';
  /** 基準日 / 実績集計週の基準となる日 / md週開始曜日の月曜日の日付 rel_week_inxに依存 */
  baseDate: Scalars['Date'];
  /** 原単価 / 店舗商品マスタより非正規化 / 処理対象日の原価 */
  cost: Scalars['Float'];
  /** 廃棄金額 / 基準日の週の廃棄金額合計 */
  discardAmount: Scalars['Int'];
  /** 廃棄数 / 基準日の週の廃棄数合計 */
  discardNum: Scalars['Int'];
  /** 在庫管理商品コード */
  itemCd: Scalars['String'];
  /** 売単価 / 店舗商品マスタより非正規化 / 処理対象日の売価（参考値） */
  price: Scalars['Int'];
  /** 粗利金額 / 基準日の週の粗利金額合計 */
  profitAmount: Scalars['Float'];
  /** 相対週 / 現在を0とし、過去方向を正とする週単位インデックス / 一ヶ月あれば良いので、1,2,3,4を想定 4は先月の同じ週。 */
  relWeekInx: Scalars['Int'];
  /** 販売金額 / 基準日の週の販売金額合計 */
  saleAmount: Scalars['Int'];
  /** 販売数 / 基準日の週の販売数合計 */
  saleNum: Scalars['Int'];
  /** 店舗コード */
  storeCd: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiMStoreitemWeeklySaleConnection = {
  __typename?: 'UiMStoreitemWeeklySaleConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMStoreitemWeeklySaleEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMStoreitemWeeklySale` and its cursor. */
export type UiMStoreitemWeeklySaleEdge = {
  __typename?: 'UiMStoreitemWeeklySaleEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMStoreitemWeeklySale>;
};

export type UiMWeather = {
  __typename?: 'UiMWeather';
  /** 処理対象日_ui / バッチ実行時に指定した処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  /** 天気コード */
  weatherCd: Scalars['String'];
  /** 天気アイコンid */
  weatherIconId?: Maybe<Scalars['String']>;
  /** 天気名 */
  weatherName: Scalars['String'];
};

export type UiMWeatherConnection = {
  __typename?: 'UiMWeatherConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiMWeatherEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiMWeather` and its cursor. */
export type UiMWeatherEdge = {
  __typename?: 'UiMWeatherEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiMWeather>;
};

export type UiPAdjustedDowIndex = {
  __typename?: 'UiPAdjustedDowIndex';
  /** 予算商品群id */
  budgetGroupId: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 終了日 */
  endDate?: Maybe<Scalars['Date']>;
  /** 販売曜日指数（金） */
  friIndex?: Maybe<Scalars['Float']>;
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 販売曜日指数（月） */
  monIndex?: Maybe<Scalars['Float']>;
  /** 販売曜日指数（土） */
  satIndex?: Maybe<Scalars['Float']>;
  /** 開始日 */
  startDate: Scalars['Date'];
  /** 店舗コード / 店舗コード */
  storeCd: Scalars['String'];
  /** 販売曜日指数（日） */
  sunIndex?: Maybe<Scalars['Float']>;
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 販売曜日指数（木） */
  thuIndex?: Maybe<Scalars['Float']>;
  /** 販売曜日指数（火） */
  tueIndex?: Maybe<Scalars['Float']>;
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  /** 販売曜日指数（水） */
  wedIndex?: Maybe<Scalars['Float']>;
};

export type UiPAdjustedDowIndexConnection = {
  __typename?: 'UiPAdjustedDowIndexConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPAdjustedDowIndexEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPAdjustedDowIndex` and its cursor. */
export type UiPAdjustedDowIndexEdge = {
  __typename?: 'UiPAdjustedDowIndexEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPAdjustedDowIndex>;
};

export type UiPArea = {
  __typename?: 'UiPArea';
  /** エリアコード */
  areaCd: Scalars['String'];
  /** エリア名 */
  areaName: Scalars['String'];
  /** エリア種別 / 0:運用エリア 1:基幹エリア 2:棚パターン / 1と2はバッチで毎日上書きされる */
  areaType: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiPAreaConnection = {
  __typename?: 'UiPAreaConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPAreaEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPArea` and its cursor. */
export type UiPAreaEdge = {
  __typename?: 'UiPAreaEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPArea>;
};

export type UiPAreastore = {
  __typename?: 'UiPAreastore';
  /** エリアコード / サイズを10→24に変更 */
  areaCd: Scalars['String'];
  /** エリア種別 / 0:運用エリア 1:基幹エリア 2:棚パターン */
  areaType: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 終了日 */
  endDate?: Maybe<Scalars['Date']>;
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 開始日 */
  startDate: Scalars['Date'];
  /** 店舗コード */
  storeCd: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiPAreastoreConnection = {
  __typename?: 'UiPAreastoreConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPAreastoreEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPAreastore` and its cursor. */
export type UiPAreastoreEdge = {
  __typename?: 'UiPAreastoreEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPAreastore>;
};

export type UiPAutoorderStop = {
  __typename?: 'UiPAutoorderStop';
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 終了日 / 適用期間終了日 / null時は終了期限なしと同義。2999/12/31扱い */
  endDate?: Maybe<Scalars['Date']>;
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 商品適用レベル / 0:all,1:category,2:item */
  itemApplyLevel: Scalars['String'];
  /** 商品適用範囲 / 0:all,適用ジャンルor適用商品コード */
  itemApplyScope: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 開始日 / 適用期間開始日 */
  startDate: Scalars['Date'];
  /** 店舗適用レベル / 0:all,1:area,2:store */
  storeApplyLevel: Scalars['String'];
  /** 店舗適用範囲 / 0:all,適用エリアコード or 適用店舗コード */
  storeApplyScope: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiPAutoorderStopConnection = {
  __typename?: 'UiPAutoorderStopConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPAutoorderStopEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPAutoorderStop` and its cursor. */
export type UiPAutoorderStopEdge = {
  __typename?: 'UiPAutoorderStopEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPAutoorderStop>;
};

export type UiPBaseConf = {
  __typename?: 'UiPBaseConf';
  /** 基礎在庫 / ui_out_sml設定マスタ */
  basicInventory: Scalars['Int'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 終了日 / 適用終了日 / エリアの場合：棚割の有効日に連動 エリア以外の場合：未設定（無効にしない限り有効） */
  endDate?: Maybe<Scalars['Date']>;
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 商品適用レベル / 2:item 固定 / 実質固定値だが、将来用にキーとして保持 */
  itemApplyLevel: Scalars['String'];
  /** 商品適用範囲 / 適用商品コード */
  itemApplyScope: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 最長発注間隔日数 / ui_out_押し出し発注マスタ */
  limitOrderIntervalDays?: Maybe<Scalars['Int']>;
  /** 発注点上限 / 発注点計算で算出した値が上限超過する場合、 上限値に切り下げ / ui_out_sml設定マスタ */
  limitOrderPoint?: Maybe<Scalars['Int']>;
  /** 備考1 */
  note1?: Maybe<Scalars['String']>;
  /** 備考2 */
  note2?: Maybe<Scalars['String']>;
  /** 加算発注量（数量） / 任意数を必要在庫に加算 / ui_out_推奨調整マスタ */
  orderQuantityAdd?: Maybe<Scalars['Int']>;
  /** 送り込み数 / ui_out_押し出し発注マスタ */
  orderSendNum?: Maybe<Scalars['Int']>;
  /** 加算発注量（回後） / 何回後の納品日まで引き上げるか（指定納品日までの販売予測数をmaxに加算） / ui_out_推奨調整マスタ */
  orderTimesAdd?: Maybe<Scalars['Int']>;
  /** 強制1終了期間 / ui_out_sml設定マスタ */
  p1EndDate?: Maybe<Scalars['Date']>;
  /** 強制1最大(+) / ui_out_sml設定マスタ */
  p1MaxAdd?: Maybe<Scalars['Int']>;
  /** 強制1smlパターン / s1,s2,s3,m1,m2,l1,l2,l3,r1,r2,r3 / ui_out_sml設定マスタ */
  p1SmlPattern?: Maybe<Scalars['String']>;
  /** 強制1開始期間 / ui_out_sml設定マスタ */
  p1StartDate?: Maybe<Scalars['Date']>;
  /** 強制1週販(倍) / ui_out_sml設定マスタ */
  p1WeeklySalesRate?: Maybe<Scalars['Float']>;
  /** 強制2終了期間 / ui_out_sml設定マスタ */
  p2EndDate?: Maybe<Scalars['Date']>;
  /** 強制2最大(+) / ui_out_sml設定マスタ */
  p2MaxAdd?: Maybe<Scalars['Int']>;
  /** 強制2smlパターン / s1,s2,s3,m1,m2,l1,l2,l3,r1,r2,r3 / ui_out_sml設定マスタ */
  p2SmlPattern?: Maybe<Scalars['String']>;
  /** 強制2開始期間 / ui_out_sml設定マスタ */
  p2StartDate?: Maybe<Scalars['Date']>;
  /** 強制2週販(倍) / ui_out_sml設定マスタ */
  p2WeeklySalesRate?: Maybe<Scalars['Float']>;
  /** 発注上限日数 / 発注上限日数 / ui_out_推奨上限日数マスタ */
  recommendLimitDays?: Maybe<Scalars['Int']>;
  /** 開始日 / 適用開始日 / エリアの場合：棚割の有効日に連動 エリア以外の場合：投入日+1で設定（翌処理から即有効） */
  startDate: Scalars['Date'];
  /** 店舗適用レベル / 0:all,1:area,2:store */
  storeApplyLevel: Scalars['String'];
  /** 店舗適用範囲 / 0:all,適用エリアコード or 適用店舗コード */
  storeApplyScope: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiPBaseConfConnection = {
  __typename?: 'UiPBaseConfConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPBaseConfEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPBaseConf` and its cursor. */
export type UiPBaseConfEdge = {
  __typename?: 'UiPBaseConfEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPBaseConf>;
};

export type UiPBudgetGroup = {
  __typename?: 'UiPBudgetGroup';
  /** 予算商品群id */
  budgetGroupId: Scalars['String'];
  /** カテゴリ１ / 予算商品群の商品カテゴリ１ / 全カテゴリが対象の場合は'*' */
  category1: Scalars['String'];
  /** カテゴリ２ / 予算商品群の商品カテゴリ２ / 全カテゴリが対象の場合は'*' */
  category2: Scalars['String'];
  /** カテゴリ３ / 予算商品群の商品カテゴリ３ / 全カテゴリが対象の場合は'*' */
  category3: Scalars['String'];
  /** カテゴリ４ / 予算商品群の商品カテゴリ４ / 全カテゴリが対象の場合は'*' */
  category4: Scalars['String'];
  /** カテゴリ５ / 予算商品群の商品カテゴリ５ / 全カテゴリが対象の場合は'*' */
  category5: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiPBudgetGroupConnection = {
  __typename?: 'UiPBudgetGroupConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPBudgetGroupEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPBudgetGroup` and its cursor. */
export type UiPBudgetGroupEdge = {
  __typename?: 'UiPBudgetGroupEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPBudgetGroup>;
};

export type UiPCausalName = {
  __typename?: 'UiPCausalName';
  /** コーザルコード / 任意の一意となるコーザルコード */
  causalCd: Scalars['String'];
  /** コーザル名 / 任意のコーザル名称 / 店舗ランク、天気、対策店舗、etc */
  causalName: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiPCausalNameConnection = {
  __typename?: 'UiPCausalNameConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPCausalNameEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPCausalName` and its cursor. */
export type UiPCausalNameEdge = {
  __typename?: 'UiPCausalNameEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPCausalName>;
};

export type UiPCausalWeightConf = {
  __typename?: 'UiPCausalWeightConf';
  /** 補正タイプ / 0: 乗率, 1: 絶対値加算 */
  adjustType: Scalars['Int'];
  /** コーザルコード / コーザル名 / コーザルのラベルを指す（店舗ランク、とか対策店舗、とか天気とか） */
  causalCd: Scalars['String'];
  /** コーザルロジック / 動作ロジックパターンを指定 / 有効な値はコーザルロジック設定マスタのもの */
  causalType: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 終了日 / コーザルの適用終了日 */
  endDate?: Maybe<Scalars['Date']>;
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 商品適用レベル / 0:all,1:genre,2:item */
  itemApplyLevel: Scalars['String'];
  /** 商品適用範囲 / 0:all,適用ジャンルor適用商品コード */
  itemApplyScope: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** パラメータ1 / 補正乗率 / 補正数を指定 */
  rsvParam1: Scalars['Float'];
  /** パラメータ2 / 同上 */
  rsvParam2?: Maybe<Scalars['Float']>;
  /** パラメータ3 / 同上 */
  rsvParam3?: Maybe<Scalars['Float']>;
  /** パラメータ4 / 同上 */
  rsvParam4?: Maybe<Scalars['Float']>;
  /** パラメータ5 / 同上 */
  rsvParam5?: Maybe<Scalars['Float']>;
  /** パラメータ6 / 同上 */
  rsvParam6?: Maybe<Scalars['Float']>;
  /** パラメータ7 / 同上 */
  rsvParam7?: Maybe<Scalars['Float']>;
  /** パラメータ8 / 同上 */
  rsvParam8?: Maybe<Scalars['Float']>;
  /** パラメータ9 / 同上 */
  rsvParam9?: Maybe<Scalars['Float']>;
  /** パラメータ10 / 同上 */
  rsvParam10?: Maybe<Scalars['Float']>;
  /** パラメータ11 / 同上 */
  rsvParam11?: Maybe<Scalars['Float']>;
  /** パラメータ12 / 同上 */
  rsvParam12?: Maybe<Scalars['Float']>;
  /** パラメータ13 / 同上 */
  rsvParam13?: Maybe<Scalars['Float']>;
  /** パラメータ14 / 同上 */
  rsvParam14?: Maybe<Scalars['Float']>;
  /** パラメータ15 / 同上 */
  rsvParam15?: Maybe<Scalars['Float']>;
  /** パラメータ16 / 同上 */
  rsvParam16?: Maybe<Scalars['Float']>;
  /** パラメータ17 / 同上 */
  rsvParam17?: Maybe<Scalars['Float']>;
  /** パラメータ18 / 同上 */
  rsvParam18?: Maybe<Scalars['Float']>;
  /** パラメータ19 / 同上 */
  rsvParam19?: Maybe<Scalars['Float']>;
  /** パラメータ20 / 同上 */
  rsvParam20?: Maybe<Scalars['Float']>;
  /** パラメータ21 / 同上 */
  rsvParam21?: Maybe<Scalars['Float']>;
  /** パラメータ22 / 同上 */
  rsvParam22?: Maybe<Scalars['Float']>;
  /** パラメータ23 / 同上 */
  rsvParam23?: Maybe<Scalars['Float']>;
  /** パラメータ24 / 同上 */
  rsvParam24?: Maybe<Scalars['Float']>;
  /** パラメータ25 / 同上 */
  rsvParam25?: Maybe<Scalars['Float']>;
  /** パラメータ26 / 同上 */
  rsvParam26?: Maybe<Scalars['Float']>;
  /** パラメータ27 / 同上 */
  rsvParam27?: Maybe<Scalars['Float']>;
  /** パラメータ28 / 同上 */
  rsvParam28?: Maybe<Scalars['Float']>;
  /** パラメータ29 / 同上 */
  rsvParam29?: Maybe<Scalars['Float']>;
  /** パラメータ30 / 同上 */
  rsvParam30?: Maybe<Scalars['Float']>;
  /** パラメータ31 / 同上 */
  rsvParam31?: Maybe<Scalars['Float']>;
  /** 開始日 / コーザルの適用開始日 */
  startDate: Scalars['Date'];
  /** 店舗適用レベル / 0:all,1:area,2:store */
  storeApplyLevel: Scalars['String'];
  /** 店舗適用範囲 / 0:all,適用エリアor適用店舗コード / サイズを10→24に変更 */
  storeApplyScope: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiPCausalWeightConfConnection = {
  __typename?: 'UiPCausalWeightConfConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPCausalWeightConfEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPCausalWeightConf` and its cursor. */
export type UiPCausalWeightConfEdge = {
  __typename?: 'UiPCausalWeightConfEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPCausalWeightConf>;
};

export type UiPDspInfo = {
  __typename?: 'UiPDspInfo';
  /** 棚０確コード / ０確棚コードと同一 */
  certain0ShelfCd: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** カテゴリコード */
  ctgCode: Scalars['String'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** パターンコード */
  ptnCode: Scalars['String'];
  /** 段 */
  shelfupNo: Scalars['String'];
  /** 台 */
  standNo: Scalars['String'];
  /** 店舗コード */
  storeCd: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiPDspInfoConnection = {
  __typename?: 'UiPDspInfoConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPDspInfoEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPDspInfo` and its cursor. */
export type UiPDspInfoEdge = {
  __typename?: 'UiPDspInfoEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPDspInfo>;
};

export type UiPItemConf = {
  __typename?: 'UiPItemConf';
  /** 発注義務 / 1:発注必須、0：バラエティ入替え可 null: 0 / all_バラエティ商品マスタ */
  absoluteOrderFlg?: Maybe<Scalars['String']>;
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 在庫管理商品コード / 販売商品コード（jan） */
  itemCd: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 最少発注数 / all_単品設定マスタ */
  moq: Scalars['Int'];
  /** ランク１納品曜日指定 / all_単品設定マスタ */
  r1StoreinDowPatternId?: Maybe<Scalars['String']>;
  /** ランク２納品曜日指定 / all_単品設定マスタ */
  r2StoreinDowPatternId?: Maybe<Scalars['String']>;
  /** ランク３納品曜日指定 / all_単品設定マスタ */
  r3StoreinDowPatternId?: Maybe<Scalars['String']>;
  /** ランク４納品曜日指定 / all_単品設定マスタ */
  r4StoreinDowPatternId?: Maybe<Scalars['String']>;
  /** ランク５納品曜日指定 / all_単品設定マスタ */
  r5StoreinDowPatternId?: Maybe<Scalars['String']>;
  /** ランク６納品曜日指定 / all_単品設定マスタ */
  r6StoreinDowPatternId?: Maybe<Scalars['String']>;
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  /** バラエティグループid / all_バラエティ商品マスタ */
  varietyGroupId?: Maybe<Scalars['String']>;
  /** バラエティ優先順位 / １を最優先とする連番 / all_バラエティ商品マスタ、予備群入れ替えで更新 */
  varietyPriority?: Maybe<Scalars['Int']>;
};

export type UiPItemConfConnection = {
  __typename?: 'UiPItemConfConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPItemConfEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPItemConf` and its cursor. */
export type UiPItemConfEdge = {
  __typename?: 'UiPItemConfEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPItemConf>;
};

export type UiPItemFeature = {
  __typename?: 'UiPItemFeature';
  /** 自動発注方式 / 1:需要予測型、2:予算按分型 */
  autoorderType: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 商品適用レベル / 0:all,1:category,2:item / カテゴリ: 小分類（カテゴリ1） */
  itemApplyLevel: Scalars['String'];
  /** 商品適用範囲 / 0:all,適用ジャンルor適用商品コード */
  itemApplyScope: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiPItemFeatureConnection = {
  __typename?: 'UiPItemFeatureConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPItemFeatureEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPItemFeature` and its cursor. */
export type UiPItemFeatureEdge = {
  __typename?: 'UiPItemFeatureEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPItemFeature>;
};

export type UiPItemOrderNumAdj = {
  __typename?: 'UiPItemOrderNumAdj';
  /** 単品係数 / 値１で100%を表す */
  adjustRate?: Maybe<Scalars['Float']>;
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 終了日 */
  endDate?: Maybe<Scalars['Date']>;
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 在庫管理商品コード */
  itemCd: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 開始日 */
  startDate: Scalars['Date'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 全店発注数下限 */
  totalOrderNumLower?: Maybe<Scalars['Int']>;
  /** 全店発注数上限 */
  totalOrderNumUpper?: Maybe<Scalars['Int']>;
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  /** 週間最低発注数 */
  weeklyMinOrderNum?: Maybe<Scalars['Int']>;
};

export type UiPItemOrderNumAdjConnection = {
  __typename?: 'UiPItemOrderNumAdjConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPItemOrderNumAdjEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPItemOrderNumAdj` and its cursor. */
export type UiPItemOrderNumAdjEdge = {
  __typename?: 'UiPItemOrderNumAdjEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPItemOrderNumAdj>;
};

export type UiPLowRankLogicConf = {
  __typename?: 'UiPLowRankLogicConf';
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 日次最低発注数1_金 */
  minDailyOrderNum1Fri?: Maybe<Scalars['Int']>;
  /** 日次最低発注数1_月 */
  minDailyOrderNum1Mon?: Maybe<Scalars['Int']>;
  /** 日次最低発注数1_土 */
  minDailyOrderNum1Sat?: Maybe<Scalars['Int']>;
  /** 日次最低発注数1_日 */
  minDailyOrderNum1Sun?: Maybe<Scalars['Int']>;
  /** 日次最低発注数1_木 */
  minDailyOrderNum1Thu?: Maybe<Scalars['Int']>;
  /** 日次最低発注数1_火 */
  minDailyOrderNum1Tue?: Maybe<Scalars['Int']>;
  /** 日次最低発注数1_水 */
  minDailyOrderNum1Wed?: Maybe<Scalars['Int']>;
  /** 日次最低発注数2_金 */
  minDailyOrderNum2Fri?: Maybe<Scalars['Int']>;
  /** 日次最低発注数2_月 */
  minDailyOrderNum2Mon?: Maybe<Scalars['Int']>;
  /** 日次最低発注数2_土 */
  minDailyOrderNum2Sat?: Maybe<Scalars['Int']>;
  /** 日次最低発注数2_日 */
  minDailyOrderNum2Sun?: Maybe<Scalars['Int']>;
  /** 日次最低発注数2_木 */
  minDailyOrderNum2Thu?: Maybe<Scalars['Int']>;
  /** 日次最低発注数2_火 */
  minDailyOrderNum2Tue?: Maybe<Scalars['Int']>;
  /** 日次最低発注数2_水 */
  minDailyOrderNum2Wed?: Maybe<Scalars['Int']>;
  /** 週次最低発注数 / 1～8 */
  minWeeklyOrderNum: Scalars['Int'];
  /** 店ランク / 1~n */
  storeRank: Scalars['Int'];
  /** 納品曜日設定 */
  storeinDowPatternId: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiPLowRankLogicConfConnection = {
  __typename?: 'UiPLowRankLogicConfConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPLowRankLogicConfEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPLowRankLogicConf` and its cursor. */
export type UiPLowRankLogicConfEdge = {
  __typename?: 'UiPLowRankLogicConfEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPLowRankLogicConf>;
};

export type UiPOrderPatternSettingSii = {
  __typename?: 'UiPOrderPatternSettingSii';
  /** 商品カテゴリ1 / 商品の分類（カテゴリー1） / 商品分類用 企業１商品マスタ.第3分類コード */
  category1: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 終了日 */
  endDate?: Maybe<Scalars['Date']>;
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 発注可能フラグ＿金 / 1:可、0:不可 */
  orderFlgFri: Scalars['String'];
  /** 発注可能フラグ＿月 / 1:可、0:不可 */
  orderFlgMon: Scalars['String'];
  /** 発注可能フラグ＿土 / 1:可、0:不可 */
  orderFlgSat: Scalars['String'];
  /** 発注可能フラグ＿日 / 1:可、0:不可 */
  orderFlgSun: Scalars['String'];
  /** 発注可能フラグ＿木 / 1:可、0:不可 */
  orderFlgThu: Scalars['String'];
  /** 発注可能フラグ＿火 / 1:可、0:不可 */
  orderFlgTue: Scalars['String'];
  /** 発注可能フラグ＿水 / 1:可、0:不可 */
  orderFlgWed: Scalars['String'];
  /** 開始日 */
  startDate: Scalars['Date'];
  /** 店舗適用レベル / 0:all,1:area,2:store */
  storeApplyLevel: Scalars['String'];
  /** 店舗適用範囲 / 0:all,適用エリアコード or 適用店舗コード */
  storeApplyScope: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  /** 発注先コード / 発注先コード */
  vendorCd: Scalars['String'];
};

export type UiPOrderPatternSettingSiiConnection = {
  __typename?: 'UiPOrderPatternSettingSiiConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPOrderPatternSettingSiiEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPOrderPatternSettingSii` and its cursor. */
export type UiPOrderPatternSettingSiiEdge = {
  __typename?: 'UiPOrderPatternSettingSiiEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPOrderPatternSettingSii>;
};

export type UiPStoreRank = {
  __typename?: 'UiPStoreRank';
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 店舗コード */
  storeCd: Scalars['String'];
  /** 店ランク / 1~n */
  storeRank: Scalars['Int'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiPStoreRankConnection = {
  __typename?: 'UiPStoreRankConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPStoreRankEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPStoreRank` and its cursor. */
export type UiPStoreRankEdge = {
  __typename?: 'UiPStoreRankEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPStoreRank>;
};

export type UiPStoreinPattern = {
  __typename?: 'UiPStoreinPattern';
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 説明 / 偶数日、奇数日の説明 / 必要に応じて記載 */
  desctription: Scalars['String'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 納品曜日パターンid */
  storeinDowPatternId: Scalars['String'];
  /** 納品曜日パターン名 */
  storeinDowPatternName: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiPStoreinPatternConnection = {
  __typename?: 'UiPStoreinPatternConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPStoreinPatternEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPStoreinPattern` and its cursor. */
export type UiPStoreinPatternEdge = {
  __typename?: 'UiPStoreinPatternEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPStoreinPattern>;
};

export type UiPTranRefItem = {
  __typename?: 'UiPTranRefItem';
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 有効期限 / 設定が有効な期限を設定する */
  expireDate: Scalars['Date'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 参照元商品コード / 参照元商品コード */
  referrerItemCd: Scalars['String'];
  /** 参照先商品コード / 参照先商品コード */
  refferenceItemCd: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiPTranRefItemConnection = {
  __typename?: 'UiPTranRefItemConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPTranRefItemEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPTranRefItem` and its cursor. */
export type UiPTranRefItemEdge = {
  __typename?: 'UiPTranRefItemEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPTranRefItem>;
};

export type UiPTranRefStore = {
  __typename?: 'UiPTranRefStore';
  /** 参照範囲 / 0: all or カテゴリid / 販売商品.カテゴリ1 */
  category: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 有効期限 / 設定が有効な期限を設定する */
  expireDate: Scalars['Date'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 参照範囲レベル / 0: all, 1: カテゴリ */
  refLevel: Scalars['String'];
  /** 参照元店舗コード / 参照元店舗 */
  referrerStoreCd: Scalars['String'];
  /** 参照先店舗コード / 参照先店舗 */
  refferenceStoreCd: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiPTranRefStoreConnection = {
  __typename?: 'UiPTranRefStoreConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPTranRefStoreEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPTranRefStore` and its cursor. */
export type UiPTranRefStoreEdge = {
  __typename?: 'UiPTranRefStoreEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPTranRefStore>;
};

export type UiPUser = {
  __typename?: 'UiPUser';
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 表示名 */
  displayName: Scalars['String'];
  /** メールアドレス */
  email: Scalars['String'];
  /** 削除フラグ / 0:有効 1:削除 / 削除の場合、「ユーザー無効」 */
  inactiveFlg: Scalars['String'];
  /** システム管理者権限 / 0:権限なし 1:権限あり */
  isAdministrator: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** ログインid */
  loginId: Scalars['String'];
  /** パスワードリセット要求フラグ / 0: 要求なし、1:要求有り */
  passwordResetRequestFlg: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiPUserConnection = {
  __typename?: 'UiPUserConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPUserEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPUser` and its cursor. */
export type UiPUserEdge = {
  __typename?: 'UiPUserEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPUser>;
};

export type UiPVarietyGroup = {
  __typename?: 'UiPVarietyGroup';
  /** 予算商品群id */
  budgetGroupId: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 発注商品数 / このグループで何商品発注するか */
  orderItemNum: Scalars['Int'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  /** バラエティグループid */
  varietyGroupId: Scalars['String'];
  /** バラエティグループ名 / 値１で100%を表す */
  varietyGroupName: Scalars['String'];
  /** 週次入替え曜日 / 予備軍との入替え実施曜日 */
  weeklySwapDow: Scalars['Int'];
};

export type UiPVarietyGroupConnection = {
  __typename?: 'UiPVarietyGroupConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPVarietyGroupEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPVarietyGroup` and its cursor. */
export type UiPVarietyGroupEdge = {
  __typename?: 'UiPVarietyGroupEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPVarietyGroup>;
};

export type UiPWeeklyDailyBudgetConf = {
  __typename?: 'UiPWeeklyDailyBudgetConf';
  /** 予算商品群id */
  budgetGroupId: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 販売日 / 店別＊クラス別係数の初日 / 日次バッチで更新（テーブルで固定値） */
  saleDate: Scalars['Date'];
  /** 店舗コード */
  storeCd: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  /** 天気係数設定a */
  weatherWeightA: Scalars['Float'];
  /** 天気係数設定b */
  weatherWeightB: Scalars['Float'];
  /** 天気係数設定c */
  weatherWeightC: Scalars['Float'];
  /** 天気係数設定最大 */
  weatherWeightMax: Scalars['Float'];
  /** 週次発注金額 */
  weeklyBudget: Scalars['Int'];
  /** 店別_クラス別係数_1 / sale_dateの販売日の設定値 */
  weight1: Scalars['Float'];
  /** 店別_クラス別係数_2 / sale_date+1の販売日の設定値 */
  weight2: Scalars['Float'];
  /** 店別_クラス別係数_3 */
  weight3: Scalars['Float'];
  /** 店別_クラス別係数_4 */
  weight4: Scalars['Float'];
  /** 店別_クラス別係数_5 */
  weight5: Scalars['Float'];
  /** 店別_クラス別係数_6 */
  weight6: Scalars['Float'];
  /** 店別_クラス別係数_7 */
  weight7: Scalars['Float'];
};

export type UiPWeeklyDailyBudgetConfConnection = {
  __typename?: 'UiPWeeklyDailyBudgetConfConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPWeeklyDailyBudgetConfEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPWeeklyDailyBudgetConf` and its cursor. */
export type UiPWeeklyDailyBudgetConfEdge = {
  __typename?: 'UiPWeeklyDailyBudgetConfEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPWeeklyDailyBudgetConf>;
};

export type UiPWeightAdjConf = {
  __typename?: 'UiPWeightAdjConf';
  /** 予算商品群id */
  budgetGroupId: Scalars['String'];
  /** 消化率最大閾値 / all_予算商品群パラメータマスタ */
  consumptionMaxLimit?: Maybe<Scalars['Float']>;
  /** 消化率最大係数 / all_予算商品群パラメータマスタ */
  consumptionMaxParam?: Maybe<Scalars['Float']>;
  /** 消化率最小閾値 / all_予算商品群パラメータマスタ */
  consumptionMinLimit?: Maybe<Scalars['Float']>;
  /** 消化率最小係数 / all_予算商品群パラメータマスタ */
  consumptionMinParam?: Maybe<Scalars['Float']>;
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 日次入替え廃棄率 / 値１で100%を表す / all_バラエティグループマスタ、この数値を超えると入替え発生 */
  dailySwapDisposalRate?: Maybe<Scalars['Float']>;
  /** 廃棄率最大閾値 / all_予算商品群パラメータマスタ */
  discardRateMaxLimit?: Maybe<Scalars['Float']>;
  /** 廃棄率最大係数 / all_予算商品群パラメータマスタ */
  discardRateMaxParam?: Maybe<Scalars['Float']>;
  /** 廃棄率最小閾値 / all_予算商品群パラメータマスタ */
  discardRateMinLimit?: Maybe<Scalars['Float']>;
  /** 廃棄率最小係数 / all_予算商品群パラメータマスタ */
  discardRateMinParam?: Maybe<Scalars['Float']>;
  /** 廃棄高最大閾値 / all_予算商品群パラメータマスタ */
  discardSumMaxLimit?: Maybe<Scalars['Float']>;
  /** 廃棄高最大係数 / all_予算商品群パラメータマスタ */
  discardSumMaxParam?: Maybe<Scalars['Float']>;
  /** 廃棄高最小閾値 / all_予算商品群パラメータマスタ */
  discardSumMinLimit?: Maybe<Scalars['Float']>;
  /** 廃棄高最小係数 / all_予算商品群パラメータマスタ */
  discardSumMinParam?: Maybe<Scalars['Float']>;
  /** 棚0確係数 / all_予算商品群パラメータマスタ */
  dspzeroParam?: Maybe<Scalars['Float']>;
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 在庫計算フラグ / する：1、しない：0 / all_予算商品群パラメータマスタ、精肉のみを想定 */
  isStockCalc: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 単品構成比最大閾値 / 廃棄率による調整 / all_予算商品群パラメータマスタ */
  skuRatioMaxLimit?: Maybe<Scalars['Float']>;
  /** 単品構成比最大係数 / 廃棄率による調整 / all_予算商品群パラメータマスタ */
  skuRatioMaxParam?: Maybe<Scalars['Float']>;
  /** 単品構成比最小閾値 / 廃棄率による調整 / all_予算商品群パラメータマスタ */
  skuRatioMinLimit?: Maybe<Scalars['Float']>;
  /** 単品構成比最小係数 / 廃棄率による調整 / all_予算商品群パラメータマスタ */
  skuRatioMinParam?: Maybe<Scalars['Float']>;
  /** 単品0確係数_最大 / all_予算商品群パラメータマスタ */
  skuzeroMaxParam?: Maybe<Scalars['Float']>;
  /** 単品0確係数_最小 / all_予算商品群パラメータマスタ */
  skuzeroMinParam?: Maybe<Scalars['Float']>;
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 閉店時在庫目標 / 小数点一桁 / all_予算商品群パラメータマスタ、精肉のみを想定 */
  targetStock?: Maybe<Scalars['Float']>;
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  /** 週次入替え廃棄率 / 値１で100%を表す / all_バラエティグループマスタ、この数値を超えると入替え発生 */
  weeklySwapDisposalRate?: Maybe<Scalars['Float']>;
};

export type UiPWeightAdjConfConnection = {
  __typename?: 'UiPWeightAdjConfConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiPWeightAdjConfEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiPWeightAdjConf` and its cursor. */
export type UiPWeightAdjConfEdge = {
  __typename?: 'UiPWeightAdjConfEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiPWeightAdjConf>;
};

export type UiSAsyncExportStatus = {
  __typename?: 'UiSAsyncExportStatus';
  archive: ArchiveTypeDb;
  /** アーカイブフラグ / 0: 非アーカイブ 1: アーカイブ / レコードを裏に隠すフラグ */
  archiveFlg: Scalars['String'];
  /** 基準営業日 */
  businessDate: Scalars['Date'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  encodingType: AsyncStatusEncodingTypeDb;
  /** エンコーディング種別 */
  encodingTypeFlg: Scalars['String'];
  /** エクスポートファイル名 */
  filenameExport?: Maybe<Scalars['String']>;
  /** エクスポートファイルパス / フローの最終ファイルパス / 現在のところfilepath_transformed_modと同一 */
  filepathExported?: Maybe<Scalars['String']>;
  /** 抽出後ファイルパス */
  filepathExtracted?: Maybe<Scalars['String']>;
  /** 抽出後ファイルパス修正後 */
  filepathExtractedMod?: Maybe<Scalars['String']>;
  /** 変換後ファイルパス */
  filepathTransformed?: Maybe<Scalars['String']>;
  /** 変換後ファイルパス修正後 */
  filepathTransformedMod?: Maybe<Scalars['String']>;
  /** ファイルサイズ / filepath_exportedのファイルサイズ / 単位はbyte */
  filesizeAll?: Maybe<Scalars['Int']>;
  formatType: AsyncStatusFormatTypeDb;
  /** フォーマット種別 */
  formatTypeFlg: Scalars['String'];
  getFileurlExported?: Maybe<Scalars['String']>;
  getFileurlExtracted?: Maybe<Scalars['String']>;
  getFileurlTransformed?: Maybe<Scalars['String']>;
  /** graphqlクエリ / graphqlクエリ / 4096文字で溢れたのでtext型 */
  graphqlQuery: Scalars['String'];
  /** jmespathクエリ / jsonから表データを抽出するために使用するjmespathクエリ */
  jmespathQuery?: Maybe<Scalars['String']>;
  /** ラベル / フィルタ用ラベル / 自由記述 */
  label?: Maybe<Scalars['String']>;
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** メタ情報 / json文字列 / 4096文字で溢れたのでtext型 */
  metainfo: Scalars['String'];
  /** 操作id / uuid */
  operationId: Scalars['String'];
  /** 全件レコード件数 / filepath_exportedのレコード数 */
  recordCountAll?: Maybe<Scalars['Int']>;
  status: AsyncStatusTypeDb;
  /** 状況 */
  statusFlg: Scalars['String'];
  /** 処理対象日_ui / バッチ実行時に指定した処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiSAsyncExportStatusConnection = {
  __typename?: 'UiSAsyncExportStatusConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiSAsyncExportStatusEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiSAsyncExportStatus` and its cursor. */
export type UiSAsyncExportStatusEdge = {
  __typename?: 'UiSAsyncExportStatusEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiSAsyncExportStatus>;
};

export type UiSAsyncImportStatus = {
  __typename?: 'UiSAsyncImportStatus';
  archive: ArchiveTypeDb;
  /** アーカイブフラグ / 0: 非アーカイブ 1: アーカイブ / レコードを裏に隠すフラグ */
  archiveFlg: Scalars['String'];
  /** 基準営業日 / マスタを参照するための基準営業日 */
  businessDate: Scalars['Date'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  encodingType: AsyncStatusEncodingTypeDb;
  /** エンコーディング種別 / 1: utf_8, 2: cp932 */
  encodingTypeFlg: Scalars['String'];
  /** ロード用ファイルパス / validatorで適宜データ変更されたファイルパス / [未使用] 3.0を3、定数を実際の値に置換など */
  filepathLoad?: Maybe<Scalars['String']>;
  /** 元ファイルパス / ユーザーがアップロードしたs3のファイルパス */
  filepathSource: Scalars['String'];
  /** 変換後ファイルパス / csv, utf-8に変換したファイルのパス */
  filepathTransformed?: Maybe<Scalars['String']>;
  /** 検証後ファイルパス / 検証後カラムを追加したファイルのパス / __type, __messageを追加 */
  filepathValidated?: Maybe<Scalars['String']>;
  /** 検証後ファイル変換後ファイルパス / 検証後カラムを追加し、元のファイル形式に戻したファイルのパス */
  filepathValidatedTransformed?: Maybe<Scalars['String']>;
  formatType: AsyncStatusFormatTypeDb;
  /** フォーマット種別 / 1: json, 2: csv, 3: excel_csv, 4: excel */
  formatTypeFlg: Scalars['String'];
  getFileurlSource?: Maybe<Scalars['String']>;
  getFileurlTransformed?: Maybe<Scalars['String']>;
  getFileurlValidated?: Maybe<Scalars['String']>;
  getFileurlValidatedTransformed?: Maybe<Scalars['String']>;
  /** ラベル / フィルタ用ラベル / 自由記述 */
  label?: Maybe<Scalars['String']>;
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** メタ情報 / インポートメタ情報 / json文字列 */
  metainfo: Scalars['String'];
  /** 操作id / uuid */
  operationId: Scalars['String'];
  putFileurlSource?: Maybe<Scalars['String']>;
  /** 全件レコード件数 */
  recordCountAll?: Maybe<Scalars['Int']>;
  /** 無視レコード件数 */
  recordCountIgnore?: Maybe<Scalars['Int']>;
  /** 違反レコード件数 */
  recordCountInvalid?: Maybe<Scalars['Int']>;
  /** 新規レコード件数 */
  recordCountNew?: Maybe<Scalars['Int']>;
  /** 上書レコード件数 */
  recordCountUpdate?: Maybe<Scalars['Int']>;
  /** 適合レコード件数 */
  recordCountValid?: Maybe<Scalars['Int']>;
  status: AsyncStatusTypeDb;
  /** 状況 */
  statusFlg: Scalars['String'];
  /** 処理対象日_ui / バッチ実行時に指定した処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiSAsyncImportStatusConnection = {
  __typename?: 'UiSAsyncImportStatusConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiSAsyncImportStatusEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiSAsyncImportStatus` and its cursor. */
export type UiSAsyncImportStatusEdge = {
  __typename?: 'UiSAsyncImportStatusEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiSAsyncImportStatus>;
};

export type UiSConst = {
  __typename?: 'UiSConst';
  /** コメント / 定義の説明 / システム担当者へのコメント */
  comment: Scalars['String'];
  /** 小数 / 小数定義の場合の値 */
  dec?: Maybe<Scalars['Float']>;
  /** 分類 / 定数の分類 */
  domain: Scalars['String'];
  /** 定義キー / 定義キー / 検索キー */
  key: Scalars['String'];
  /** 定義名 / 定義日本語名 */
  name: Scalars['String'];
  /** 整数 / 整数定義の場合の値 */
  num?: Maybe<Scalars['Int']>;
  /** 処理対象日_ui / バッチ実行時に指定した処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** テキスト / 文字列定義の場合の値 */
  txt?: Maybe<Scalars['String']>;
  /** 更新時刻 / 取り込み時刻 / 障害追跡用 */
  updTime: Scalars['DateTime'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiSConstConnection = {
  __typename?: 'UiSConstConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiSConstEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiSConst` and its cursor. */
export type UiSConstEdge = {
  __typename?: 'UiSConstEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiSConst>;
};

export type UiSImportLock = {
  __typename?: 'UiSImportLock';
  /** ロックフラグ / 0:未使用、1:更新中 */
  lockFlg: Scalars['String'];
  /** テーブルid / インポートで処理されるテーブル / ui_p_userなど */
  tableId: Scalars['String'];
  /** 処理対象日_ui / バッチ実行時に指定した処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻 / ロックフラグが1に変更された日時 */
  updTime: Scalars['DateTime'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiSImportLockConnection = {
  __typename?: 'UiSImportLockConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiSImportLockEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiSImportLock` and its cursor. */
export type UiSImportLockEdge = {
  __typename?: 'UiSImportLockEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiSImportLock>;
};

export type UiSUser = {
  __typename?: 'UiSUser';
  /** cognito識別子 */
  cognitoId?: Maybe<Scalars['String']>;
  /** 表示名 */
  displayName: Scalars['String'];
  /** システム管理者権限 / true:システム管理者権限あり */
  isAdministrator?: Maybe<Scalars['Boolean']>;
  /** ログインid */
  loginId: Scalars['String'];
  /** 処理対象日_ui / バッチ実行時に指定した処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type UiSUserConnection = {
  __typename?: 'UiSUserConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UiSUserEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UiSUser` and its cursor. */
export type UiSUserEdge = {
  __typename?: 'UiSUserEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<UiSUser>;
};

/** ユーザー情報 */
export type UserInformation = {
  __typename?: 'UserInformation';
  /** 利用可能パス */
  availablePaths?: Maybe<Array<Maybe<UserInformationAvailablePath>>>;
  /** 表示名 */
  displayName?: Maybe<Scalars['String']>;
  /** システム管理者権限 */
  isAdministrator?: Maybe<Scalars['Boolean']>;
  /** ログインID */
  loginId?: Maybe<Scalars['String']>;
};

export type UserInformationAvailablePath = {
  __typename?: 'UserInformationAvailablePath';
  /** 利用可能パス */
  path?: Maybe<Scalars['String']>;
};

export type VUiMItemApplyLevel = {
  __typename?: 'VUiMItemApplyLevel';
  /** 商品適用レベル / 0:all,1:genre,2:item */
  itemApplyLevel: Scalars['String'];
  /** 商品適用レベル名称 */
  itemApplyLevelName: Scalars['String'];
  /** 商品適用範囲 / 0:all,適用ジャンルor適用商品コード / 2の場合、sale_item_cd */
  itemApplyScope: Scalars['String'];
  /** 商品適用範囲名称 */
  itemApplyScopeName: Scalars['String'];
  items?: Maybe<AllSaleitemMstConnection>;
  /**
   * a: VUiMItemApplyLevel, b: UiMRefItemApplyLevelSaleitem, c: AllSaleitemMst<br><br>
   * a.item_apply_level == '2'
   */
  m2oItem?: Maybe<AllSaleitemMst>;
  /**
   * a: VUiMItemApplyLevel, b: UiMRefItemApplyLevelSaleitem, c: AllSaleitemMst<br><br>
   * b.sale_item_cd == c.item_cd
   */
  saleItems?: Maybe<AllSaleitemMstConnection>;
  uiMCategory1s?: Maybe<UiMCategory1Connection>;
  uiMCategory2s?: Maybe<UiMCategory2Connection>;
  uiMCategory3s?: Maybe<UiMCategory3Connection>;
  uiMRefItemApplyLevel_Category1s?: Maybe<UiMRefItemApplyLevelCategory1Connection>;
  uiMRefItemApplyLevel_Category2s?: Maybe<UiMRefItemApplyLevelCategory2Connection>;
  uiMRefItemApplyLevel_Category3s?: Maybe<UiMRefItemApplyLevelCategory3Connection>;
  uiMRefItemApplyLevel_SaleItems?: Maybe<UiMRefItemApplyLevelSaleitemConnection>;
  vUiMStoreItemApplyLevels?: Maybe<VUiMStoreItemApplyLevelConnection>;
  vUiPAutoorderStops?: Maybe<VUiPAutoorderStopConnection>;
  vUiPBaseConfs?: Maybe<VUiPBaseConfConnection>;
  vUiPCausalWeightConfs?: Maybe<VUiPCausalWeightConfConnection>;
  vUiPItemFeature?: Maybe<VUiPItemFeature>;
};


export type VUiMItemApplyLevelItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMItemApplyLevelSaleItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMItemApplyLevelUiMCategory1sArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMItemApplyLevelUiMCategory2sArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMItemApplyLevelUiMCategory3sArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMItemApplyLevelUiMRefItemApplyLevel_Category1sArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMItemApplyLevelUiMRefItemApplyLevel_Category2sArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMItemApplyLevelUiMRefItemApplyLevel_Category3sArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMItemApplyLevelUiMRefItemApplyLevel_SaleItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMItemApplyLevelVUiMStoreItemApplyLevelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMItemApplyLevelVUiPAutoorderStopsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMItemApplyLevelVUiPBaseConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMItemApplyLevelVUiPCausalWeightConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type VUiMItemApplyLevelConnection = {
  __typename?: 'VUiMItemApplyLevelConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiMItemApplyLevelEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiMItemApplyLevel` and its cursor. */
export type VUiMItemApplyLevelEdge = {
  __typename?: 'VUiMItemApplyLevelEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiMItemApplyLevel>;
};

export type VUiMStoreApplyLevel = {
  __typename?: 'VUiMStoreApplyLevel';
  /** 店舗適用レベル / 0:all,1:area,2:store */
  storeApplyLevel: Scalars['String'];
  /** 店舗適用レベル名称 / 全店/エリア/単店 */
  storeApplyLevelName: Scalars['String'];
  /** 店舗適用レベル名称_棚パターン / 全店/棚パターン/単店 */
  storeApplyLevelNameShelfPattern: Scalars['String'];
  /** 店舗適用範囲 / 0:all,適用エリアコード or 適用店舗コード */
  storeApplyScope: Scalars['String'];
  /** 店舗適用範囲名称 */
  storeApplyScopeName: Scalars['String'];
  stores?: Maybe<AllStoreMstConnection>;
  vUiMStoreApplyLevel_Areas?: Maybe<VUiMStoreApplyLevelAreaConnection>;
  vUiMStoreApplyLevel_Stores?: Maybe<VUiMStoreApplyLevelStoreConnection>;
  vUiMStoreItemApplyLevels?: Maybe<VUiMStoreItemApplyLevelConnection>;
  vUiPAreas?: Maybe<VUiPAreaConnection>;
  vUiPAutoorderStops?: Maybe<VUiPAutoorderStopConnection>;
  vUiPBaseConfs?: Maybe<VUiPBaseConfConnection>;
  vUiPCausalWeightConfs?: Maybe<VUiPCausalWeightConfConnection>;
  vUiPOrderPatternSettingSiis?: Maybe<VUiPOrderPatternSettingSiiConnection>;
  /**
   * a: VUiMStoreApplyLevel, b: VUiMStoreApplyLevelArea, c: AllStoreMst<br><br>
   * UI_BUSINESS_DATE between b.start_date and b.end_date
   */
  validStores?: Maybe<AllStoreMstConnection>;
  /**
   * a: VUiMStoreApplyLevel, b: VUiMStoreApplyLevelArea, c: VUiPArea<br><br>
   * UI_BUSINESS_DATE between b.start_date and b.end_date
   */
  validVUiPAreas?: Maybe<VUiPAreaConnection>;
};


export type VUiMStoreApplyLevelStoresArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMStoreApplyLevelVUiMStoreApplyLevel_AreasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMStoreApplyLevelVUiMStoreApplyLevel_StoresArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMStoreApplyLevelVUiMStoreItemApplyLevelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMStoreApplyLevelVUiPAreasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMStoreApplyLevelVUiPAutoorderStopsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMStoreApplyLevelVUiPBaseConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMStoreApplyLevelVUiPCausalWeightConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMStoreApplyLevelVUiPOrderPatternSettingSiisArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMStoreApplyLevelValidStoresArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiMStoreApplyLevelValidVUiPAreasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type VUiMStoreApplyLevelArea = {
  __typename?: 'VUiMStoreApplyLevelArea';
  /** エリアコード / 0','0'の場合でarea_cdが違う場合があるので、このカラムもpk */
  areaCd: Scalars['String'];
  /** エリア種別 / 0:運用エリア 1:基幹エリア 2:棚パターン */
  areaType: Scalars['String'];
  /** 終了日 / 元テーブルでnullの場合、2999-12-31 */
  endDate: Scalars['Date'];
  /** 開始日 / 元テーブルでnullの場合、2000-01-01 */
  startDate: Scalars['Date'];
  /** 店舗適用レベル / 0:all,1:area,2:store */
  storeApplyLevel: Scalars['String'];
  /** 店舗適用範囲 / 0:all,適用エリアコード or 適用店舗コード */
  storeApplyScope: Scalars['String'];
  vUiMStoreApplyLevel?: Maybe<VUiMStoreApplyLevel>;
  vUiPArea?: Maybe<VUiPArea>;
};

export type VUiMStoreApplyLevelAreaConnection = {
  __typename?: 'VUiMStoreApplyLevelAreaConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiMStoreApplyLevelAreaEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiMStoreApplyLevelArea` and its cursor. */
export type VUiMStoreApplyLevelAreaEdge = {
  __typename?: 'VUiMStoreApplyLevelAreaEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiMStoreApplyLevelArea>;
};

export type VUiMStoreApplyLevelConnection = {
  __typename?: 'VUiMStoreApplyLevelConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiMStoreApplyLevelEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiMStoreApplyLevel` and its cursor. */
export type VUiMStoreApplyLevelEdge = {
  __typename?: 'VUiMStoreApplyLevelEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiMStoreApplyLevel>;
};

export type VUiMStoreApplyLevelStore = {
  __typename?: 'VUiMStoreApplyLevelStore';
  /** エリア種別 / 0:運用エリア 1:基幹エリア 2:棚パターン, *:単店（エリアに関係ない） */
  areaType: Scalars['String'];
  /** 終了日 / 元テーブルでnullの場合、2999-12-31 */
  endDate: Scalars['Date'];
  /** 開始日 / 元テーブルでnullの場合、2000-01-01 */
  startDate: Scalars['Date'];
  store?: Maybe<AllStoreMst>;
  /** 店舗適用レベル / 0:all,1:area,2:store */
  storeApplyLevel: Scalars['String'];
  /** 店舗適用範囲 / 0:all,適用エリアコード or 適用店舗コード */
  storeApplyScope: Scalars['String'];
  /** 店舗コード / 0','0'の場合でstore_cdが違う場合があるので、このカラムもpk */
  storeCd: Scalars['String'];
  vUiMStoreApplyLevel?: Maybe<VUiMStoreApplyLevel>;
};

export type VUiMStoreApplyLevelStoreConnection = {
  __typename?: 'VUiMStoreApplyLevelStoreConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiMStoreApplyLevelStoreEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiMStoreApplyLevelStore` and its cursor. */
export type VUiMStoreApplyLevelStoreEdge = {
  __typename?: 'VUiMStoreApplyLevelStoreEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiMStoreApplyLevelStore>;
};

export type VUiMStoreItemApplyLevel = {
  __typename?: 'VUiMStoreItemApplyLevel';
  /** 商品適用レベル / 0:all,1:genre,2:item */
  itemApplyLevel: Scalars['String'];
  /** 商品適用範囲 / 0:all,適用ジャンルor適用商品コード / 2の場合、sale_item_cd */
  itemApplyScope: Scalars['String'];
  /** 店舗適用レベル / 0:all,1:area,2:store */
  storeApplyLevel: Scalars['String'];
  /** 店舗適用範囲 / 0:all,適用エリアコード or 適用店舗コード */
  storeApplyScope: Scalars['String'];
  storeItem?: Maybe<AllStoreitemMst>;
  vUiMItemApplyLevel?: Maybe<VUiMItemApplyLevel>;
  vUiMStoreApplyLevel?: Maybe<VUiMStoreApplyLevel>;
  vUiPBaseConfs?: Maybe<VUiPBaseConfConnection>;
};


export type VUiMStoreItemApplyLevelVUiPBaseConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type VUiMStoreItemApplyLevelConnection = {
  __typename?: 'VUiMStoreItemApplyLevelConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiMStoreItemApplyLevelEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiMStoreItemApplyLevel` and its cursor. */
export type VUiMStoreItemApplyLevelEdge = {
  __typename?: 'VUiMStoreItemApplyLevelEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiMStoreItemApplyLevel>;
};

export type VUiPAdjustedDowIndex = {
  __typename?: 'VUiPAdjustedDowIndex';
  /** 予算商品群id */
  budgetGroupId: Scalars['String'];
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 登録日時 */
  createDatetime?: Maybe<Scalars['DateTime']>;
  /** 登録者id */
  createUser?: Maybe<Scalars['String']>;
  /** 登録者名 */
  createUserName?: Maybe<Scalars['String']>;
  /** 終了日 */
  endDate?: Maybe<Scalars['Date']>;
  /** 終了日nvl / 終了日がnullの場合、2999-12-31に変換するカラム */
  endDateNvl: Scalars['Date'];
  /** エンジン販売曜日指数（金） */
  engineFriIndex?: Maybe<Scalars['Float']>;
  /** エンジン販売曜日指数（月） */
  engineMonIndex?: Maybe<Scalars['Float']>;
  /** エンジン販売曜日指数（土） */
  engineSatIndex?: Maybe<Scalars['Float']>;
  /** エンジン販売曜日指数（日） */
  engineSunIndex?: Maybe<Scalars['Float']>;
  /** エンジン販売曜日指数（木） */
  engineThuIndex?: Maybe<Scalars['Float']>;
  /** エンジン販売曜日指数（火） */
  engineTueIndex?: Maybe<Scalars['Float']>;
  /** エンジン販売曜日指数（水） */
  engineWedIndex?: Maybe<Scalars['Float']>;
  /** 販売曜日指数（金） */
  friIndex?: Maybe<Scalars['Float']>;
  /** 削除フラグ / 1:削除 */
  inactiveFlg?: Maybe<Scalars['String']>;
  /** 削除フラグnvl / 終了日がnullの場合、'-'に変換するカラム */
  inactiveFlgNvl: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime?: Maybe<Scalars['DateTime']>;
  /** 最終更新者id */
  lastModifiedUser?: Maybe<Scalars['String']>;
  /** 最終更新者名 */
  lastModifiedUserName?: Maybe<Scalars['String']>;
  /** 販売曜日指数（月） */
  monIndex?: Maybe<Scalars['Float']>;
  /** 販売曜日指数（土） */
  satIndex?: Maybe<Scalars['Float']>;
  /** 開始日 */
  startDate?: Maybe<Scalars['Date']>;
  /** 開始日nvl / 開始日がnullの場合、2000-01-01に変換するカラム */
  startDateNvl: Scalars['Date'];
  /** 店舗コード / 店舗コード */
  storeCd: Scalars['String'];
  /** 店舗コード名称 */
  storeCdName?: Maybe<Scalars['String']>;
  /** 販売曜日指数（日） */
  sunIndex?: Maybe<Scalars['Float']>;
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi?: Maybe<Scalars['Date']>;
  /** 販売曜日指数（木） */
  thuIndex?: Maybe<Scalars['Float']>;
  /** 販売曜日指数（火） */
  tueIndex?: Maybe<Scalars['Float']>;
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi?: Maybe<Scalars['DateTime']>;
  vUiPBudgetGroup?: Maybe<VUiPBudgetGroup>;
  /**
   * a: v_ui_p_adjusted_dow_index, b: v_ui_p_budget_group<br><br>
   * a.inactive_flg = '0' and<br>
   * b.inactive_flg = '0'
   */
  validVUiPBudgetGroup?: Maybe<VUiPBudgetGroup>;
  /** 販売曜日指数（水） */
  wedIndex?: Maybe<Scalars['Float']>;
};

export type VUiPAdjustedDowIndexConnection = {
  __typename?: 'VUiPAdjustedDowIndexConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPAdjustedDowIndexEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPAdjustedDowIndex` and its cursor. */
export type VUiPAdjustedDowIndexEdge = {
  __typename?: 'VUiPAdjustedDowIndexEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPAdjustedDowIndex>;
};

export type VUiPArea = {
  __typename?: 'VUiPArea';
  /** エリアコード */
  areaCd: Scalars['String'];
  /** エリア名 */
  areaName: Scalars['String'];
  /** エリア種別 / 0:運用エリア 1:基幹エリア 2:棚パターン / 1と2はバッチで毎日上書きされる */
  areaType: Scalars['String'];
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 終了日 */
  endDate?: Maybe<Scalars['Date']>;
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 開始日 */
  startDate?: Maybe<Scalars['Date']>;
  stores?: Maybe<AllStoreMstConnection>;
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  uiMShelfPatterns?: Maybe<UiMShelfPatternConnection>;
  uiMStoreShelfPatterns?: Maybe<UiMStoreShelfPatternConnection>;
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiMStoreApplyLevel_Areas?: Maybe<VUiMStoreApplyLevelAreaConnection>;
  vUiPAreastores?: Maybe<VUiPAreastoreConnection>;
  /**
   * a: v_ui_p_area, b: v_ui_p_areastore, c: all_store_mst<br><br>
   * a.inactive_flg = '0' and<br>b.inactive_flg = '0' and<br>UI_BUSINESS_DATE between b.start_date and b.end_date
   */
  validStores?: Maybe<AllStoreMstConnection>;
  /**
   * a: ui_m_shelf_pattern, b: v_ui_p_area<br><br>
   * UI_BUSINESS_DATE between a.start_date and a.end_date and<br>b.inactive_flg = '0'
   */
  validUiMShelfPatterns?: Maybe<UiMShelfPatternConnection>;
  /**
   * a: ui_m_store_shelf_pattern, b: v_ui_p_area<br><br>
   * UI_BUSINESS_DATE between a.start_date and a.end_date and<br>b.inactive_flg = '0'
   */
  validUiMStoreShelfPatterns?: Maybe<UiMStoreShelfPatternConnection>;
  /**
   * a: VUiMStoreApplyLevel, b: VUiMStoreApplyLevelArea, c: VUiPArea<br><br>
   * UI_BUSINESS_DATE between b.start_date and b.end_date
   */
  validVUiMStoreApplyLevels?: Maybe<VUiMStoreApplyLevelConnection>;
};


export type VUiPAreaStoresArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiPAreaUiMShelfPatternsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiPAreaUiMStoreShelfPatternsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiPAreaVUiMStoreApplyLevel_AreasArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiPAreaVUiPAreastoresArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiPAreaValidStoresArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiPAreaValidUiMShelfPatternsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiPAreaValidUiMStoreShelfPatternsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiPAreaValidVUiMStoreApplyLevelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type VUiPAreaConnection = {
  __typename?: 'VUiPAreaConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPAreaEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPArea` and its cursor. */
export type VUiPAreaEdge = {
  __typename?: 'VUiPAreaEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPArea>;
};

export type VUiPAreastore = {
  __typename?: 'VUiPAreastore';
  /** エリアコード / サイズを10→24に変更 */
  areaCd: Scalars['String'];
  /** エリアコード名称 */
  areaCdName?: Maybe<Scalars['String']>;
  /** エリア種別 / 0:運用エリア 1:基幹エリア 2:棚パターン */
  areaType: Scalars['String'];
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 終了日 */
  endDate?: Maybe<Scalars['Date']>;
  /** 終了日nvl / 終了日がnullの場合、2999-12-31に変換するカラム */
  endDateNvl: Scalars['Date'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 開始日 */
  startDate: Scalars['Date'];
  store?: Maybe<AllStoreMst>;
  /** 店舗コード */
  storeCd: Scalars['String'];
  /** 店舗コード名称 */
  storeCdName?: Maybe<Scalars['String']>;
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiPArea?: Maybe<VUiPArea>;
};

export type VUiPAreastoreConnection = {
  __typename?: 'VUiPAreastoreConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPAreastoreEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPAreastore` and its cursor. */
export type VUiPAreastoreEdge = {
  __typename?: 'VUiPAreastoreEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPAreastore>;
};

export type VUiPAutoorderStop = {
  __typename?: 'VUiPAutoorderStop';
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 終了日 / 適用期間終了日 / null時は終了期限なしと同義。2999/12/31扱い */
  endDate?: Maybe<Scalars['Date']>;
  /** 終了日nvl / 終了日がnullの場合、2999-12-31に変換するカラム */
  endDateNvl: Scalars['Date'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 商品適用レベル / 0:all,1:category,2:item */
  itemApplyLevel: Scalars['String'];
  /** 商品適用範囲 / 0:all,適用ジャンルor適用商品コード */
  itemApplyScope: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 開始日 / 適用期間開始日 */
  startDate: Scalars['Date'];
  /** 店舗適用レベル / 0:all,1:area,2:store */
  storeApplyLevel: Scalars['String'];
  /** 店舗適用範囲 / 0:all,適用エリアコード or 適用店舗コード */
  storeApplyScope: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiMItemApplyLevel?: Maybe<VUiMItemApplyLevel>;
  vUiMStoreApplyLevel?: Maybe<VUiMStoreApplyLevel>;
};

export type VUiPAutoorderStopConnection = {
  __typename?: 'VUiPAutoorderStopConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPAutoorderStopEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPAutoorderStop` and its cursor. */
export type VUiPAutoorderStopEdge = {
  __typename?: 'VUiPAutoorderStopEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPAutoorderStop>;
};

export type VUiPBaseConf = {
  __typename?: 'VUiPBaseConf';
  /** [Ref only] 自動発注停止フラグ */
  autoorderStopFlg: Scalars['String'];
  /** 基礎在庫 / ui_out_sml設定マスタ */
  basicInventory: Scalars['Int'];
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 終了日 / 適用終了日 / エリアの場合：棚割の有効日に連動 エリア以外の場合：未設定（無効にしない限り有効） */
  endDate?: Maybe<Scalars['Date']>;
  /** 終了日nvl / 終了日がnullの場合、2999-12-31に変換するカラム */
  endDateNvl: Scalars['Date'];
  /** [Ref only] フェイス数 */
  faceNum?: Maybe<Scalars['Int']>;
  flg_VUiPBaseConf_AllStoreConfig: Scalars['String'];
  flg_VUiPBaseConf_EachStoreConfig: Scalars['String'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 商品適用レベル / 2:item 固定 / 実質固定値だが、将来用にキーとして保持 */
  itemApplyLevel: Scalars['String'];
  /** 商品適用範囲 / 適用商品コード */
  itemApplyScope: Scalars['String'];
  /** [Ref Where only] 商品状態コード */
  itemStatus?: Maybe<Scalars['String']>;
  /** [Ref only] 商品状態名 */
  itemStatusName?: Maybe<Scalars['String']>;
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 最長発注間隔日数 / ui_out_押し出し発注マスタ */
  limitOrderIntervalDays?: Maybe<Scalars['Int']>;
  /** 発注点上限 / 発注点計算で算出した値が上限超過する場合、 上限値に切り下げ / ui_out_sml設定マスタ */
  limitOrderPoint?: Maybe<Scalars['Int']>;
  /** 備考1 */
  note1?: Maybe<Scalars['String']>;
  /** 備考2 */
  note2?: Maybe<Scalars['String']>;
  /** 加算発注量（数量） / 任意数を必要在庫に加算 / ui_out_推奨調整マスタ */
  orderQuantityAdd?: Maybe<Scalars['Int']>;
  /** 送り込み数 / ui_out_押し出し発注マスタ */
  orderSendNum?: Maybe<Scalars['Int']>;
  /** 加算発注量（回後） / 何回後の納品日まで引き上げるか（指定納品日までの販売予測数をmaxに加算） / ui_out_推奨調整マスタ */
  orderTimesAdd?: Maybe<Scalars['Int']>;
  /** 強制1終了期間 / ui_out_sml設定マスタ */
  p1EndDate?: Maybe<Scalars['Date']>;
  /** 強制1最大(+) / ui_out_sml設定マスタ */
  p1MaxAdd?: Maybe<Scalars['Int']>;
  /** 強制1smlパターン / s1,s2,s3,m1,m2,l1,l2,l3,r1,r2,r3 / ui_out_sml設定マスタ */
  p1SmlPattern?: Maybe<Scalars['String']>;
  /** 強制1開始期間 / ui_out_sml設定マスタ */
  p1StartDate?: Maybe<Scalars['Date']>;
  /** 強制1週販(倍) / ui_out_sml設定マスタ */
  p1WeeklySalesRate?: Maybe<Scalars['Float']>;
  /** 強制2終了期間 / ui_out_sml設定マスタ */
  p2EndDate?: Maybe<Scalars['Date']>;
  /** 強制2最大(+) / ui_out_sml設定マスタ */
  p2MaxAdd?: Maybe<Scalars['Int']>;
  /** 強制2smlパターン / s1,s2,s3,m1,m2,l1,l2,l3,r1,r2,r3 / ui_out_sml設定マスタ */
  p2SmlPattern?: Maybe<Scalars['String']>;
  /** 強制2開始期間 / ui_out_sml設定マスタ */
  p2StartDate?: Maybe<Scalars['Date']>;
  /** 強制2週販(倍) / ui_out_sml設定マスタ */
  p2WeeklySalesRate?: Maybe<Scalars['Float']>;
  /** 発注上限日数 / 発注上限日数 / ui_out_推奨上限日数マスタ */
  recommendLimitDays?: Maybe<Scalars['Int']>;
  /** 開始日 / 適用開始日 / エリアの場合：棚割の有効日に連動 エリア以外の場合：投入日+1で設定（翌処理から即有効） */
  startDate: Scalars['Date'];
  /** 店舗適用レベル / 0:all,1:area,2:store */
  storeApplyLevel: Scalars['String'];
  /** 店舗適用範囲 / 0:all,適用エリアコード or 適用店舗コード */
  storeApplyScope: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  uiMRefStoreitemLevelShelfPattern?: Maybe<UiMRefStoreitemLevelShelfPattern>;
  uiMRefStoreitemScopeSale?: Maybe<UiMRefStoreitemScopeSale>;
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiMItemApplyLevel?: Maybe<VUiMItemApplyLevel>;
  vUiMStoreApplyLevel?: Maybe<VUiMStoreApplyLevel>;
  vUiMStoreItemApplyLevel?: Maybe<VUiMStoreItemApplyLevel>;
  vUiPBaseConf_AllStoreConfig?: Maybe<VUiPBaseConfAllStoreConfig>;
  vUiPBaseConf_EachStoreConfig?: Maybe<VUiPBaseConfEachStoreConfig>;
  vUiPBaseConf_TranRef?: Maybe<VUiPBaseConfTranRef>;
};

export type VUiPBaseConfAllStoreConfig = {
  __typename?: 'VUiPBaseConfAllStoreConfig';
  /** 全店設定終了日 */
  allStoreConfEndDate: Scalars['Date'];
  /** 全店設定開始日 */
  allStoreConfStartDate: Scalars['Date'];
  /** 終了日 */
  endDate: Scalars['Date'];
  /** 商品適用レベル / 2:item 固定 / 実質固定値だが、将来用にキーとして保持 */
  itemApplyLevel: Scalars['String'];
  /** 商品適用範囲 / 適用商品コード */
  itemApplyScope: Scalars['String'];
  /** 開始日 */
  startDate: Scalars['Date'];
  /** 店舗適用レベル / 0:all,1:area,2:store */
  storeApplyLevel: Scalars['String'];
  /** 店舗適用範囲 / 0:all,適用エリアコード or 適用店舗コード */
  storeApplyScope: Scalars['String'];
  vUiPBaseConf?: Maybe<VUiPBaseConf>;
};

export type VUiPBaseConfAllStoreConfigConnection = {
  __typename?: 'VUiPBaseConfAllStoreConfigConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPBaseConfAllStoreConfigEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPBaseConfAllStoreConfig` and its cursor. */
export type VUiPBaseConfAllStoreConfigEdge = {
  __typename?: 'VUiPBaseConfAllStoreConfigEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPBaseConfAllStoreConfig>;
};

export type VUiPBaseConfConnection = {
  __typename?: 'VUiPBaseConfConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPBaseConfEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type VUiPBaseConfEachStoreConfig = {
  __typename?: 'VUiPBaseConfEachStoreConfig';
  /** 個店設定終了日 */
  eachStoreConfEndDate: Scalars['Date'];
  /** 個店設定開始日 */
  eachStoreConfStartDate: Scalars['Date'];
  /** 個店設定店舗コード */
  eachStoreStoreCd: Scalars['String'];
  /** 終了日 */
  endDate: Scalars['Date'];
  /** 商品適用レベル / 2:item 固定 / 実質固定値だが、将来用にキーとして保持 */
  itemApplyLevel: Scalars['String'];
  /** 商品適用範囲 / 適用商品コード */
  itemApplyScope: Scalars['String'];
  /** 開始日 */
  startDate: Scalars['Date'];
  /** 店舗適用レベル / 0:all,1:area,2:store */
  storeApplyLevel: Scalars['String'];
  /** 店舗適用範囲 / 0:all,適用エリアコード or 適用店舗コード */
  storeApplyScope: Scalars['String'];
  vUiPBaseConf?: Maybe<VUiPBaseConf>;
};

export type VUiPBaseConfEachStoreConfigConnection = {
  __typename?: 'VUiPBaseConfEachStoreConfigConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPBaseConfEachStoreConfigEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPBaseConfEachStoreConfig` and its cursor. */
export type VUiPBaseConfEachStoreConfigEdge = {
  __typename?: 'VUiPBaseConfEachStoreConfigEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPBaseConfEachStoreConfig>;
};

/** A Relay edge containing a `VUiPBaseConf` and its cursor. */
export type VUiPBaseConfEdge = {
  __typename?: 'VUiPBaseConfEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPBaseConf>;
};

export type VUiPBaseConfTranRef = {
  __typename?: 'VUiPBaseConfTranRef';
  /** 終了日 */
  endDate: Scalars['Date'];
  /** 商品適用レベル / 2:item 固定 / 実質固定値だが、将来用にキーとして保持 */
  itemApplyLevel: Scalars['String'];
  /** 商品適用範囲 / 適用商品コード */
  itemApplyScope: Scalars['String'];
  /** 開始日 */
  startDate: Scalars['Date'];
  /** 店舗適用レベル / 0:all,1:area,2:store */
  storeApplyLevel: Scalars['String'];
  /** 店舗適用範囲 / 0:all,適用エリアコード or 適用店舗コード */
  storeApplyScope: Scalars['String'];
  /** 有効期限 表示用 / excel表示用 */
  vExpireDate?: Maybe<Scalars['Date']>;
  /** 実績参照 表示用 / excel表示用 */
  vTranRef?: Maybe<Scalars['String']>;
  vUiPBaseConf?: Maybe<VUiPBaseConf>;
};

export type VUiPBaseConfTranRefConnection = {
  __typename?: 'VUiPBaseConfTranRefConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPBaseConfTranRefEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPBaseConfTranRef` and its cursor. */
export type VUiPBaseConfTranRefEdge = {
  __typename?: 'VUiPBaseConfTranRefEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPBaseConfTranRef>;
};

export type VUiPBudgetGroup = {
  __typename?: 'VUiPBudgetGroup';
  /** 予算商品群id */
  budgetGroupId: Scalars['String'];
  /** 親カテゴリコード / 直属の親となるカテゴリコード / 第2分類コード */
  category0?: Maybe<Scalars['String']>;
  /** カテゴリ１ / 予算商品群の商品カテゴリ１ / 全カテゴリが対象の場合は'*' */
  category1: Scalars['String'];
  /** カテゴリ２ / 予算商品群の商品カテゴリ２ / 全カテゴリが対象の場合は'*' */
  category2: Scalars['String'];
  /** カテゴリ３ / 予算商品群の商品カテゴリ３ / 全カテゴリが対象の場合は'*' */
  category3: Scalars['String'];
  /** カテゴリ４ / 予算商品群の商品カテゴリ４ / 全カテゴリが対象の場合は'*' */
  category4: Scalars['String'];
  /** カテゴリ５ / 予算商品群の商品カテゴリ５ / 全カテゴリが対象の場合は'*' */
  category5: Scalars['String'];
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  uiMCategory0?: Maybe<UiMCategory0>;
  uiMCategory1?: Maybe<UiMCategory1>;
  uiMCategory2?: Maybe<UiMCategory2>;
  uiMCategory3?: Maybe<UiMCategory3>;
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiPAdjustedDowIndices?: Maybe<VUiPAdjustedDowIndexConnection>;
  vUiPWeeklyDailyBudgetConfs?: Maybe<VUiPWeeklyDailyBudgetConfConnection>;
  vUiPWeightAdjConf?: Maybe<VUiPWeightAdjConf>;
  /**
   * a: v_ui_p_adjusted_dow_index, b: v_ui_p_budget_group<br><br>
   * a.inactive_flg = '0' and<br>
   * b.inactive_flg = '0'
   */
  validVUiPAdjustedDowIndexes?: Maybe<VUiPAdjustedDowIndexConnection>;
  /**
   * a: v_ui_p_weekly_daily_budget_conf, b: v_ui_p_budget_group<br><br>
   * a.inactive_flg = '0' and<br>
   * b.inactive_flg = '0'
   */
  validVUiPWeeklyDailyBudgetConfs?: Maybe<VUiPWeeklyDailyBudgetConfConnection>;
  /**
   * a: v_ui_p_weight_adj_conf, b: v_ui_p_budget_group<br><br>
   * a.inactive_flg = '0' and<br>
   * b.inactive_flg = '0'
   */
  validVUiPWeightAdjConfs?: Maybe<VUiPWeightAdjConfConnection>;
};


export type VUiPBudgetGroupVUiPAdjustedDowIndicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiPBudgetGroupVUiPWeeklyDailyBudgetConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiPBudgetGroupValidVUiPAdjustedDowIndexesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiPBudgetGroupValidVUiPWeeklyDailyBudgetConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


export type VUiPBudgetGroupValidVUiPWeightAdjConfsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type VUiPBudgetGroupConnection = {
  __typename?: 'VUiPBudgetGroupConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPBudgetGroupEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPBudgetGroup` and its cursor. */
export type VUiPBudgetGroupEdge = {
  __typename?: 'VUiPBudgetGroupEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPBudgetGroup>;
};

export type VUiPCausalName = {
  __typename?: 'VUiPCausalName';
  /** コーザルコード / 任意の一意となるコーザルコード */
  causalCd: Scalars['String'];
  /** コーザル名 / 任意のコーザル名称 / 店舗ランク、天気、対策店舗、etc */
  causalName: Scalars['String'];
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type VUiPCausalNameConnection = {
  __typename?: 'VUiPCausalNameConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPCausalNameEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPCausalName` and its cursor. */
export type VUiPCausalNameEdge = {
  __typename?: 'VUiPCausalNameEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPCausalName>;
};

export type VUiPCausalWeightConf = {
  __typename?: 'VUiPCausalWeightConf';
  /** 補正タイプ / 0: 乗率, 1: 絶対値加算 */
  adjustType: Scalars['Int'];
  /** 補正タイプ名称 */
  adjustTypeName?: Maybe<Scalars['String']>;
  /** コーザルコード / コーザル名 / コーザルのラベルを指す（店舗ランク、とか対策店舗、とか天気とか） */
  causalCd: Scalars['String'];
  /** コーザルコード名称 */
  causalCdName?: Maybe<Scalars['String']>;
  /** コーザルロジック / 動作ロジックパターンを指定 / 有効な値はコーザルロジック設定マスタのもの */
  causalType: Scalars['String'];
  /** コーザルロジック名称 */
  causalTypeName?: Maybe<Scalars['String']>;
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 終了日 / コーザルの適用終了日 */
  endDate?: Maybe<Scalars['Date']>;
  /** 終了日nvl / 終了日がnullの場合、2999-12-31に変換するカラム */
  endDateNvl: Scalars['Date'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 商品適用レベル / 0:all,1:genre,2:item */
  itemApplyLevel: Scalars['String'];
  /** 商品適用範囲 / 0:all,適用ジャンルor適用商品コード */
  itemApplyScope: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** パラメータ1 / 補正乗率 / 補正数を指定 */
  rsvParam1: Scalars['Float'];
  /** パラメータ2 / 同上 */
  rsvParam2?: Maybe<Scalars['Float']>;
  /** パラメータ3 / 同上 */
  rsvParam3?: Maybe<Scalars['Float']>;
  /** パラメータ4 / 同上 */
  rsvParam4?: Maybe<Scalars['Float']>;
  /** パラメータ5 / 同上 */
  rsvParam5?: Maybe<Scalars['Float']>;
  /** パラメータ6 / 同上 */
  rsvParam6?: Maybe<Scalars['Float']>;
  /** パラメータ7 / 同上 */
  rsvParam7?: Maybe<Scalars['Float']>;
  /** パラメータ8 / 同上 */
  rsvParam8?: Maybe<Scalars['Float']>;
  /** パラメータ9 / 同上 */
  rsvParam9?: Maybe<Scalars['Float']>;
  /** パラメータ10 / 同上 */
  rsvParam10?: Maybe<Scalars['Float']>;
  /** パラメータ11 / 同上 */
  rsvParam11?: Maybe<Scalars['Float']>;
  /** パラメータ12 / 同上 */
  rsvParam12?: Maybe<Scalars['Float']>;
  /** パラメータ13 / 同上 */
  rsvParam13?: Maybe<Scalars['Float']>;
  /** パラメータ14 / 同上 */
  rsvParam14?: Maybe<Scalars['Float']>;
  /** パラメータ15 / 同上 */
  rsvParam15?: Maybe<Scalars['Float']>;
  /** パラメータ16 / 同上 */
  rsvParam16?: Maybe<Scalars['Float']>;
  /** パラメータ17 / 同上 */
  rsvParam17?: Maybe<Scalars['Float']>;
  /** パラメータ18 / 同上 */
  rsvParam18?: Maybe<Scalars['Float']>;
  /** パラメータ19 / 同上 */
  rsvParam19?: Maybe<Scalars['Float']>;
  /** パラメータ20 / 同上 */
  rsvParam20?: Maybe<Scalars['Float']>;
  /** パラメータ21 / 同上 */
  rsvParam21?: Maybe<Scalars['Float']>;
  /** パラメータ22 / 同上 */
  rsvParam22?: Maybe<Scalars['Float']>;
  /** パラメータ23 / 同上 */
  rsvParam23?: Maybe<Scalars['Float']>;
  /** パラメータ24 / 同上 */
  rsvParam24?: Maybe<Scalars['Float']>;
  /** パラメータ25 / 同上 */
  rsvParam25?: Maybe<Scalars['Float']>;
  /** パラメータ26 / 同上 */
  rsvParam26?: Maybe<Scalars['Float']>;
  /** パラメータ27 / 同上 */
  rsvParam27?: Maybe<Scalars['Float']>;
  /** パラメータ28 / 同上 */
  rsvParam28?: Maybe<Scalars['Float']>;
  /** パラメータ29 / 同上 */
  rsvParam29?: Maybe<Scalars['Float']>;
  /** パラメータ30 / 同上 */
  rsvParam30?: Maybe<Scalars['Float']>;
  /** パラメータ31 / 同上 */
  rsvParam31?: Maybe<Scalars['Float']>;
  /** 開始日 / コーザルの適用開始日 */
  startDate: Scalars['Date'];
  /** 店舗適用レベル / 0:all,1:area,2:store */
  storeApplyLevel: Scalars['String'];
  /** 店舗適用範囲 / 0:all,適用エリアor適用店舗コード / サイズを10→24に変更 */
  storeApplyScope: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiMItemApplyLevel?: Maybe<VUiMItemApplyLevel>;
  vUiMStoreApplyLevel?: Maybe<VUiMStoreApplyLevel>;
};

export type VUiPCausalWeightConfConnection = {
  __typename?: 'VUiPCausalWeightConfConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPCausalWeightConfEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPCausalWeightConf` and its cursor. */
export type VUiPCausalWeightConfEdge = {
  __typename?: 'VUiPCausalWeightConfEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPCausalWeightConf>;
};

export type VUiPDspInfo = {
  __typename?: 'VUiPDspInfo';
  /** 棚０確コード / ０確棚コードと同一 */
  certain0ShelfCd: Scalars['String'];
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** カテゴリコード */
  ctgCode: Scalars['String'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** パターンコード */
  ptnCode: Scalars['String'];
  /** 段 */
  shelfupNo: Scalars['String'];
  /** 台 */
  standNo: Scalars['String'];
  /** 店舗コード */
  storeCd: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type VUiPDspInfoConnection = {
  __typename?: 'VUiPDspInfoConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPDspInfoEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPDspInfo` and its cursor. */
export type VUiPDspInfoEdge = {
  __typename?: 'VUiPDspInfoEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPDspInfo>;
};

export type VUiPItemConf = {
  __typename?: 'VUiPItemConf';
  /** 発注義務 / 1:発注必須、0：バラエティ入替え可 null: 0 / all_バラエティ商品マスタ */
  absoluteOrderFlg?: Maybe<Scalars['String']>;
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 原価 */
  cost?: Maybe<Scalars['Float']>;
  /** 登録日時 */
  createDatetime?: Maybe<Scalars['DateTime']>;
  /** 登録者id */
  createUser?: Maybe<Scalars['String']>;
  /** 登録者名 */
  createUserName?: Maybe<Scalars['String']>;
  /** 廃棄金額 1週間 */
  discardAmount1w?: Maybe<Scalars['Int']>;
  /** 廃棄数 1週間 */
  discardNum1w?: Maybe<Scalars['Int']>;
  /** 廃棄率 1週間 / 廃棄額(合計) 1週間 / 販売金額(合計) 1週間 */
  discardRate1w?: Maybe<Scalars['Float']>;
  /** 削除フラグ / 1:削除 */
  inactiveFlg?: Maybe<Scalars['String']>;
  /** 削除フラグnvl / 終了日がnullの場合、'-'に変換するカラム */
  inactiveFlgNvl: Scalars['String'];
  item?: Maybe<AllSaleitemMst>;
  /** 在庫管理商品コード / 販売商品コード（jan） */
  itemCd: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime?: Maybe<Scalars['DateTime']>;
  /** 最終更新者id */
  lastModifiedUser?: Maybe<Scalars['String']>;
  /** 最終更新者名 */
  lastModifiedUserName?: Maybe<Scalars['String']>;
  /** メーカーコード名称 */
  makerCdName?: Maybe<Scalars['String']>;
  /** 最少発注数 / all_単品設定マスタ */
  moq?: Maybe<Scalars['Int']>;
  /** 売価 */
  price?: Maybe<Scalars['Int']>;
  /** 粗利金額 1週間 */
  profitAmount1w?: Maybe<Scalars['Float']>;
  /** 粗利金額 3週間 */
  profitAmount3w?: Maybe<Scalars['Float']>;
  /** 粗利率 */
  profitRate?: Maybe<Scalars['Float']>;
  /** 粗利率 3週間 / 粗利金額(合計) 3週間 / 売上金額(合計) 3週間 */
  profitRate3w?: Maybe<Scalars['Float']>;
  r1StoreinDowPattern?: Maybe<VUiPStoreinPattern>;
  /** ランク１納品曜日指定 / all_単品設定マスタ */
  r1StoreinDowPatternId?: Maybe<Scalars['String']>;
  r2StoreinDowPattern?: Maybe<VUiPStoreinPattern>;
  /** ランク２納品曜日指定 / all_単品設定マスタ */
  r2StoreinDowPatternId?: Maybe<Scalars['String']>;
  r3StoreinDowPattern?: Maybe<VUiPStoreinPattern>;
  /** ランク３納品曜日指定 / all_単品設定マスタ */
  r3StoreinDowPatternId?: Maybe<Scalars['String']>;
  r4StoreinDowPattern?: Maybe<VUiPStoreinPattern>;
  /** ランク４納品曜日指定 / all_単品設定マスタ */
  r4StoreinDowPatternId?: Maybe<Scalars['String']>;
  r5StoreinDowPattern?: Maybe<VUiPStoreinPattern>;
  /** ランク５納品曜日指定 / all_単品設定マスタ */
  r5StoreinDowPatternId?: Maybe<Scalars['String']>;
  r6StoreinDowPattern?: Maybe<VUiPStoreinPattern>;
  /** ランク６納品曜日指定 / all_単品設定マスタ */
  r6StoreinDowPatternId?: Maybe<Scalars['String']>;
  /** 販売金額 1週間 */
  saleAmount1w?: Maybe<Scalars['Int']>;
  /** 販売金額 3週間 */
  saleAmount3w?: Maybe<Scalars['Int']>;
  saleItems?: Maybe<AllSaleitemMstConnection>;
  /** 販売数 1週間 */
  saleNum1w?: Maybe<Scalars['Int']>;
  /** 販売数 1週前 / 販売数(合計) 1週間と同じ */
  saleNum1wBefore?: Maybe<Scalars['Int']>;
  /** 販売数 2週前 / 2週前、1週間の合計販売数 */
  saleNum2wBefore?: Maybe<Scalars['Int']>;
  /** 販売数 3週間 / 3週間の合計販売数 */
  saleNum3w?: Maybe<Scalars['Int']>;
  /** 販売数 3週前 / 3週前、1週間の合計販売数 */
  saleNum3wBefore?: Maybe<Scalars['Int']>;
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi?: Maybe<Scalars['Date']>;
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi?: Maybe<Scalars['DateTime']>;
  /** バラエティグループid / all_バラエティ商品マスタ */
  varietyGroupId?: Maybe<Scalars['String']>;
  /** バラエティグループid名称 */
  varietyGroupIdName?: Maybe<Scalars['String']>;
  /** バラエティ優先順位 / １を最優先とする連番 / all_バラエティ商品マスタ、予備群入れ替えで更新 */
  varietyPriority?: Maybe<Scalars['Int']>;
};


export type VUiPItemConfSaleItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type VUiPItemConfConnection = {
  __typename?: 'VUiPItemConfConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPItemConfEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPItemConf` and its cursor. */
export type VUiPItemConfEdge = {
  __typename?: 'VUiPItemConfEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPItemConf>;
};

export type VUiPItemFeature = {
  __typename?: 'VUiPItemFeature';
  /** 自動発注方式 / 1:需要予測型、2:予算按分型 */
  autoorderType: Scalars['String'];
  /** 自動発注方式名称 */
  autoorderTypeName?: Maybe<Scalars['String']>;
  /** カテゴリ1コード / 更新時刻 / 障害追跡用 */
  category1Cd?: Maybe<Scalars['String']>;
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 商品適用レベル / 0:all,1:category,2:item / カテゴリ: 小分類（カテゴリ1） */
  itemApplyLevel: Scalars['String'];
  /** 商品適用範囲 / 0:all,適用ジャンルor適用商品コード */
  itemApplyScope: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiMItemApplyLevel?: Maybe<VUiMItemApplyLevel>;
};

export type VUiPItemFeatureConnection = {
  __typename?: 'VUiPItemFeatureConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPItemFeatureEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPItemFeature` and its cursor. */
export type VUiPItemFeatureEdge = {
  __typename?: 'VUiPItemFeatureEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPItemFeature>;
};

export type VUiPItemOrderNumAdj = {
  __typename?: 'VUiPItemOrderNumAdj';
  /** 単品係数 / 値１で100%を表す */
  adjustRate?: Maybe<Scalars['Float']>;
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 原価 */
  cost?: Maybe<Scalars['Float']>;
  /** 登録日時 */
  createDatetime?: Maybe<Scalars['DateTime']>;
  /** 登録者id */
  createUser?: Maybe<Scalars['String']>;
  /** 登録者名 */
  createUserName?: Maybe<Scalars['String']>;
  /** 廃棄金額 1週間 */
  discardAmount1w?: Maybe<Scalars['Int']>;
  /** 廃棄数 1週間 */
  discardNum1w?: Maybe<Scalars['Int']>;
  /** 廃棄率 1週間 / 廃棄額(合計) 1週間 / 販売金額(合計) 1週間 */
  discardRate1w?: Maybe<Scalars['Float']>;
  /** 終了日 */
  endDate?: Maybe<Scalars['Date']>;
  /** 終了日nvl / 終了日がnullの場合、2999-12-31に変換するカラム */
  endDateNvl: Scalars['Date'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg?: Maybe<Scalars['String']>;
  /** 削除フラグnvl / 終了日がnullの場合、'-'に変換するカラム */
  inactiveFlgNvl: Scalars['String'];
  item?: Maybe<AllSaleitemMst>;
  /** 在庫管理商品コード */
  itemCd: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime?: Maybe<Scalars['DateTime']>;
  /** 最終更新者id */
  lastModifiedUser?: Maybe<Scalars['String']>;
  /** 最終更新者名 */
  lastModifiedUserName?: Maybe<Scalars['String']>;
  /** メーカーコード名称 */
  makerCdName?: Maybe<Scalars['String']>;
  /** 売価 */
  price?: Maybe<Scalars['Int']>;
  /** 粗利金額 1週間 */
  profitAmount1w?: Maybe<Scalars['Float']>;
  /** 粗利金額 3週間 */
  profitAmount3w?: Maybe<Scalars['Float']>;
  /** 粗利率 */
  profitRate?: Maybe<Scalars['Float']>;
  /** 粗利率 3週間 / 粗利金額(合計) 3週間 / 売上金額(合計) 3週間 */
  profitRate3w?: Maybe<Scalars['Float']>;
  /** 販売金額 1週間 */
  saleAmount1w?: Maybe<Scalars['Int']>;
  /** 販売金額 3週間 */
  saleAmount3w?: Maybe<Scalars['Int']>;
  saleItems?: Maybe<AllSaleitemMstConnection>;
  /** 販売数 1週間 */
  saleNum1w?: Maybe<Scalars['Int']>;
  /** 販売数 1週前 / 販売数(合計) 1週間と同じ */
  saleNum1wBefore?: Maybe<Scalars['Int']>;
  /** 販売数 2週前 / 2週前、1週間の合計販売数 */
  saleNum2wBefore?: Maybe<Scalars['Int']>;
  /** 販売数 3週間 / 3週間の合計販売数 */
  saleNum3w?: Maybe<Scalars['Int']>;
  /** 販売数 3週前 / 3週前、1週間の合計販売数 */
  saleNum3wBefore?: Maybe<Scalars['Int']>;
  /** 開始日 */
  startDate?: Maybe<Scalars['Date']>;
  /** 開始日nvl / 開始日がnullの場合、2000-01-01に変換するカラム */
  startDateNvl: Scalars['Date'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi?: Maybe<Scalars['Date']>;
  /** 全店発注数下限 */
  totalOrderNumLower?: Maybe<Scalars['Int']>;
  /** 全店発注数上限 */
  totalOrderNumUpper?: Maybe<Scalars['Int']>;
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi?: Maybe<Scalars['DateTime']>;
  /** 週間最低発注数 */
  weeklyMinOrderNum?: Maybe<Scalars['Int']>;
};


export type VUiPItemOrderNumAdjSaleItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type VUiPItemOrderNumAdjConnection = {
  __typename?: 'VUiPItemOrderNumAdjConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPItemOrderNumAdjEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPItemOrderNumAdj` and its cursor. */
export type VUiPItemOrderNumAdjEdge = {
  __typename?: 'VUiPItemOrderNumAdjEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPItemOrderNumAdj>;
};

export type VUiPLowRankLogicConf = {
  __typename?: 'VUiPLowRankLogicConf';
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 日次最低発注数1_金 */
  minDailyOrderNum1Fri?: Maybe<Scalars['Int']>;
  /** 日次最低発注数1_月 */
  minDailyOrderNum1Mon?: Maybe<Scalars['Int']>;
  /** 日次最低発注数1_土 */
  minDailyOrderNum1Sat?: Maybe<Scalars['Int']>;
  /** 日次最低発注数1_日 */
  minDailyOrderNum1Sun?: Maybe<Scalars['Int']>;
  /** 日次最低発注数1_木 */
  minDailyOrderNum1Thu?: Maybe<Scalars['Int']>;
  /** 日次最低発注数1_火 */
  minDailyOrderNum1Tue?: Maybe<Scalars['Int']>;
  /** 日次最低発注数1_水 */
  minDailyOrderNum1Wed?: Maybe<Scalars['Int']>;
  /** 日次最低発注数2_金 */
  minDailyOrderNum2Fri?: Maybe<Scalars['Int']>;
  /** 日次最低発注数2_月 */
  minDailyOrderNum2Mon?: Maybe<Scalars['Int']>;
  /** 日次最低発注数2_土 */
  minDailyOrderNum2Sat?: Maybe<Scalars['Int']>;
  /** 日次最低発注数2_日 */
  minDailyOrderNum2Sun?: Maybe<Scalars['Int']>;
  /** 日次最低発注数2_木 */
  minDailyOrderNum2Thu?: Maybe<Scalars['Int']>;
  /** 日次最低発注数2_火 */
  minDailyOrderNum2Tue?: Maybe<Scalars['Int']>;
  /** 日次最低発注数2_水 */
  minDailyOrderNum2Wed?: Maybe<Scalars['Int']>;
  /** 週次最低発注数 / 1～8 */
  minWeeklyOrderNum: Scalars['Int'];
  /** 店ランク / 1~n */
  storeRank: Scalars['Int'];
  /** 納品曜日設定 */
  storeinDowPatternId: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type VUiPLowRankLogicConfConnection = {
  __typename?: 'VUiPLowRankLogicConfConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPLowRankLogicConfEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPLowRankLogicConf` and its cursor. */
export type VUiPLowRankLogicConfEdge = {
  __typename?: 'VUiPLowRankLogicConfEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPLowRankLogicConf>;
};

export type VUiPNewStoreBulkOrder = {
  __typename?: 'VUiPNewStoreBulkOrder';
  /** カテゴリ2コード / 直属の親となるカテゴリコード / 第4分類コード、販売商品マスタ.カテゴリ2コード */
  category2Cd: Scalars['String'];
  /** カテゴリ3コード / 第5階層のカテゴリを一意に特定するコード / 第5分類コード、販売商品マスタ.カテゴリ3コード */
  category3Cd: Scalars['String'];
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 商品適用レベル / 0:all,1:genre,2:item */
  itemApplyLevel: Scalars['String'];
  /** 商品適用範囲 / 0:all,適用ジャンルor適用商品コード */
  itemApplyScope: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** パラメータ1 / 補正乗率 / 補正数を指定 */
  rsvParam1: Scalars['Float'];
  /** パラメータ2 / 同上 */
  rsvParam2?: Maybe<Scalars['Float']>;
  /** パラメータ3 / 同上 */
  rsvParam3?: Maybe<Scalars['Float']>;
  /** パラメータ4 / 同上 */
  rsvParam4?: Maybe<Scalars['Float']>;
  /** パラメータ5 / 同上 */
  rsvParam5?: Maybe<Scalars['Float']>;
  /** パラメータ6 / 同上 */
  rsvParam6?: Maybe<Scalars['Float']>;
  /** パラメータ7 / 同上 */
  rsvParam7?: Maybe<Scalars['Float']>;
  /** パラメータ8 / 同上 */
  rsvParam8?: Maybe<Scalars['Float']>;
  /** パラメータ9 / 同上 */
  rsvParam9?: Maybe<Scalars['Float']>;
  /** パラメータ10 / 同上 */
  rsvParam10?: Maybe<Scalars['Float']>;
  /** パラメータ11 / 同上 */
  rsvParam11?: Maybe<Scalars['Float']>;
  /** パラメータ12 / 同上 */
  rsvParam12?: Maybe<Scalars['Float']>;
  /** パラメータ13 / 同上 */
  rsvParam13?: Maybe<Scalars['Float']>;
  /** パラメータ14 / 同上 */
  rsvParam14?: Maybe<Scalars['Float']>;
  /** パラメータ15 / 同上 */
  rsvParam15?: Maybe<Scalars['Float']>;
  /** パラメータ16 / 同上 */
  rsvParam16?: Maybe<Scalars['Float']>;
  /** パラメータ17 / 同上 */
  rsvParam17?: Maybe<Scalars['Float']>;
  /** パラメータ18 / 同上 */
  rsvParam18?: Maybe<Scalars['Float']>;
  /** パラメータ19 / 同上 */
  rsvParam19?: Maybe<Scalars['Float']>;
  /** パラメータ20 / 同上 */
  rsvParam20?: Maybe<Scalars['Float']>;
  /** パラメータ21 / 同上 */
  rsvParam21?: Maybe<Scalars['Float']>;
  /** パラメータ22 / 同上 */
  rsvParam22?: Maybe<Scalars['Float']>;
  /** パラメータ23 / 同上 */
  rsvParam23?: Maybe<Scalars['Float']>;
  /** パラメータ24 / 同上 */
  rsvParam24?: Maybe<Scalars['Float']>;
  /** パラメータ25 / 同上 */
  rsvParam25?: Maybe<Scalars['Float']>;
  /** パラメータ26 / 同上 */
  rsvParam26?: Maybe<Scalars['Float']>;
  /** パラメータ27 / 同上 */
  rsvParam27?: Maybe<Scalars['Float']>;
  /** パラメータ28 / 同上 */
  rsvParam28?: Maybe<Scalars['Float']>;
  /** パラメータ29 / 同上 */
  rsvParam29?: Maybe<Scalars['Float']>;
  /** パラメータ30 / 同上 */
  rsvParam30?: Maybe<Scalars['Float']>;
  /** パラメータ31 / 同上 */
  rsvParam31?: Maybe<Scalars['Float']>;
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type VUiPNewStoreBulkOrderConnection = {
  __typename?: 'VUiPNewStoreBulkOrderConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPNewStoreBulkOrderEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPNewStoreBulkOrder` and its cursor. */
export type VUiPNewStoreBulkOrderEdge = {
  __typename?: 'VUiPNewStoreBulkOrderEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPNewStoreBulkOrder>;
};

export type VUiPOrderPatternSettingSii = {
  __typename?: 'VUiPOrderPatternSettingSii';
  /** 商品カテゴリ1 / 商品の分類（カテゴリー1） / 商品分類用 企業１商品マスタ.第3分類コード */
  category1: Scalars['String'];
  /** 商品カテゴリ1名称 */
  category1Name?: Maybe<Scalars['String']>;
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 終了日 */
  endDate?: Maybe<Scalars['Date']>;
  /** 終了日nvl / 終了日がnullの場合、2999-12-31に変換するカラム */
  endDateNvl: Scalars['Date'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 発注可能フラグ＿金 / 1:可、0:不可 */
  orderFlgFri: Scalars['String'];
  /** 発注可能フラグ＿月 / 1:可、0:不可 */
  orderFlgMon: Scalars['String'];
  /** 発注可能フラグ＿土 / 1:可、0:不可 */
  orderFlgSat: Scalars['String'];
  /** 発注可能フラグ＿日 / 1:可、0:不可 */
  orderFlgSun: Scalars['String'];
  /** 発注可能フラグ＿木 / 1:可、0:不可 */
  orderFlgThu: Scalars['String'];
  /** 発注可能フラグ＿火 / 1:可、0:不可 */
  orderFlgTue: Scalars['String'];
  /** 発注可能フラグ＿水 / 1:可、0:不可 */
  orderFlgWed: Scalars['String'];
  /** 開始日 */
  startDate: Scalars['Date'];
  /** 店舗適用レベル / 0:all,1:area,2:store */
  storeApplyLevel: Scalars['String'];
  /** 店舗適用範囲 / 0:all,適用エリアコード or 適用店舗コード */
  storeApplyScope: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiMStoreApplyLevel?: Maybe<VUiMStoreApplyLevel>;
  /** 発注先コード / 発注先コード */
  vendorCd: Scalars['String'];
  /** 発注先コード名称 */
  vendorCdName?: Maybe<Scalars['String']>;
};

export type VUiPOrderPatternSettingSiiConnection = {
  __typename?: 'VUiPOrderPatternSettingSiiConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPOrderPatternSettingSiiEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPOrderPatternSettingSii` and its cursor. */
export type VUiPOrderPatternSettingSiiEdge = {
  __typename?: 'VUiPOrderPatternSettingSiiEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPOrderPatternSettingSii>;
};

export type VUiPStoreRank = {
  __typename?: 'VUiPStoreRank';
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 店舗コード */
  storeCd: Scalars['String'];
  /** 店ランク / 1~n */
  storeRank: Scalars['Int'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type VUiPStoreRankConnection = {
  __typename?: 'VUiPStoreRankConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPStoreRankEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPStoreRank` and its cursor. */
export type VUiPStoreRankEdge = {
  __typename?: 'VUiPStoreRankEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPStoreRank>;
};

export type VUiPStoreinPattern = {
  __typename?: 'VUiPStoreinPattern';
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 説明 / 偶数日、奇数日の説明 / 必要に応じて記載 */
  desctription: Scalars['String'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 納品曜日パターンid */
  storeinDowPatternId: Scalars['String'];
  /** 納品曜日パターン名 */
  storeinDowPatternName: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type VUiPStoreinPatternConnection = {
  __typename?: 'VUiPStoreinPatternConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPStoreinPatternEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPStoreinPattern` and its cursor. */
export type VUiPStoreinPatternEdge = {
  __typename?: 'VUiPStoreinPatternEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPStoreinPattern>;
};

export type VUiPTranRefItem = {
  __typename?: 'VUiPTranRefItem';
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 有効期限 / 設定が有効な期限を設定する */
  expireDate: Scalars['Date'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 参照元商品コード / 参照元商品コード */
  referrerItemCd: Scalars['String'];
  /** 参照元商品コード名称 */
  referrerItemCdName?: Maybe<Scalars['String']>;
  /** 参照先商品コード / 参照先商品コード */
  refferenceItemCd: Scalars['String'];
  /** 参照先商品コード名称 */
  refferenceItemCdName?: Maybe<Scalars['String']>;
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type VUiPTranRefItemConnection = {
  __typename?: 'VUiPTranRefItemConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPTranRefItemEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPTranRefItem` and its cursor. */
export type VUiPTranRefItemEdge = {
  __typename?: 'VUiPTranRefItemEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPTranRefItem>;
};

export type VUiPTranRefStore = {
  __typename?: 'VUiPTranRefStore';
  /** 参照範囲 / 0: all or カテゴリid / 販売商品.カテゴリ1 */
  category: Scalars['String'];
  /** 参照範囲名称 */
  categoryName?: Maybe<Scalars['String']>;
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 有効期限 / 設定が有効な期限を設定する */
  expireDate: Scalars['Date'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 参照範囲レベル / 0: all, 1: カテゴリ */
  refLevel: Scalars['String'];
  /** 参照範囲レベル名称 */
  refLevelName?: Maybe<Scalars['String']>;
  /** 参照元店舗コード / 参照元店舗 */
  referrerStoreCd: Scalars['String'];
  /** 参照元店舗コード名称 */
  referrerStoreCdName?: Maybe<Scalars['String']>;
  /** 参照先店舗コード / 参照先店舗 */
  refferenceStoreCd: Scalars['String'];
  /** 参照先店舗コード名称 */
  refferenceStoreCdName?: Maybe<Scalars['String']>;
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  uiMCategory1s?: Maybe<UiMCategory1Connection>;
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};


export type VUiPTranRefStoreUiMCategory1sArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type VUiPTranRefStoreConnection = {
  __typename?: 'VUiPTranRefStoreConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPTranRefStoreEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPTranRefStore` and its cursor. */
export type VUiPTranRefStoreEdge = {
  __typename?: 'VUiPTranRefStoreEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPTranRefStore>;
};

export type VUiPUser = {
  __typename?: 'VUiPUser';
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 表示名 */
  displayName: Scalars['String'];
  /** メールアドレス */
  email: Scalars['String'];
  /** 削除フラグ / 0:有効 1:削除 / 削除の場合、「ユーザー無効」 */
  inactiveFlg: Scalars['String'];
  /** システム管理者権限 / 0:権限なし 1:権限あり */
  isAdministrator: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** ログインid */
  loginId: Scalars['String'];
  /** パスワードリセット要求フラグ / 0: 要求なし、1:要求有り */
  passwordResetRequestFlg: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
};

export type VUiPUserConnection = {
  __typename?: 'VUiPUserConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPUserEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPUser` and its cursor. */
export type VUiPUserEdge = {
  __typename?: 'VUiPUserEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPUser>;
};

export type VUiPVarietyGroup = {
  __typename?: 'VUiPVarietyGroup';
  /** 予算商品群id */
  budgetGroupId: Scalars['String'];
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 発注商品数 / このグループで何商品発注するか */
  orderItemNum: Scalars['Int'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  /** バラエティグループid */
  varietyGroupId: Scalars['String'];
  /** バラエティグループ名 / 値１で100%を表す */
  varietyGroupName: Scalars['String'];
  /** 週次入替え曜日 / 予備軍との入替え実施曜日 */
  weeklySwapDow: Scalars['Int'];
};

export type VUiPVarietyGroupConnection = {
  __typename?: 'VUiPVarietyGroupConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPVarietyGroupEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPVarietyGroup` and its cursor. */
export type VUiPVarietyGroupEdge = {
  __typename?: 'VUiPVarietyGroupEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPVarietyGroup>;
};

export type VUiPWeeklyDailyBudgetConf = {
  __typename?: 'VUiPWeeklyDailyBudgetConf';
  /** 予算商品群id */
  budgetGroupId: Scalars['String'];
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 登録日時 */
  createDatetime?: Maybe<Scalars['DateTime']>;
  /** 登録者id */
  createUser?: Maybe<Scalars['String']>;
  /** 登録者名 */
  createUserName?: Maybe<Scalars['String']>;
  /** 削除フラグ / 1:削除 */
  inactiveFlg?: Maybe<Scalars['String']>;
  /** 最終更新日時 */
  lastModifiedDatetime?: Maybe<Scalars['DateTime']>;
  /** 最終更新者id */
  lastModifiedUser?: Maybe<Scalars['String']>;
  /** 最終更新者名 */
  lastModifiedUserName?: Maybe<Scalars['String']>;
  /** 販売日 / 店別＊クラス別係数の初日 / 日次バッチで更新（テーブルで固定値） */
  saleDate?: Maybe<Scalars['Date']>;
  store?: Maybe<AllStoreMst>;
  /** 店舗コード */
  storeCd: Scalars['String'];
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi?: Maybe<Scalars['Date']>;
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi?: Maybe<Scalars['DateTime']>;
  vUiPBudgetGroup?: Maybe<VUiPBudgetGroup>;
  vUiPWeeklyDailyBudgetConf_DowIndex?: Maybe<VUiPWeeklyDailyBudgetConfDowIndex>;
  /**
   * a: v_ui_p_weekly_daily_budget_conf, b: v_ui_p_budget_group<br><br>
   * a.inactive_flg = '0' and<br>
   * b.inactive_flg = '0'
   */
  validVUiPBudgetGroup?: Maybe<VUiPBudgetGroup>;
  /** 天気係数設定a */
  weatherWeightA?: Maybe<Scalars['Float']>;
  /** 天気係数設定b */
  weatherWeightB?: Maybe<Scalars['Float']>;
  /** 天気係数設定c */
  weatherWeightC?: Maybe<Scalars['Float']>;
  /** 天気係数設定最大 */
  weatherWeightMax?: Maybe<Scalars['Float']>;
  /** 週次発注金額 */
  weeklyBudget?: Maybe<Scalars['Int']>;
  /** 店別_クラス別係数_1 / sale_dateの販売日の設定値 */
  weight1?: Maybe<Scalars['Float']>;
  /** 店別_クラス別係数_2 / sale_date+1の販売日の設定値 */
  weight2?: Maybe<Scalars['Float']>;
  /** 店別_クラス別係数_3 */
  weight3?: Maybe<Scalars['Float']>;
  /** 店別_クラス別係数_4 */
  weight4?: Maybe<Scalars['Float']>;
  /** 店別_クラス別係数_5 */
  weight5?: Maybe<Scalars['Float']>;
  /** 店別_クラス別係数_6 */
  weight6?: Maybe<Scalars['Float']>;
  /** 店別_クラス別係数_7 */
  weight7?: Maybe<Scalars['Float']>;
};

export type VUiPWeeklyDailyBudgetConfConnection = {
  __typename?: 'VUiPWeeklyDailyBudgetConfConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPWeeklyDailyBudgetConfEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

export type VUiPWeeklyDailyBudgetConfDowIndex = {
  __typename?: 'VUiPWeeklyDailyBudgetConfDowIndex';
  /** 予算商品群id */
  budgetGroupId: Scalars['String'];
  /** 発注曜日指数（金） */
  friIndex: Scalars['Float'];
  /** 発注曜日指数（月） */
  monIndex: Scalars['Float'];
  /** 販売日 / 基準日 / ui_p_weekly_daily_budget_confのsale_date */
  saleDate: Scalars['Date'];
  /** 発注曜日指数（土） */
  satIndex: Scalars['Float'];
  /** 店舗コード */
  storeCd: Scalars['String'];
  /** 発注曜日指数（日） */
  sunIndex: Scalars['Float'];
  /** 発注曜日指数（木） */
  thuIndex: Scalars['Float'];
  /** 発注曜日指数（火） */
  tueIndex: Scalars['Float'];
  vUiPWeeklyDailyBudgetConf?: Maybe<VUiPWeeklyDailyBudgetConf>;
  /** 発注曜日指数（水） */
  wedIndex: Scalars['Float'];
};

export type VUiPWeeklyDailyBudgetConfDowIndexConnection = {
  __typename?: 'VUiPWeeklyDailyBudgetConfDowIndexConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPWeeklyDailyBudgetConfDowIndexEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPWeeklyDailyBudgetConfDowIndex` and its cursor. */
export type VUiPWeeklyDailyBudgetConfDowIndexEdge = {
  __typename?: 'VUiPWeeklyDailyBudgetConfDowIndexEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPWeeklyDailyBudgetConfDowIndex>;
};

/** A Relay edge containing a `VUiPWeeklyDailyBudgetConf` and its cursor. */
export type VUiPWeeklyDailyBudgetConfEdge = {
  __typename?: 'VUiPWeeklyDailyBudgetConfEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPWeeklyDailyBudgetConf>;
};

export type VUiPWeeklyDailyBudgetConfWeather = {
  __typename?: 'VUiPWeeklyDailyBudgetConfWeather';
  /** 降雨量予報_1 */
  rain1?: Maybe<Scalars['Float']>;
  /** 降雨量予報_2 */
  rain2?: Maybe<Scalars['Float']>;
  /** 降雨量予報_3 */
  rain3?: Maybe<Scalars['Float']>;
  /** 降雨量予報_4 */
  rain4?: Maybe<Scalars['Float']>;
  /** 降雨量予報_5 */
  rain5?: Maybe<Scalars['Float']>;
  /** 降雨量予報_6 */
  rain6?: Maybe<Scalars['Float']>;
  /** 降雨量予報_7 */
  rain7?: Maybe<Scalars['Float']>;
  /** 販売日 / 基準日 */
  saleDate: Scalars['Date'];
  store?: Maybe<AllStoreMst>;
  /** 店舗コード */
  storeCd: Scalars['String'];
};

export type VUiPWeeklyDailyBudgetConfWeatherConnection = {
  __typename?: 'VUiPWeeklyDailyBudgetConfWeatherConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPWeeklyDailyBudgetConfWeatherEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPWeeklyDailyBudgetConfWeather` and its cursor. */
export type VUiPWeeklyDailyBudgetConfWeatherEdge = {
  __typename?: 'VUiPWeeklyDailyBudgetConfWeatherEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPWeeklyDailyBudgetConfWeather>;
};

export type VUiPWeightAdjConf = {
  __typename?: 'VUiPWeightAdjConf';
  /** 予算商品群id */
  budgetGroupId: Scalars['String'];
  /** 設定行フラグ / 設定行: 1, 自動生成行: 0 */
  configuredFlg: Scalars['String'];
  /** 消化率最大閾値 / all_予算商品群パラメータマスタ */
  consumptionMaxLimit?: Maybe<Scalars['Float']>;
  /** 消化率最大係数 / all_予算商品群パラメータマスタ */
  consumptionMaxParam?: Maybe<Scalars['Float']>;
  /** 消化率最小閾値 / all_予算商品群パラメータマスタ */
  consumptionMinLimit?: Maybe<Scalars['Float']>;
  /** 消化率最小係数 / all_予算商品群パラメータマスタ */
  consumptionMinParam?: Maybe<Scalars['Float']>;
  /** 登録日時 */
  createDatetime: Scalars['DateTime'];
  /** 登録者id */
  createUser: Scalars['String'];
  /** 登録者名 */
  createUserName: Scalars['String'];
  /** 日次入替え廃棄率 / 値１で100%を表す / all_バラエティグループマスタ、この数値を超えると入替え発生 */
  dailySwapDisposalRate?: Maybe<Scalars['Float']>;
  /** 廃棄率最大閾値 / all_予算商品群パラメータマスタ */
  discardRateMaxLimit?: Maybe<Scalars['Float']>;
  /** 廃棄率最大係数 / all_予算商品群パラメータマスタ */
  discardRateMaxParam?: Maybe<Scalars['Float']>;
  /** 廃棄率最小閾値 / all_予算商品群パラメータマスタ */
  discardRateMinLimit?: Maybe<Scalars['Float']>;
  /** 廃棄率最小係数 / all_予算商品群パラメータマスタ */
  discardRateMinParam?: Maybe<Scalars['Float']>;
  /** 廃棄高最大閾値 / all_予算商品群パラメータマスタ */
  discardSumMaxLimit?: Maybe<Scalars['Float']>;
  /** 廃棄高最大係数 / all_予算商品群パラメータマスタ */
  discardSumMaxParam?: Maybe<Scalars['Float']>;
  /** 廃棄高最小閾値 / all_予算商品群パラメータマスタ */
  discardSumMinLimit?: Maybe<Scalars['Float']>;
  /** 廃棄高最小係数 / all_予算商品群パラメータマスタ */
  discardSumMinParam?: Maybe<Scalars['Float']>;
  /** 棚0確係数 / all_予算商品群パラメータマスタ */
  dspzeroParam?: Maybe<Scalars['Float']>;
  /** 削除フラグ / 1:削除 */
  inactiveFlg: Scalars['String'];
  /** 在庫計算フラグ / する：1、しない：0 / all_予算商品群パラメータマスタ、精肉のみを想定 */
  isStockCalc: Scalars['String'];
  /** 最終更新日時 */
  lastModifiedDatetime: Scalars['DateTime'];
  /** 最終更新者id */
  lastModifiedUser: Scalars['String'];
  /** 最終更新者名 */
  lastModifiedUserName: Scalars['String'];
  /** 単品構成比最大閾値 / 廃棄率による調整 / all_予算商品群パラメータマスタ */
  skuRatioMaxLimit?: Maybe<Scalars['Float']>;
  /** 単品構成比最大係数 / 廃棄率による調整 / all_予算商品群パラメータマスタ */
  skuRatioMaxParam?: Maybe<Scalars['Float']>;
  /** 単品構成比最小閾値 / 廃棄率による調整 / all_予算商品群パラメータマスタ */
  skuRatioMinLimit?: Maybe<Scalars['Float']>;
  /** 単品構成比最小係数 / 廃棄率による調整 / all_予算商品群パラメータマスタ */
  skuRatioMinParam?: Maybe<Scalars['Float']>;
  /** 単品0確係数_最大 / all_予算商品群パラメータマスタ */
  skuzeroMaxParam?: Maybe<Scalars['Float']>;
  /** 単品0確係数_最小 / all_予算商品群パラメータマスタ */
  skuzeroMinParam?: Maybe<Scalars['Float']>;
  /** 処理対象日_ui / 処理対象日 / 障害追跡用 */
  targetDateUi: Scalars['Date'];
  /** 閉店時在庫目標 / 小数点一桁 / all_予算商品群パラメータマスタ、精肉のみを想定 */
  targetStock?: Maybe<Scalars['Float']>;
  /** 更新時刻_ui / 更新時刻 / 障害追跡用 */
  updTimeUi: Scalars['DateTime'];
  vUiPBudgetGroup?: Maybe<VUiPBudgetGroup>;
  /**
   * a: v_ui_p_weight_adj_conf, b: v_ui_p_budget_group<br><br>
   * a.inactive_flg = '0' and<br>
   * b.inactive_flg = '0'
   */
  validVUiPBudgetGroup?: Maybe<VUiPBudgetGroup>;
  /** 週次入替え廃棄率 / 値１で100%を表す / all_バラエティグループマスタ、この数値を超えると入替え発生 */
  weeklySwapDisposalRate?: Maybe<Scalars['Float']>;
};

export type VUiPWeightAdjConfConnection = {
  __typename?: 'VUiPWeightAdjConfConnection';
  edgeCount?: Maybe<Scalars['Int']>;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<VUiPWeightAdjConfEdge>>;
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  totalCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `VUiPWeightAdjConf` and its cursor. */
export type VUiPWeightAdjConfEdge = {
  __typename?: 'VUiPWeightAdjConfEdge';
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
  /** The item at the end of the edge */
  node?: Maybe<VUiPWeightAdjConf>;
};

export type Where = {
  field: Array<InputMaybe<Scalars['String']>>;
  op: WhereOp;
  value?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type WhereEx = {
  and?: InputMaybe<WhereEx>;
  or?: InputMaybe<WhereEx>;
  where?: InputMaybe<Array<InputMaybe<Where>>>;
};

export enum WhereOp {
  Between = 'BETWEEN',
  /** column LIKE '%' || {value} || '%' */
  Contains = 'CONTAINS',
  /** column LIKE '%' || {value} */
  Endswith = 'ENDSWITH',
  /** = */
  Eq = 'EQ',
  /** <pre>>=</pre> */
  Ge = 'GE',
  /** <pre>></pre> */
  Gt = 'GT',
  /** LIKE but case insensitive */
  Ilike = 'ILIKE',
  In = 'IN',
  IsNotNull = 'IS_NOT_NULL',
  IsNull = 'IS_NULL',
  /** <= */
  Le = 'LE',
  Like = 'LIKE',
  /** < */
  Lt = 'LT',
  /** != */
  Ne = 'NE',
  NotIn = 'NOT_IN',
  Regexp = 'REGEXP',
  /** column LIKE {value} || '%' */
  Startswith = 'STARTSWITH'
}

export type BulkOperationRunQueryMutationVariables = Exact<{
  input: BulkOperationRunQueryInput;
}>;


export type BulkOperationRunQueryMutation = { __typename?: 'Mutation', bulkOperationRunQuery?: { __typename?: 'BulkOperationRunQuery', operationId: any } | null };

export type CreateUploadUrlMutationVariables = Exact<{
  input: CreateUploadUrlInput;
}>;


export type CreateUploadUrlMutation = { __typename?: 'Mutation', createUploadUrl?: { __typename?: 'CreateUploadUrl', urlPutFile: string, putFilepath: string } | null };

export type RunSimpleImportMutationVariables = Exact<{
  input: RunSimpleImportInput;
}>;


export type RunSimpleImportMutation = { __typename?: 'Mutation', runSimpleImport?: { __typename?: 'RunSimpleImport', operationId: any } | null };

export type ExtractVUiPAreasQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;


export type ExtractVUiPAreasQuery = { __typename?: 'Query', vUiPAreas?: { __typename?: 'VUiPAreaConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VUiPAreaEdge', node?: { __typename?: 'VUiPArea', areaCd: string, areaName: string, areaType: string } | null } | null> } | null };

export type SaleItemsQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;


export type SaleItemsQuery = { __typename?: 'Query', saleItems?: { __typename?: 'AllSaleitemMstConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'AllSaleitemMstEdge', cursor: string, node?: { __typename?: 'AllSaleitemMst', saleItemCd: string, saleItemName: string, itemCd: string } | null } | null> } | null };

export type StoresQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;


export type StoresQuery = { __typename?: 'Query', stores?: { __typename?: 'AllStoreMstConnection', totalCount?: number | null, edgeCount?: number | null, edges: Array<{ __typename?: 'AllStoreMstEdge', cursor: string, node?: { __typename?: 'AllStoreMst', storeCd: string, storeName: string } | null } | null> } | null };

export type SystemStatusQueryVariables = Exact<{ [key: string]: never; }>;


export type SystemStatusQuery = { __typename?: 'Query', systemStatus?: { __typename?: 'SystemStatus', isMaintenance?: boolean | null, isImportWarn?: boolean | null, isExtServiceWarn?: boolean | null } | null };

export type UiMCategory0sQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;


export type UiMCategory0sQuery = { __typename?: 'Query', uiMCategory0s?: { __typename?: 'UiMCategory0Connection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'UiMCategory0Edge', cursor: string, node?: { __typename?: 'UiMCategory0', category0Cd: string, categoryName: string, parentCategoryCd: string } | null } | null> } | null };

export type UiMCategory1sQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;


export type UiMCategory1sQuery = { __typename?: 'Query', uiMCategory1s?: { __typename?: 'UiMCategory1Connection', totalCount?: number | null, edgeCount?: number | null, edges: Array<{ __typename?: 'UiMCategory1Edge', node?: { __typename?: 'UiMCategory1', category1Cd: string, categoryName: string, parentCategoryCd: string } | null } | null> } | null };

export type UiMCategory2sQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;


export type UiMCategory2sQuery = { __typename?: 'Query', uiMCategory2s?: { __typename?: 'UiMCategory2Connection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'UiMCategory2Edge', cursor: string, node?: { __typename?: 'UiMCategory2', category1Cd: string, category2Cd: string, categoryName: string } | null } | null> } | null };

export type UiMCategory3sQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;


export type UiMCategory3sQuery = { __typename?: 'Query', uiMCategory3s?: { __typename?: 'UiMCategory3Connection', totalCount?: number | null, edgeCount?: number | null, edges: Array<{ __typename?: 'UiMCategory3Edge', node?: { __typename?: 'UiMCategory3', category1Cd: string, category2Cd: string, category3Cd: string, categoryName: string } | null } | null> } | null };

export type UiMStoreShelvesQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
}>;


export type UiMStoreShelvesQuery = { __typename?: 'Query', uiMStoreShelves?: { __typename?: 'UiMStoreShelfConnection', totalCount?: number | null, edgeCount?: number | null, edges: Array<{ __typename?: 'UiMStoreShelfEdge', node?: { __typename?: 'UiMStoreShelf', categoryCd: string, storeCd: string, patternCd: string, patternName?: string | null, startDate: any, endDate: any, patternSeqNo: string, patternStandNo: string, gondolaNo?: string | null, standSeqNo: string, shelfUpNo: string, columnNo: string, versionNo: number, saleItemCd: string, faceNum: number, stackUpNum: number, shelfMode?: string | null, targetDateUi: any, updTimeUi: any } | null } | null> } | null };

export type UiPCausalNamesQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;


export type UiPCausalNamesQuery = { __typename?: 'Query', uiPCausalNames?: { __typename?: 'UiPCausalNameConnection', totalCount?: number | null, edgeCount?: number | null, edges: Array<{ __typename?: 'UiPCausalNameEdge', node?: { __typename?: 'UiPCausalName', causalCd: string, causalName: string } | null } | null> } | null };

export type UiSAsyncExportStatusesQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  offset?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;


export type UiSAsyncExportStatusesQuery = { __typename?: 'Query', uiSAsyncExportStatuses?: { __typename?: 'UiSAsyncExportStatusConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'UiSAsyncExportStatusEdge', cursor: string, node?: { __typename?: 'UiSAsyncExportStatus', operationId: string, graphqlQuery: string, metainfo: string, formatTypeFlg: string, encodingTypeFlg: string, businessDate: any, statusFlg: string, label?: string | null, jmespathQuery?: string | null, filenameExport?: string | null, filepathExtracted?: string | null, filepathTransformed?: string | null, filepathExported?: string | null, recordCountAll?: number | null, filesizeAll?: number | null, archiveFlg: string, createUser: string, createUserName: string, createDatetime: any, lastModifiedUser: string, lastModifiedUserName: string, lastModifiedDatetime: any, targetDateUi: any, updTimeUi: any, formatType: AsyncStatusFormatTypeDb, encodingType: AsyncStatusEncodingTypeDb, status: AsyncStatusTypeDb, archive: ArchiveTypeDb, getFileurlExtracted?: string | null, getFileurlTransformed?: string | null, getFileurlExported?: string | null } | null } | null> } | null };

export type UiSAsyncImportStatusesQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  offset?: InputMaybe<Scalars['Int']>;
  before?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
}>;


export type UiSAsyncImportStatusesQuery = { __typename?: 'Query', uiSAsyncImportStatuses?: { __typename?: 'UiSAsyncImportStatusConnection', pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'UiSAsyncImportStatusEdge', cursor: string, node?: { __typename?: 'UiSAsyncImportStatus', operationId: string, metainfo: string, formatTypeFlg: string, encodingTypeFlg: string, businessDate: any, statusFlg: string, label?: string | null, filepathSource: string, filepathTransformed?: string | null, filepathLoad?: string | null, filepathValidated?: string | null, filepathValidatedTransformed?: string | null, recordCountAll?: number | null, recordCountValid?: number | null, recordCountInvalid?: number | null, recordCountNew?: number | null, recordCountUpdate?: number | null, recordCountIgnore?: number | null, archiveFlg: string, createUser: string, createUserName: string, createDatetime: any, lastModifiedUser: string, lastModifiedUserName: string, lastModifiedDatetime: any, targetDateUi: any, updTimeUi: any, formatType: AsyncStatusFormatTypeDb, encodingType: AsyncStatusEncodingTypeDb, status: AsyncStatusTypeDb, archive: ArchiveTypeDb, putFileurlSource?: string | null, getFileurlSource?: string | null, getFileurlTransformed?: string | null, getFileurlValidated?: string | null, getFileurlValidatedTransformed?: string | null } | null } | null> } | null };

export type UserSystemInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type UserSystemInfoQuery = { __typename?: 'Query', userInformation?: { __typename?: 'UserInformation', loginId?: string | null, displayName?: string | null, isAdministrator?: boolean | null, availablePaths?: Array<{ __typename?: 'UserInformationAvailablePath', path?: string | null } | null> | null } | null, systemSetting?: { __typename?: 'SystemSetting', systemDate?: any | null, operationDisplayHistDays?: number | null, displayMaintenanceWord1?: string | null, displayMaintenanceWord2?: string | null } | null };

export type VUiPAdjustedDowIndicesCountQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type VUiPAdjustedDowIndicesCountQuery = { __typename?: 'Query', vUiPAdjustedDowIndices?: { __typename?: 'VUiPAdjustedDowIndexConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VUiPAdjustedDowIndexEdge', node?: { __typename?: 'VUiPAdjustedDowIndex', inactiveFlg?: string | null } | null } | null> } | null };

export type VUiPAreasCountQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type VUiPAreasCountQuery = { __typename?: 'Query', vUiPAreas?: { __typename?: 'VUiPAreaConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VUiPAreaEdge', node?: { __typename?: 'VUiPArea', inactiveFlg: string } | null } | null> } | null };

export type VUiPAreastoresCountQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type VUiPAreastoresCountQuery = { __typename?: 'Query', vUiPAreastores?: { __typename?: 'VUiPAreastoreConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VUiPAreastoreEdge', node?: { __typename?: 'VUiPAreastore', inactiveFlg: string } | null } | null> } | null };

export type VUiPAutoorderStopsCountQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type VUiPAutoorderStopsCountQuery = { __typename?: 'Query', vUiPAutoorderStops?: { __typename?: 'VUiPAutoorderStopConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VUiPAutoorderStopEdge', node?: { __typename?: 'VUiPAutoorderStop', inactiveFlg: string } | null } | null> } | null };

export type VUiPBaseConfsCountQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type VUiPBaseConfsCountQuery = { __typename?: 'Query', vUiPBaseConfs?: { __typename?: 'VUiPBaseConfConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VUiPBaseConfEdge', node?: { __typename?: 'VUiPBaseConf', inactiveFlg: string } | null } | null> } | null };

export type VUiPCausalNamesCountQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type VUiPCausalNamesCountQuery = { __typename?: 'Query', vUiPCausalNames?: { __typename?: 'VUiPCausalNameConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VUiPCausalNameEdge', node?: { __typename?: 'VUiPCausalName', inactiveFlg: string } | null } | null> } | null };

export type VUiPCausalWeightConfsCountQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type VUiPCausalWeightConfsCountQuery = { __typename?: 'Query', vUiPCausalWeightConfs?: { __typename?: 'VUiPCausalWeightConfConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VUiPCausalWeightConfEdge', node?: { __typename?: 'VUiPCausalWeightConf', inactiveFlg: string } | null } | null> } | null };

export type VUiPDspInfosCountQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type VUiPDspInfosCountQuery = { __typename?: 'Query', vUiPDspInfos?: { __typename?: 'VUiPDspInfoConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VUiPDspInfoEdge', node?: { __typename?: 'VUiPDspInfo', inactiveFlg: string } | null } | null> } | null };

export type VUiPItemConfsCountQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type VUiPItemConfsCountQuery = { __typename?: 'Query', vUiPItemConfs?: { __typename?: 'VUiPItemConfConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VUiPItemConfEdge', node?: { __typename?: 'VUiPItemConf', inactiveFlg?: string | null } | null } | null> } | null };

export type VUiPItemFeaturesCountQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type VUiPItemFeaturesCountQuery = { __typename?: 'Query', vUiPItemFeatures?: { __typename?: 'VUiPItemFeatureConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VUiPItemFeatureEdge', node?: { __typename?: 'VUiPItemFeature', inactiveFlg: string } | null } | null> } | null };

export type VUiPItemOrderNumAdjsCountQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type VUiPItemOrderNumAdjsCountQuery = { __typename?: 'Query', vUiPItemOrderNumAdjs?: { __typename?: 'VUiPItemOrderNumAdjConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VUiPItemOrderNumAdjEdge', node?: { __typename?: 'VUiPItemOrderNumAdj', inactiveFlg?: string | null } | null } | null> } | null };

export type VUiPLowRankLogicConfsCountQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type VUiPLowRankLogicConfsCountQuery = { __typename?: 'Query', vUiPLowRankLogicConfs?: { __typename?: 'VUiPLowRankLogicConfConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VUiPLowRankLogicConfEdge', node?: { __typename?: 'VUiPLowRankLogicConf', inactiveFlg: string } | null } | null> } | null };

export type VUiPNewStoreBulkOrdersCountQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type VUiPNewStoreBulkOrdersCountQuery = { __typename?: 'Query', vUiPNewStoreBulkOrders?: { __typename?: 'VUiPNewStoreBulkOrderConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VUiPNewStoreBulkOrderEdge', node?: { __typename?: 'VUiPNewStoreBulkOrder', inactiveFlg: string } | null } | null> } | null };

export type VUiPOrderPatternSettingSiisCountQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type VUiPOrderPatternSettingSiisCountQuery = { __typename?: 'Query', vUiPOrderPatternSettingSiis?: { __typename?: 'VUiPOrderPatternSettingSiiConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VUiPOrderPatternSettingSiiEdge', node?: { __typename?: 'VUiPOrderPatternSettingSii', inactiveFlg: string } | null } | null> } | null };

export type VUiPTranRefItemsCountQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type VUiPTranRefItemsCountQuery = { __typename?: 'Query', vUiPTranRefItems?: { __typename?: 'VUiPTranRefItemConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VUiPTranRefItemEdge', node?: { __typename?: 'VUiPTranRefItem', inactiveFlg: string } | null } | null> } | null };

export type VUiPTranRefStoresCountQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type VUiPTranRefStoresCountQuery = { __typename?: 'Query', vUiPTranRefStores?: { __typename?: 'VUiPTranRefStoreConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VUiPTranRefStoreEdge', node?: { __typename?: 'VUiPTranRefStore', inactiveFlg: string } | null } | null> } | null };

export type VUiPUsersCountQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type VUiPUsersCountQuery = { __typename?: 'Query', vUiPUsers?: { __typename?: 'VUiPUserConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VUiPUserEdge', node?: { __typename?: 'VUiPUser', inactiveFlg: string } | null } | null> } | null };

export type VUiPWeeklyDailyBudgetConfsCountQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type VUiPWeeklyDailyBudgetConfsCountQuery = { __typename?: 'Query', vUiPWeeklyDailyBudgetConfs?: { __typename?: 'VUiPWeeklyDailyBudgetConfConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VUiPWeeklyDailyBudgetConfEdge', node?: { __typename?: 'VUiPWeeklyDailyBudgetConf', inactiveFlg?: string | null } | null } | null> } | null };

export type VUiPWeightAdjConfsCountQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
}>;


export type VUiPWeightAdjConfsCountQuery = { __typename?: 'Query', vUiPWeightAdjConfs?: { __typename?: 'VUiPWeightAdjConfConnection', totalCount?: number | null, edgeCount?: number | null, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null, endCursor?: string | null }, edges: Array<{ __typename?: 'VUiPWeightAdjConfEdge', node?: { __typename?: 'VUiPWeightAdjConf', inactiveFlg: string } | null } | null> } | null };

export type VendorsQueryVariables = Exact<{
  where?: InputMaybe<Array<InputMaybe<Where>> | InputMaybe<Where>>;
  orderBy?: InputMaybe<Array<InputMaybe<OrderBy>> | InputMaybe<OrderBy>>;
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
}>;


export type VendorsQuery = { __typename?: 'Query', vendors?: { __typename?: 'AllVendorMstConnection', totalCount?: number | null, edgeCount?: number | null, edges: Array<{ __typename?: 'AllVendorMstEdge', node?: { __typename?: 'AllVendorMst', vendorCd: string, vendorName: string } | null } | null> } | null };


export const BulkOperationRunQueryDocument = gql`
    mutation bulkOperationRunQuery($input: BulkOperationRunQueryInput!) {
  bulkOperationRunQuery(input: $input) {
    operationId
  }
}
    `;
export const CreateUploadUrlDocument = gql`
    mutation createUploadUrl($input: CreateUploadUrlInput!) {
  createUploadUrl(input: $input) {
    urlPutFile
    putFilepath
  }
}
    `;
export const RunSimpleImportDocument = gql`
    mutation runSimpleImport($input: RunSimpleImportInput!) {
  runSimpleImport(input: $input) {
    operationId
  }
}
    `;
export const ExtractVUiPAreasDocument = gql`
    query ExtractVUiPAreas($where: [Where], $orderBy: [OrderBy], $after: String, $first: Int) {
  vUiPAreas(where: $where, orderBy: $orderBy, after: $after, first: $first) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        areaCd
        areaName
        areaType
      }
    }
  }
}
    `;
export const SaleItemsDocument = gql`
    query saleItems($where: [Where], $orderBy: [OrderBy], $before: String, $after: String, $first: Int, $last: Int) {
  saleItems(
    where: $where
    orderBy: $orderBy
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        saleItemCd
        saleItemName
        itemCd
      }
      cursor
    }
  }
}
    `;
export const StoresDocument = gql`
    query stores($where: [Where], $orderBy: [OrderBy], $after: String, $first: Int) {
  stores(where: $where, orderBy: $orderBy, after: $after, first: $first) {
    totalCount
    edgeCount
    edges {
      node {
        storeCd
        storeName
      }
      cursor
    }
  }
}
    `;
export const SystemStatusDocument = gql`
    query systemStatus {
  systemStatus {
    isMaintenance
    isImportWarn
    isExtServiceWarn
  }
}
    `;
export const UiMCategory0sDocument = gql`
    query uiMCategory0s($where: [Where], $orderBy: [OrderBy], $after: String, $first: Int) {
  uiMCategory0s(where: $where, orderBy: $orderBy, after: $after, first: $first) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        category0Cd
        categoryName
        parentCategoryCd
      }
      cursor
    }
  }
}
    `;
export const UiMCategory1sDocument = gql`
    query uiMCategory1s($where: [Where], $orderBy: [OrderBy], $after: String, $first: Int) {
  uiMCategory1s(where: $where, orderBy: $orderBy, after: $after, first: $first) {
    totalCount
    edgeCount
    edges {
      node {
        category1Cd
        categoryName
        parentCategoryCd
      }
    }
  }
}
    `;
export const UiMCategory2sDocument = gql`
    query uiMCategory2s($where: [Where], $orderBy: [OrderBy], $after: String, $first: Int) {
  uiMCategory2s(where: $where, orderBy: $orderBy, after: $after, first: $first) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        category1Cd
        category2Cd
        categoryName
      }
      cursor
    }
  }
}
    `;
export const UiMCategory3sDocument = gql`
    query uiMCategory3s($where: [Where], $orderBy: [OrderBy], $after: String, $first: Int) {
  uiMCategory3s(where: $where, orderBy: $orderBy, after: $after, first: $first) {
    totalCount
    edgeCount
    edges {
      node {
        category1Cd
        category2Cd
        category3Cd
        categoryName
      }
    }
  }
}
    `;
export const UiMStoreShelvesDocument = gql`
    query uiMStoreShelves($where: [Where], $orderBy: [OrderBy]) {
  uiMStoreShelves(where: $where, orderBy: $orderBy) {
    totalCount
    edgeCount
    edges {
      node {
        categoryCd
        storeCd
        patternCd
        patternName
        startDate
        endDate
        patternSeqNo
        patternStandNo
        gondolaNo
        standSeqNo
        shelfUpNo
        columnNo
        versionNo
        saleItemCd
        faceNum
        stackUpNum
        shelfMode
        targetDateUi
        updTimeUi
      }
    }
  }
}
    `;
export const UiPCausalNamesDocument = gql`
    query uiPCausalNames($where: [Where], $orderBy: [OrderBy], $after: String, $first: Int) {
  uiPCausalNames(where: $where, orderBy: $orderBy, after: $after, first: $first) {
    totalCount
    edgeCount
    edges {
      node {
        causalCd
        causalName
      }
    }
  }
}
    `;
export const UiSAsyncExportStatusesDocument = gql`
    query uiSAsyncExportStatuses($where: [Where], $orderBy: [OrderBy], $offset: Int, $before: String, $after: String, $first: Int, $last: Int) {
  uiSAsyncExportStatuses(
    where: $where
    orderBy: $orderBy
    offset: $offset
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        operationId
        graphqlQuery
        metainfo
        formatTypeFlg
        encodingTypeFlg
        businessDate
        statusFlg
        label
        jmespathQuery
        filenameExport
        filepathExtracted
        filepathTransformed
        filepathExported
        recordCountAll
        filesizeAll
        archiveFlg
        createUser
        createUserName
        createDatetime
        lastModifiedUser
        lastModifiedUserName
        lastModifiedDatetime
        targetDateUi
        updTimeUi
        formatType
        encodingType
        status
        archive
        getFileurlExtracted
        getFileurlTransformed
        getFileurlExported
      }
      cursor
    }
  }
}
    `;
export const UiSAsyncImportStatusesDocument = gql`
    query uiSAsyncImportStatuses($where: [Where], $orderBy: [OrderBy], $offset: Int, $before: String, $after: String, $first: Int, $last: Int) {
  uiSAsyncImportStatuses(
    where: $where
    orderBy: $orderBy
    offset: $offset
    before: $before
    after: $after
    first: $first
    last: $last
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        operationId
        metainfo
        formatTypeFlg
        encodingTypeFlg
        businessDate
        statusFlg
        label
        filepathSource
        filepathTransformed
        filepathLoad
        filepathValidated
        filepathValidatedTransformed
        recordCountAll
        recordCountValid
        recordCountInvalid
        recordCountNew
        recordCountUpdate
        recordCountIgnore
        archiveFlg
        createUser
        createUserName
        createDatetime
        lastModifiedUser
        lastModifiedUserName
        lastModifiedDatetime
        targetDateUi
        updTimeUi
        formatType
        encodingType
        status
        archive
        putFileurlSource
        getFileurlSource
        getFileurlTransformed
        getFileurlValidated
        getFileurlValidatedTransformed
      }
      cursor
    }
  }
}
    `;
export const UserSystemInfoDocument = gql`
    query userSystemInfo {
  userInformation {
    loginId
    displayName
    isAdministrator
    availablePaths {
      path
    }
  }
  systemSetting {
    systemDate
    operationDisplayHistDays
    displayMaintenanceWord1
    displayMaintenanceWord2
  }
}
    `;
export const VUiPAdjustedDowIndicesCountDocument = gql`
    query vUiPAdjustedDowIndicesCount($where: [Where], $orderBy: [OrderBy], $after: String) {
  vUiPAdjustedDowIndices(
    where: $where
    orderBy: $orderBy
    after: $after
    first: 0
  ) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        inactiveFlg
      }
    }
  }
}
    `;
export const VUiPAreasCountDocument = gql`
    query vUiPAreasCount($where: [Where], $orderBy: [OrderBy], $after: String) {
  vUiPAreas(where: $where, orderBy: $orderBy, after: $after, first: 0) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        inactiveFlg
      }
    }
  }
}
    `;
export const VUiPAreastoresCountDocument = gql`
    query vUiPAreastoresCount($where: [Where], $orderBy: [OrderBy], $after: String) {
  vUiPAreastores(where: $where, orderBy: $orderBy, after: $after, first: 0) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        inactiveFlg
      }
    }
  }
}
    `;
export const VUiPAutoorderStopsCountDocument = gql`
    query vUiPAutoorderStopsCount($where: [Where], $orderBy: [OrderBy], $after: String) {
  vUiPAutoorderStops(where: $where, orderBy: $orderBy, after: $after, first: 0) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        inactiveFlg
      }
    }
  }
}
    `;
export const VUiPBaseConfsCountDocument = gql`
    query vUiPBaseConfsCount($where: [Where], $orderBy: [OrderBy], $after: String) {
  vUiPBaseConfs(where: $where, orderBy: $orderBy, after: $after, first: 0) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        inactiveFlg
      }
    }
  }
}
    `;
export const VUiPCausalNamesCountDocument = gql`
    query vUiPCausalNamesCount($where: [Where], $orderBy: [OrderBy], $after: String) {
  vUiPCausalNames(where: $where, orderBy: $orderBy, after: $after, first: 0) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        inactiveFlg
      }
    }
  }
}
    `;
export const VUiPCausalWeightConfsCountDocument = gql`
    query vUiPCausalWeightConfsCount($where: [Where], $orderBy: [OrderBy], $after: String) {
  vUiPCausalWeightConfs(where: $where, orderBy: $orderBy, after: $after, first: 0) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        inactiveFlg
      }
    }
  }
}
    `;
export const VUiPDspInfosCountDocument = gql`
    query vUiPDspInfosCount($where: [Where], $orderBy: [OrderBy], $after: String) {
  vUiPDspInfos(where: $where, orderBy: $orderBy, after: $after, first: 0) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        inactiveFlg
      }
    }
  }
}
    `;
export const VUiPItemConfsCountDocument = gql`
    query vUiPItemConfsCount($where: [Where], $orderBy: [OrderBy], $after: String) {
  vUiPItemConfs(where: $where, orderBy: $orderBy, after: $after, first: 0) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        inactiveFlg
      }
    }
  }
}
    `;
export const VUiPItemFeaturesCountDocument = gql`
    query vUiPItemFeaturesCount($where: [Where], $orderBy: [OrderBy], $after: String) {
  vUiPItemFeatures(where: $where, orderBy: $orderBy, after: $after, first: 0) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        inactiveFlg
      }
    }
  }
}
    `;
export const VUiPItemOrderNumAdjsCountDocument = gql`
    query vUiPItemOrderNumAdjsCount($where: [Where], $orderBy: [OrderBy], $after: String) {
  vUiPItemOrderNumAdjs(where: $where, orderBy: $orderBy, after: $after, first: 0) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        inactiveFlg
      }
    }
  }
}
    `;
export const VUiPLowRankLogicConfsCountDocument = gql`
    query vUiPLowRankLogicConfsCount($where: [Where], $orderBy: [OrderBy], $after: String) {
  vUiPLowRankLogicConfs(where: $where, orderBy: $orderBy, after: $after, first: 0) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        inactiveFlg
      }
    }
  }
}
    `;
export const VUiPNewStoreBulkOrdersCountDocument = gql`
    query vUiPNewStoreBulkOrdersCount($where: [Where], $orderBy: [OrderBy], $after: String) {
  vUiPNewStoreBulkOrders(
    where: $where
    orderBy: $orderBy
    after: $after
    first: 0
  ) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        inactiveFlg
      }
    }
  }
}
    `;
export const VUiPOrderPatternSettingSiisCountDocument = gql`
    query vUiPOrderPatternSettingSiisCount($where: [Where], $orderBy: [OrderBy], $after: String) {
  vUiPOrderPatternSettingSiis(
    where: $where
    orderBy: $orderBy
    after: $after
    first: 0
  ) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        inactiveFlg
      }
    }
  }
}
    `;
export const VUiPTranRefItemsCountDocument = gql`
    query vUiPTranRefItemsCount($where: [Where], $orderBy: [OrderBy], $after: String) {
  vUiPTranRefItems(where: $where, orderBy: $orderBy, after: $after, first: 0) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        inactiveFlg
      }
    }
  }
}
    `;
export const VUiPTranRefStoresCountDocument = gql`
    query vUiPTranRefStoresCount($where: [Where], $orderBy: [OrderBy], $after: String) {
  vUiPTranRefStores(where: $where, orderBy: $orderBy, after: $after, first: 0) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        inactiveFlg
      }
    }
  }
}
    `;
export const VUiPUsersCountDocument = gql`
    query vUiPUsersCount($where: [Where], $orderBy: [OrderBy], $after: String) {
  vUiPUsers(where: $where, orderBy: $orderBy, after: $after, first: 0) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        inactiveFlg
      }
    }
  }
}
    `;
export const VUiPWeeklyDailyBudgetConfsCountDocument = gql`
    query vUiPWeeklyDailyBudgetConfsCount($where: [Where], $orderBy: [OrderBy], $after: String) {
  vUiPWeeklyDailyBudgetConfs(
    where: $where
    orderBy: $orderBy
    after: $after
    first: 0
  ) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        inactiveFlg
      }
    }
  }
}
    `;
export const VUiPWeightAdjConfsCountDocument = gql`
    query vUiPWeightAdjConfsCount($where: [Where], $orderBy: [OrderBy], $after: String) {
  vUiPWeightAdjConfs(where: $where, orderBy: $orderBy, after: $after, first: 0) {
    totalCount
    edgeCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      node {
        inactiveFlg
      }
    }
  }
}
    `;
export const VendorsDocument = gql`
    query vendors($where: [Where], $orderBy: [OrderBy], $after: String, $first: Int) {
  vendors(where: $where, orderBy: $orderBy, after: $after, first: $first) {
    totalCount
    edgeCount
    edges {
      node {
        vendorCd
        vendorName
      }
    }
  }
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    bulkOperationRunQuery(variables: BulkOperationRunQueryMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<BulkOperationRunQueryMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<BulkOperationRunQueryMutation>(BulkOperationRunQueryDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'bulkOperationRunQuery');
    },
    createUploadUrl(variables: CreateUploadUrlMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<CreateUploadUrlMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<CreateUploadUrlMutation>(CreateUploadUrlDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'createUploadUrl');
    },
    runSimpleImport(variables: RunSimpleImportMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RunSimpleImportMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RunSimpleImportMutation>(RunSimpleImportDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'runSimpleImport');
    },
    ExtractVUiPAreas(variables?: ExtractVUiPAreasQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<ExtractVUiPAreasQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<ExtractVUiPAreasQuery>(ExtractVUiPAreasDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'ExtractVUiPAreas');
    },
    saleItems(variables?: SaleItemsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SaleItemsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SaleItemsQuery>(SaleItemsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'saleItems');
    },
    stores(variables?: StoresQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<StoresQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<StoresQuery>(StoresDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'stores');
    },
    systemStatus(variables?: SystemStatusQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<SystemStatusQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<SystemStatusQuery>(SystemStatusDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'systemStatus');
    },
    uiMCategory0s(variables?: UiMCategory0sQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UiMCategory0sQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UiMCategory0sQuery>(UiMCategory0sDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'uiMCategory0s');
    },
    uiMCategory1s(variables?: UiMCategory1sQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UiMCategory1sQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UiMCategory1sQuery>(UiMCategory1sDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'uiMCategory1s');
    },
    uiMCategory2s(variables?: UiMCategory2sQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UiMCategory2sQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UiMCategory2sQuery>(UiMCategory2sDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'uiMCategory2s');
    },
    uiMCategory3s(variables?: UiMCategory3sQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UiMCategory3sQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UiMCategory3sQuery>(UiMCategory3sDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'uiMCategory3s');
    },
    uiMStoreShelves(variables?: UiMStoreShelvesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UiMStoreShelvesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UiMStoreShelvesQuery>(UiMStoreShelvesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'uiMStoreShelves');
    },
    uiPCausalNames(variables?: UiPCausalNamesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UiPCausalNamesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UiPCausalNamesQuery>(UiPCausalNamesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'uiPCausalNames');
    },
    uiSAsyncExportStatuses(variables?: UiSAsyncExportStatusesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UiSAsyncExportStatusesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UiSAsyncExportStatusesQuery>(UiSAsyncExportStatusesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'uiSAsyncExportStatuses');
    },
    uiSAsyncImportStatuses(variables?: UiSAsyncImportStatusesQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UiSAsyncImportStatusesQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UiSAsyncImportStatusesQuery>(UiSAsyncImportStatusesDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'uiSAsyncImportStatuses');
    },
    userSystemInfo(variables?: UserSystemInfoQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<UserSystemInfoQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<UserSystemInfoQuery>(UserSystemInfoDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'userSystemInfo');
    },
    vUiPAdjustedDowIndicesCount(variables?: VUiPAdjustedDowIndicesCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VUiPAdjustedDowIndicesCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VUiPAdjustedDowIndicesCountQuery>(VUiPAdjustedDowIndicesCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vUiPAdjustedDowIndicesCount');
    },
    vUiPAreasCount(variables?: VUiPAreasCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VUiPAreasCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VUiPAreasCountQuery>(VUiPAreasCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vUiPAreasCount');
    },
    vUiPAreastoresCount(variables?: VUiPAreastoresCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VUiPAreastoresCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VUiPAreastoresCountQuery>(VUiPAreastoresCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vUiPAreastoresCount');
    },
    vUiPAutoorderStopsCount(variables?: VUiPAutoorderStopsCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VUiPAutoorderStopsCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VUiPAutoorderStopsCountQuery>(VUiPAutoorderStopsCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vUiPAutoorderStopsCount');
    },
    vUiPBaseConfsCount(variables?: VUiPBaseConfsCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VUiPBaseConfsCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VUiPBaseConfsCountQuery>(VUiPBaseConfsCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vUiPBaseConfsCount');
    },
    vUiPCausalNamesCount(variables?: VUiPCausalNamesCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VUiPCausalNamesCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VUiPCausalNamesCountQuery>(VUiPCausalNamesCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vUiPCausalNamesCount');
    },
    vUiPCausalWeightConfsCount(variables?: VUiPCausalWeightConfsCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VUiPCausalWeightConfsCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VUiPCausalWeightConfsCountQuery>(VUiPCausalWeightConfsCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vUiPCausalWeightConfsCount');
    },
    vUiPDspInfosCount(variables?: VUiPDspInfosCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VUiPDspInfosCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VUiPDspInfosCountQuery>(VUiPDspInfosCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vUiPDspInfosCount');
    },
    vUiPItemConfsCount(variables?: VUiPItemConfsCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VUiPItemConfsCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VUiPItemConfsCountQuery>(VUiPItemConfsCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vUiPItemConfsCount');
    },
    vUiPItemFeaturesCount(variables?: VUiPItemFeaturesCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VUiPItemFeaturesCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VUiPItemFeaturesCountQuery>(VUiPItemFeaturesCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vUiPItemFeaturesCount');
    },
    vUiPItemOrderNumAdjsCount(variables?: VUiPItemOrderNumAdjsCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VUiPItemOrderNumAdjsCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VUiPItemOrderNumAdjsCountQuery>(VUiPItemOrderNumAdjsCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vUiPItemOrderNumAdjsCount');
    },
    vUiPLowRankLogicConfsCount(variables?: VUiPLowRankLogicConfsCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VUiPLowRankLogicConfsCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VUiPLowRankLogicConfsCountQuery>(VUiPLowRankLogicConfsCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vUiPLowRankLogicConfsCount');
    },
    vUiPNewStoreBulkOrdersCount(variables?: VUiPNewStoreBulkOrdersCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VUiPNewStoreBulkOrdersCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VUiPNewStoreBulkOrdersCountQuery>(VUiPNewStoreBulkOrdersCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vUiPNewStoreBulkOrdersCount');
    },
    vUiPOrderPatternSettingSiisCount(variables?: VUiPOrderPatternSettingSiisCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VUiPOrderPatternSettingSiisCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VUiPOrderPatternSettingSiisCountQuery>(VUiPOrderPatternSettingSiisCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vUiPOrderPatternSettingSiisCount');
    },
    vUiPTranRefItemsCount(variables?: VUiPTranRefItemsCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VUiPTranRefItemsCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VUiPTranRefItemsCountQuery>(VUiPTranRefItemsCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vUiPTranRefItemsCount');
    },
    vUiPTranRefStoresCount(variables?: VUiPTranRefStoresCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VUiPTranRefStoresCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VUiPTranRefStoresCountQuery>(VUiPTranRefStoresCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vUiPTranRefStoresCount');
    },
    vUiPUsersCount(variables?: VUiPUsersCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VUiPUsersCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VUiPUsersCountQuery>(VUiPUsersCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vUiPUsersCount');
    },
    vUiPWeeklyDailyBudgetConfsCount(variables?: VUiPWeeklyDailyBudgetConfsCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VUiPWeeklyDailyBudgetConfsCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VUiPWeeklyDailyBudgetConfsCountQuery>(VUiPWeeklyDailyBudgetConfsCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vUiPWeeklyDailyBudgetConfsCount');
    },
    vUiPWeightAdjConfsCount(variables?: VUiPWeightAdjConfsCountQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VUiPWeightAdjConfsCountQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VUiPWeightAdjConfsCountQuery>(VUiPWeightAdjConfsCountDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vUiPWeightAdjConfsCount');
    },
    vendors(variables?: VendorsQueryVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<VendorsQuery> {
      return withWrapper((wrappedRequestHeaders) => client.request<VendorsQuery>(VendorsDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'vendors');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;